<template>
  <div id="body-container" class="d-flex flex-column h-100">
    <nav v-if="isWebSite" class="navbar navbar-expand-md fixed-top navbar-light bg-white shadow-sm py-0">
      <a class="navbar-brand mx-2 mx-lg-5" :href="this.$root.redirectUrl">
        <img src="@/assets/images/logo.svg" width="240" alt="shifton">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerUnLoggedBar" aria-controls="navbarTogglerUnLoggedBar" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div v-if="!hideLanguage" class="collapse navbar-collapse" id="navbarTogglerUnLoggedBar">
        <div class="navbar-nav mr-auto mt-2 mt-lg-0"></div>
        <form class="form-inline my-2 my-lg-0">
          <ui-dropdown
            v-if="languages"
            @command="langSwitch"
            :items="languages.map(item => {
              return {
                id: item.code,
                title: item.name
              }
            })"
          >
            <span class="el-dropdown-link btn btn-sm btn-primary rounded-pill">
              {{languages.filter(item => item.code === localeDropdown)[0] ? languages.filter(item => item.code === localeDropdown)[0].name : ''}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
          </ui-dropdown>
          <button v-if="isAuthorized" class="btn btn-outline-light rounded-pill ml-2" @click="logout">{{ $t('Log out')}}</button>
        </form>
      </div>
    </nav>

    <main-site-header
      @openCreateRequestModal="openCreateRequestModal"
      @openChangePasswordModal="openChangePasswordModal"
      v-if="!isWebSite"
    />
    <loader />
    <ChangeCompany
      v-if="openModal"
    />
    <change-password
      v-if="showChangePassword"
      @closeChangePasswordModal="closeChangePasswordModal"
    />
    <el-dialog
      key="showConfirmShiftsModal"
      :title="$t('UNCONFIRMED_SHIFTS_TITLE')"
      v-model="showConfirmShiftsModal"
      :width="elDialogWidth">
      <div class="my-3" style="max-height: 300px; overflow:auto; background-color: white">
        <div class="border border-light p-2 mb-2" v-for="(shift, index) in unconfirmedShifts" :key="index">
          {{shift.time_from}} - {{shift.time_to}} {{shift.template ? shift.template.name : ''}} {{shift.schedule.name}}
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showConfirmShiftsModal = false">{{$t('REMIND_ME_LATER')}}</el-button>
          <el-button type="primary" @click="confirmShifts">{{$t('Confirm')}}</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- Модалка с ссылками на мобильное приложение -->
    <MobileAppModal v-if="companyId && mobilePlatform" />
    <el-config-provider :locale="localeElements">
      <CreateRequest
        v-if="showCreateRequest"
        @closeCreateRequestModal="closeCreateRequestModal"
      />
      <div v-if="translationsIsLoaded" class="container-fluid mb-4 pt-2 flex-shrink-0">
        <router-view v-slot="{ Component, route }">
          <transition name="fade" mode="out-in">
            <keep-alive include="['Availability', 'Schedule']">
              <component v-if="route.matched.length > 0" :is="Component" />
            </keep-alive>
          </transition>
        </router-view>
      </div>
    </el-config-provider>
    <footer class="mt-auto mb-1">
      <div class="container p-0 text-center">
        <span class="text-muted">Copyright © 2017-{{year}}, Shifton. All rights reserved.</span>
        &nbsp;|&nbsp;
        <a :href="this.redirectUrl + $i18n.locale + '/privacy'">Privacy Policy</a>
        &nbsp;|&nbsp;
        <a :href="this.redirectUrl + $i18n.locale + '/terms'">Terms of Use</a>
        &nbsp;|&nbsp;
        <a :href="this.redirectUrl + $i18n.locale + '/cookies'">Сookies</a>
        &nbsp;|&nbsp;
        <a href="#" class="termly-display-preferences">Consent Preferences</a>
      </div>
    </footer>
  </div>
</template>

<script setup>
</script>

<script>
import 'jquery'
import 'bootstrap'
import Header from './components/Headers/Header.vue'
import ChangeCompany from './components/Company/ChangeCompany'
import ChangePassword from './components/ChangePassword'
import CreateRequest from './components/Requests/CreateRequest'
import Loader from '@/components/CommonComponents/Loader'
import { mapGetters } from 'vuex'
import { loadLanguageAsync } from '@/i18n'
import momentMixin from '@/mixins/mixinMoment'
import mixinWindowWidthWatch from './mixins/mixinWindowWidthWatch'
import MobileAppModal from '@/components/CommonComponents/MobileAppModal'

import ru from 'element-plus/es/locale/lang/ru'
import en from 'element-plus/es/locale/lang/en'
import he from 'element-plus/es/locale/lang/he'
import uk from 'element-plus/es/locale/lang/uk'
import de from 'element-plus/es/locale/lang/de'
import pl from 'element-plus/es/locale/lang/pl'
import es from 'element-plus/es/locale/lang/es'
import dayjs from 'dayjs'
import moment from 'moment'

export default {
  name: 'app',
  components: {
    MobileAppModal,
    ChangeCompany: ChangeCompany,
    'main-site-header': Header,
    'change-password': ChangePassword,
    CreateRequest: CreateRequest,
    Loader
  },
  mixins: [momentMixin, mixinWindowWidthWatch],
  data () {
    return {
      showChangePassword: false,
      showCreateRequest: false,
      localeDropdown: 'en',
      languageCodes: ['ru', 'en', 'he', 'uk', 'de', 'pl', 'es', 'el'],
      translationsIsLoaded: false,
      timerId: null,
      showConfirmShiftsModal: false,
      unconfirmedShifts: []
    }
  },
  created () {
    if (localStorage.getItem('api_key')) {
      this.$store.dispatch('getProfile')
    }

    if (this.languageCodes.includes(this.$route.query.lang)) {
      this.localeDropdown = this.$route.query.lang
    }

    this.$store.dispatch('getLanguages').then((resolve) => {
      this.languageCodes = resolve
      this.languageCodes = this.languages.map((item) => item.code)
      loadLanguageAsync(!this.$store.getters.profileId ? this.localeDropdown : this.locale).then(() => {
        this.translationsIsLoaded = true
      })
    })
    if (this.readyToSetCrispWidget) {
      this.addWidget()
    }
  },
  watch: {
    confirmRequired () {
      if (this.confirmRequired && this.companyId) {
        this.handleShiftConfirmationModal(this.companyId)
        this.timerId = setInterval(this.handleShiftConfirmationModal, 30 * 60 * 1000, this.companyId)
      }
    },
    companyId (value) {
      if (value && this.confirmRequired) {
        this.handleShiftConfirmationModal(value)
        this.timerId = setInterval(this.handleShiftConfirmationModal, 30 * 60 * 1000, value)
      }
    },
    isTablet (value) {
      if (value) {
        document.body.style.paddingTop = '130px'
      } else {
        document.body.style.paddingTop = '65px'
      }
    },
    readyToSetCrispWidget (value) {
      if (value) {
        this.addWidget()
      }
    }
  },
  mounted () {
    if (this.isTablet) {
      document.body.style.paddingTop = '130px'
    }
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://app.termly.io/resource-blocker/681018c5-660c-4911-ac6d-455c7c1f2eb2?autoBlock=off'
    script.async = true
    document.head.appendChild(script)
  },
  computed: {
    ...mapGetters([
      'languages',
      'useImpersonalization'
    ]),
    mobilePlatform () {
      return this.getOS === 'Android' || this.getOS === 'iOS'
    },
    companyId () {
      return this.$store.getters.company.id
    },
    confirmRequired () {
      return this.$store.getters.require_user_confirm
    },
    tz () {
      return this.$store.getters.profileTimeZone
    },
    isWebSite: function () {
      const paths = ['/password/recovery/confirm/', '/invite/confirm/', '/invite/registration/', '/confirm/', '/unsubscribe/']
      for (const patKey in paths) {
        if (this.$route.path.indexOf(paths[patKey]) !== -1) {
          return true
        }
      }
      return this.$store.state.webSiteUrls.includes(this.$route.path)
    },
    hideLanguage: function () {
      const paths = ['/confirm/', '/unsubscribe/']
      for (const patKey in paths) {
        if (this.$route.path.indexOf(paths[patKey]) !== -1) {
          return true
        }
      }
      return false
    },
    isAuthorized () {
      return !!this.$store.getters.profileId
    },
    openModal () {
      return this.$store.state.modal === true
    },
    year () {
      const dt = new Date()
      return dt.getFullYear()
    },
    locale () {
      return this.$store.getters.locale
    },
    elDialogWidth () {
      const lWidth = window.screen.width
      if (lWidth < 800) {
        return '95%'
      }
      return '40%'
    },
    readyToSetCrispWidget () {
      return !!(this.isAuthorized && this.companyId)
    },
    weekStart () {
      if (!this.$store.getters.company || !this.$store.getters.company.salary) {
        return 0
      }
      return +this.$store.getters.company.salary.week_start + 1
    },
    localeElements () {
      let loc
      switch (this.locale) {
        case 'ru':
          dayjs.Ls.ru ??= {}
          dayjs.Ls.ru.weekStart = this.weekStart
          loc = ru
          break
        case 'he':
          dayjs.Ls.he ??= {}
          dayjs.Ls.he.weekStart = this.weekStart
          loc = he
          break
        case 'uk':
          dayjs.Ls.uk ??= {}
          dayjs.Ls.uk.weekStart = this.weekStart
          loc = uk
          break
        case 'de':
          dayjs.Ls.de ??= {}
          dayjs.Ls.de.weekStart = this.weekStart
          loc = de
          break
        case 'es':
          dayjs.Ls.es ??= {}
          dayjs.Ls.es.weekStart = this.weekStart
          loc = es
          break
        case 'pl':
          dayjs.Ls.pl ??= {}
          dayjs.Ls.pl.weekStart = this.weekStart
          loc = pl
          break
        default:
          dayjs.Ls.en ??= {}
          dayjs.Ls.en.weekStart = this.weekStart
          loc = en
      }
      return loc
    },
    profile () {
      return this.$store.getters.Profile
    },
    company () {
      return this.$store.getters.company
    }
  },
  methods: {
    confirmShifts () {
      this.showConfirmShiftsModal = false
      const data = {
        companyId: this.companyId,
        shifts: {
          shift_id: this.unconfirmedShifts.map(shift => shift.id)
        }
      }
      this.$store.dispatch('markShiftsAsConfirmed', data).then(response => {
        this.showConfirmShiftsModal = false
        this.toastSuccess(this.$t('SHIFTS_CONFIRMATION_MESSAGE'))

        this.$eventBus.emit('refetchEvents')
      })
    },
    handleShiftConfirmationModal (value) {
      this.$store.dispatch('getUnconfirmedShifts', value).then(response => {
        this.unconfirmedShifts = response.shifts
        this.unconfirmedShifts.forEach(shift => {
          shift.time_from = this.fromZoneToZone(shift.time_from, 'UTC', this.tz).format(this.localeDateTimeFormat) // moment(shift.time_from).format(this.localeDateTimeFormat)
          shift.time_to = this.fromZoneToZone(shift.time_to, 'UTC', this.tz).format(this.localeDateTimeFormat)
        })
        if (this.unconfirmedShifts.length > 0 && !this.showConfirmShiftsModal) {
          this.showConfirmShiftsModal = true
        }
      })
    },
    langSwitch (e) {
      if (e) {
        loadLanguageAsync(e)
        this.$router.push({ query: { lang: e } }).catch((e) => {})
        this.localeDropdown = e
      }
    },
    logout: function () {
      clearInterval(this.timerId)
      this.$this.$crisp.setTokenId()
      this.$this.$crisp.session.reset()
      this.$store.dispatch('logout', this.$store.getters.locale).then()
    },
    openChangePasswordModal () {
      this.showChangePassword = true
    },
    openCreateRequestModal () {
      this.showCreateRequest = true
    },
    closeChangePasswordModal () {
      this.showChangePassword = false
    },
    closeCreateRequestModal () {
      this.showCreateRequest = false
    },
    addWidget () {
      // if ((process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development') && !this.useImpersonalization) {
      if (process.env.NODE_ENV === 'production' && !this.useImpersonalization) {
        this.$store.dispatch('getCrispHash').then(response => {
          if (this.$crisp) {
            this.$crisp.configure(process.env.VUE_APP_CRISP_WEBSITE_ID)
            if (this.profile.email) {
              this.$crisp.user.setEmail(this.profile.email, response.user_hash)
              this.$crisp.user.setNickname(this.profile.name)
              if (this.profile.phone) {
                this.$crisp.user.setPhone(this.profile.phone)
              } else if (this.profile.phones && Object.keys(this.profile.phones).length > 0) {
                const firstPhone = Object.keys(this.profile.phones)[0]
                this.$crisp.user.setPhone(firstPhone.toString())
              }

              this.$crisp.session.setData({
                company: this.company.name,
                phone: this.profile.phone,
                email: this.profile.email,
                user_id: this.profile.id,
                employee_id: this.company.employee_id,
                company_id: this.company.id,
                job_title: this.getRole(this.company.role),
                locale: this.profile.locale,
                company_owner: this.company.owner_id === this.profile.id,
                industry: this.company.industry,
                size: this.company.workers,
                created_at: this.company.created_at,
                work_mode: this.company.work_mode,
                last_activity: this.fromZoneToZone(moment(), this.profile.tz, 'UTC').format(this.backendDateTimeFormat)
              })

              let employmentTitle = this.getRole(this.company.role)
              if (this.company.owner_id === this.profile.id) {
                employmentTitle = 'owner'
              }

              this.$crisp.user.setCompany(this.company.name, {
                employment: {
                  title: ' ', // The title must be filled if you want to put a title
                  role: employmentTitle
                }
              })
            }
          }
        })
      }
    },
    getRole (group) {
      let role
      switch (group) {
        case 'gg_admin':
          role = 'admin'
          break
        case 'gg_manager':
          role = 'manager'
          break
        default:
          role = 'user'
      }
      return role
    }
  }
}
</script>

<style lang="scss">
  @import "./assets/styles/custom-bootstrap";
  $body-bg:                   #FBFBFB;
  $primary:                   #53a8dd;

  // load Shards theme
  @import "~shards-ui/src/scss/shards";

  // finally load basic bootstrap
  @import "~bootstrap-v4-rtl/scss/bootstrap-rtl";

  @import "./assets/icomoon/style.css";

  /* todo: разобраться как добавить шрифт не из гугла и чтобы bold работал в chrome*/
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700&subset=all');
  @import "~vue-tel-input/vue-tel-input.css";
  @import url('https://fonts.googleapis.com/earlyaccess/alefhebrew.css');

  /* boostrap, fullcalendar & elementUI rtl global overrides */
  .rtl, [dir="rtl"] {

    * {
      font-family: 'Alef Hebrew', 'Arial', sans-serif;
    }

    .dropdown-menu-right {
      right: auto;
      left: 0;
    }

    .dropdown-menu {
      right: inherit;
    }

    .fc-toolbar {
      direction: rtl;
    }

    .fc-toolbar>*>:not(:first-child) {
      margin-right: .75em;
    }
    .vue-tel-input {
      direction: ltr;
    }

    .el-step__main {
      text-align: inherit;
    }
    .el-step.is-vertical .el-step__main {
      padding-right: 10px;
    }

    .el-select .el-tag {
      margin-right: 6px;
      margin-left: 0;
    }
    .el-select .el-tag__close.el-icon-close {
      right: 0;
      left: -7px;
    }

    .el-select-dropdown__item {
      margin-right: 15px;
    }

    .el-switch__label--left {
      margin-right: 0;
      margin-left: 10px
    }
    .el-switch__label--right {
      margin-left: 0;
      margin-right: 10px
    }

    .el-checkbox {
      margin-right: 0;
      margin-left: 30px;
    }
    .el-checkbox__label {
      padding-left: 0;
      padding-right: 10px;
    }
    .el-checkbox:last-of-type {
      margin-left: 0;
    }

    .el-radio__label {
      padding-left: 0;
      padding-right: 10px;
    }
    // keep time input ltr
    .el-time-spinner {
      direction: ltr;
    }
    .el-input__inner {
      direction: ltr;
    }
    .time-select-item {
      margin-right: 15px;
    }
    .navbar-nav .nav-item + .nav-item {
      margin-right: inherit;
      margin-left: 0rem;
    }
  }

  .fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
    top: 65px!important; /* because border-sharing causes a gap at the top */
  }

  /* other */
  .size-lg {
    font-size: 1.25em;
  }

  .el-message-box {
    padding: 25px!important;
    text-align: center!important;
  }

  .el-select__popper.el-popper {
    z-index: 20047!important;
  }

  /* тени на кнопки */
  //.el-input__inner:focus {
  //  border-color: #00b7f1;
  //  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.175)!important;
  //}
  .is-invalid {
    border: 1px solid red;
    border-radius: 10px;
  }
  .el-select-dropdown {
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.175)!important;
  }
  .el-select__tags-text {
    color: #212529 !important;
  }
  .el-input__inner {
    padding-left: 10px !important;
    padding-right: 10px !important;
    min-height: calc(2.425rem + 2px) !important;
  }
  .el-input-group__prepend {
    border-bottom-left-radius: 0.5rem !important;
    border-top-left-radius: 0.5rem !important;
  }
  .el-checkbox__input { // выравниваем чекбоксы по вертикали по отношению к лейблу
    vertical-align: top!important;
    margin-top: 3px;
  }
  .el-radio__input { // выравниваем радиокнопки по вертикали по отношению к лейблу
    vertical-align: top!important;
  }
  .el-notify {
    z-index: 2147483101!important;
  }
  a:hover {
    text-decoration: none;
  }
  .bg-raspisanka {
    background-color: #0aa4eb;
  }
  .bg-raspisanka-dlight {
    background-color: #b6eaff;
  }
  .bg-raspisanka-light {
    background-color: #d0f2ff;
  }

  .table-header {
    background-color: #dbdbdb!important;
  }
  .white {
    color: #ffffff;
  }
  .header-text {
    font-size: 18px;
  }
  .dropdown-toggle::after {
    border-color: white;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent
  }

  .title-text {
    color: #697882;
    font-family: "Open Sans";
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
    padding-top: 0.75rem !important;
  }

  .font-blue-oleo {
    color: #94A0B2;
  }
  .font-xs {
    font-size: 11px;
  }
  .font-header {
    color: #0075d0!important;
  }
  /* forms */
  .dropdown-toggle {
    border-radius: 7px!important;
  }
  /* rounded button */
  .btn-circle {
    border-radius: 25px;
    font-size: 0.8rem;
  }

  .btn-dark-green {
    color: #fff;
    font-weight: normal;
    background-color: #00c690;
    border-color: #00c690;
  }
  .btn-grey {
    color: #fff;
    font-weight: lighter;
    background-color: #898989;
    border-color: #898989;
  }
  .btn-cancel {
    color: #333;
    background-color: #fff;
    border-color: #ccc
  }
  .btn-height {
    height: 27px;
  }

  /* Icon */
  .icon-default {
    font-size: 20px;
  }

  .btn-icon {
    cursor: pointer;
    font-size: 20px;
  }

  .btn-icon-lg {
    cursor: pointer;
    font-size: 40px;
  }
  /*Input*/
  .el-input-60px {
    width: 60px!important;
  }

  /*Datepickers*/
  .el-date-picker-160px {
    width: 160px!important;
  }

  /*Wizard*/
  .el-step__head.is-success {
    color: #53a8dd !important;
    border-color: #53a8dd !important;
  }
  .el-step__title.is-success {
    color: #53a8dd !important;
  }
  .shadow-custom {
    box-shadow: 0 0.75rem 1rem rgba(0, 0, 0, 0.2) !important
  }
  /*Checkbox*/
  .checkbox-component > input + label > .input-box {
    border-color: #d3cfc8!important;
    background: #fff!important;
    height: 26px!important;
    width: 26px!important;
    border-radius: 7px!important;
  }
  .checkbox-component > input:checked + label > .input-box {
    background:  url("../public/static/images/App/checkbox-green.png") no-repeat center !important;
    background-size: 100% 100% !important;
  }
  .checkbox-component > input + label > .input-box > .input-box-tick > path {
    stroke: #fff;
  }
  .checkbox-label {
    vertical-align: bottom;
  }
  /*Radio button*/
  .radio-component > input + label > .input-box {
    border: 0 !important;
    background: url("../public/static/images/App/radio.png") no-repeat center !important;
    height: 25px!important;
    width: 25px!important;
  }
  .radio-component > input:checked + label > .input-box > .input-box-circle {
    display: none;
  }
  .radio-component > input:checked + label > .input-box {
    height: 25px!important;
    width: 25px!important;
    background:url("../public/static/images/App/radio-active.png") no-repeat center !important;
  }

  .radio-component > label {
    margin-bottom: 0rem !important;
  }

  .radio-label {
    vertical-align: super;
  }

  /*Range date picker смещаем датапикеры друг под друга для маленьких экранов*/
  @media (max-width:640px){
    .el-date-range-picker__content {
      width: 100%!important;
    }
    .el-date-range-picker > .el-picker-panel__body-wrapper > .el-picker-panel__body {
       min-width: 100px!important;
    }
    .el-picker-panel.el-date-range-picker.el-popper {
      width: auto!important;
    }
  }
  .icon-hover {
    cursor: pointer;
  }

  /*color for requests title*/
  .name-color {
    color: #0aa4eb;
  }
  button:focus {
    outline:0;
  }

  .font-weight-bold {
    font-weight: 600 !important;
  }

  .search_table {
    border-top: none;
    border-left: none;
    border-right: none;
    opacity: 0.6;
    width: 150px;
    border-bottom: 1px solid #0606065e;
    padding: 5px 5px;
    outline: none;
  }

  .secondary {
    background-color: #f1f1f1;
  }

  /* !!!!!!! Актуальные стили !!!!!!! */
  html {
    --main-site-color: #4a4a4a;
  }
  body {
    //font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    padding-top: 65px;
  }

  /*font size-16px*/
  .fs-16 {
    font-size: 16px;
  }

  /* Радиусы */
  .radius-all {
    border-radius: 7px!important;
  }

  .pointer-cursor {
    cursor: pointer;
  }

  .hint {
    color: #606266 !important;
  }
  .dismiss {
    background-color: #ffe6bc;
  }

  .project-color {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 25px;
    vertical-align: middle;
  }

  /* START DUMMY MODAL */
  .modal-dummy {
    max-width: 1140px;
  }

  .dummy-popup {
    border-radius: 3px;
    text-align: center;
    padding: 0;
    position: relative;
    width: auto;
    height: 450px;
  }

  /* END DUMMY MODAL */
</style>
