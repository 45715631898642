<template>
  <Field :name="name" v-model="innerValue" v-slot="{ errors }" :rules="validation" as="div">
    <ui-input-label v-if="label" :label="label" :hint="hint"/>
    <el-input-number
      v-model="innerValue"
      @input="$emit('update:modelValue', innerValue)"
      @change="$emit('update:modelValue', innerValue)"
      @blur="$emit('update:modelValue', innerValue)"
      :name="name"
      :min="min"
      :max="max"
      :disabled="!!disabled"
      :placeholder="placeholder"
      :class="{'is-invalid': errors.length}"
      style="max-width: 70px;"
      :controls="controls"
      :controls-position="controlsPosition"
      prefix-icon="disabled"
    >
    </el-input-number>
    <span v-show="errors.length" class="invalid-feedback">{{ errors[0] }}</span>
  </Field>
</template>

<script>

export default {
  name: 'integer-input',
  emits: ['update:modelValue'],

  props: {
    name: String,
    validation: [String, Object],
    value: [Number, String],
    modelValue: [Number, String],
    placeholder: String,
    disabled: Boolean,
    label: String,
    hint: String,
    min: Number,
    max: Number,
    controls: Boolean,
    controlsPosition: {
      type: String,
      default: null
    }
  },

  computed: {
    innerValue: {
      get () {
        return this.modelValue === '' ? null : this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }

}
</script>
<style lang="scss">
.el-input__inner {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
