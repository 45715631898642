<template>
  <modal-component :id="'DistributeFreeBoardModal'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title" id="">{{ $t('DISTRIBUTE_SHIFTS')}}</h5>
    </template>
    <template #body>
      <Form ref="observer" as="div">
        <div class="row">
          <div class="col">
            <ui-date-picker
              name="start_date"
              :label="$t('From')"
              v-model="from"
              :validation="{required: true}"
              @change="onChangeStart"
              :disabledDate="disabledDate"
            />
          </div>
          <div class="col">
            <ui-date-picker
              name="end_date"
              :label="$t('To')"
              v-model="to"
              :validation="{required: true}"
              @change="onChangeEnd"
              :disabledDate="disabledDate"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <ui-multi-select
              name="SELECT_SCHEDULES"
              :label="$t('SELECT_SCHEDULES')"
              :placeholder="$t('Select schedules')"
              v-model="schedulesValue"
              :options="schedulesOptions"
              :key-name="'id'"
              :label-name="'name'"
              :value-name="'id'"
              :validation="{required: true}"
            />
          </div>
        </div>
      </Form>
    </template>
    <template #footer>
      <div>
        <button
          class="btn btn-success rounded-pill shadow-sm"
          @click="distribute">
          {{ $t('Distribute') }}
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import ToastMixin from '@/mixins/ToastMixin'
import errorMixin from '@/mixins/mixinApiErrors'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import mixinColorsMarks from '@/mixins/mixinColorsMarks'
import moment from 'moment'

export default {
  name: 'DistributeFreeBoardModal',
  mixins: [momentMixin, errorMixin, mixinColorsMarks, ToastMixin],
  components: {
    ModalComponent: ModalComponent
  },

  data () {
    return {
      from: moment().startOf('day'),
      to: moment().startOf('day'),
      schedulesValue: [],
      schedulesOptions: []
    }
  },

  props: {
    start: Date,
    end: Date,
    schedules: Object
  },
  created () {
    if (this.start && this.end) {
      this.from = moment(this.start).toISOString()
      this.to = moment(this.end).add(-1, 'day').toISOString()
    }
    this.handleSchedulesOptions()
  },
  mounted () {},
  computed: {
    companyId () {
      return this.$store.getters.companyId
    }
  },
  watch: {
    schedules () {
      this.handleSchedulesOptions()
    },
    start () {
      this.from = moment(this.start).toISOString()
    },
    end () {
      this.to = moment(this.end).add(-1, 'day').toISOString()
    }
  },
  methods: {
    handleSchedulesOptions () {
      this.schedulesValue = []
      this.schedulesOptions = []
      Object.keys(this.schedules).forEach(key => {
        if (this.schedules[key].schedule_type !== 'by_pattern') {
          this.schedulesOptions.push({
            id: key,
            name: this.schedules[key].name
          })
        }
      })
    },
    disabledDate (date) {
      return moment(date).isSameOrAfter(moment(this.end)) || moment(date).isBefore(moment(this.start))
    },
    onChangeStart () {
      if (moment(this.from).isAfter(moment(this.to))) {
        this.to = this.from
      }
    },
    onChangeEnd () {
      if (moment(this.to).isBefore(moment(this.from))) {
        this.from = this.to
      }
    },
    closeModal () {
      this.schedulesValue = []
      this.$emit('closeDistributeFreeBoardModal')
    },
    distribute () {
      this.$refs.observer.validate().then(({ valid }) => {
        if (valid) {
          this.$emit('distributeFreeBoard', {
            date_from: this.fromZoneToZone(this.from, this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat),
            date_to: this.fromZoneToZone(this.to, this.$store.getters.actualTZ, 'UTC').add(1, 'day').format(this.backendDateTimeFormat),
            schedules: [...this.schedulesValue]
          })
          this.schedulesValue = []
          document.getElementById('modalCloseButton').click()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .project-color {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 25px;
    vertical-align: middle;
  }
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .checkbox-wrapper :deep(.el-checkbox) {
    margin: 0;
  }
  .el-checkbox :deep(.el-checkbox__inner) {
    width: 30px;
    height: 30px;
  }
  .el-checkbox :deep(.el-checkbox__inner::after) {
    border: 3px solid #FFF;
    border-left: 0;
    border-top: 0;
    height: 14px;
    left: 11px;
    top: 4px;
  }
</style>
