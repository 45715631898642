<template>
  <div>
    <div
      v-if="isEmployeeHasPermission('split-shift-admin') ||
      rolesByProjects[currentProjectId] === 'manager' ||
       (isEmployeeHasPermission('split-shift-employee') && schedule.allow_split_shift_by_employee && +shift.extendedProps.employee_id === +currentEmployeeID)"
      class="mb-3">
      <div class="text-primary text-uppercase font-weight-bold">
        {{ $t('Split this shift') }}
      </div>
      <div class="text-primary text-center my-2">
        {{ $t('Split your shift to select working time and place the rest of the period on the Free Board.') }}
      </div>
      <div class="row">
        <div class="col-4 text-left">
          {{shiftStart}}
        </div>
        <div class="col-4 text-center">
          {{ $t('Shift time')}}
        </div>
        <div class="col-4 text-right">
          {{shiftEnd}}
        </div>
      </div>
      <vue-slider
        ref="slider"
        class="px-2"
        v-model="shiftMiddle"
        :data="splitData"
        tooltip-dir="bottom">
      </vue-slider>
      <div class="my-2 text-center">
        {{ $t('Select a time period you want to place on the Free Board.') }}
      </div>
      <div class="text-center">
        <ui-radio
          name="first"
          :label="shiftStart + ' - ' + shiftMiddle"
          v-model="splitPart"
          :set-value="'first'"
        />
        <ui-radio
          name="second"
          :label="shiftMiddle + ' - ' + shiftEnd"
          v-model="splitPart"
          :set-value="'second'"
        />
      </div>
      <div v-if="showButtons" class="text-center my-2">
        <button class="btn btn-success rounded-pill shadow-sm"
                :class="{ disabled: this.shiftRequest }"
                aria-label="Close"
                @click="splitShiftTap">
          {{ $t('Split this shift') }}
        </button>
      </div>
      <el-divider v-if="isEmployeeHasPermission('split-shift-employee')"></el-divider>
    </div>
    <template v-if="!isEmployeeHasPermission('split-shift-admin') && rolesByProjects[currentProjectId] !== 'manager'">
      <template v-if="shiftInfoState.request_id">
        <div class="my-2 text-primary text-uppercase font-weight-bold text-center">
          {{ $t('ACTIONS_DISABLED_BY_UNRESOLVED_REQUEST') }}
        </div>
      </template>
      <template v-else>
        <div v-if="isAllowMoveToFreeboard">
          <div class="row mt-2">
            <div class="col">
              <div class="mb-2 text-primary text-center text-uppercase font-weight-bold">
                {{ $t('MOVE_SHIFT_TO_FREEBOARD') }}
              </div>
              <div class="text-center mt-2">
                <button
                  class="btn btn-primary rounded-pill shadow-sm"
                  aria-label="Close"
                  @click="putOnFreeBoard">
                  {{ $t('SHIFT_TO_FREEBOARD_BUTTON') }}
                </button>
              </div>
            </div>
          </div>
          <el-divider></el-divider>
        </div>
        <div v-if="(+shiftEmployeerId === +currentEmployeeID)">
          <div class="my-2 text-primary text-uppercase font-weight-bold">
            {{ $t('CHANGE_SHIFT_MODAL_TITLE') }}
          </div>
          <div class="row my-2">
            <div class="col-md-6 py-1">
              <ui-datetime-picker
                :label="$t('SHIFT_START')"
                name="from"
                :placeholder="$t('Select date and time')"
                v-model="startDateTime"
                @change="$emit('updateOvertimeCheck')"
                :validation="{required: true}"
                :clearable="false"
                :disabledDate="disabledDateFrom"
              />
            </div>
            <div class="col-md-6 py-1">
              <ui-datetime-picker
                :label="$t('SHIFT_END')"
                name="to"
                :placeholder="$t('Select date and time')"
                v-model="endDateTime"
                @change="$emit('updateOvertimeCheck')"
                :validation="{required: true}"
                :clearable="false"
                :disabledDate="disabledDateTo"
              />
            </div>
          </div>
          <div class="row my-2">
            <div class="col py-1">
              <ui-textarea-input
                name="note"
                :label="$t('SHIFT_CHANGE_COMMENT')"
                v-model="message"
                :validation="{required: false}"
                :rows="2"
              />
            </div>
          </div>
          <div class="row my-2">
            <div class="col py-1">
              <div class="text-center mt-2">
                <button class="btn btn-primary rounded-pill shadow-sm"
                        aria-label="Close"
                        @click="changeShiftRequest">
                  {{ $t('CHANGE_SHIFT') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="(+shiftEmployeerId === +currentEmployeeID) && (showLocationSelect || showMarkSelect)">
          <el-divider></el-divider>
          <div class="row mt-2">
            <div v-if="showLocationSelect" class="col-12 col-md-6">
              <ui-select
                :label="$t('SHIFT_EDIT_LOCATION_SELECT')"
                :hint="$t('SHIFT_EDIT_LOCATION_SELECT_HINT')"
                :name="'location'"
                v-model="shiftInfoState.location_id"
                :clearable="true"
                :placeholder="$t('Select')"
                :options="locations"
                :key-name="'id'"
                :label-name="'name'"
                :value-name="'id'"
              />
            </div>
            <div v-if="showMarkSelect" class="col-12 col-md-6">
              <ui-tags-select
                :label="$t('Shift positions')"
                :hint="$t('POSITION_HINT')"
                :name="'positions'"
                v-model="shiftMarks"
                :clearable="true"
                :filterable="true"
                :placeholder="$t('Select')"
                :options="projectMarks"
                :key-name="'id'"
                :label-name="'name'"
                :value-name="'id'"
              >
                <template v-slot:default="slotProps">
                  <div  class="user-mark mr-3" v-bind:style="{ backgroundColor: slotProps.option.color }"></div>
                  {{ slotProps.option.name }}
                </template>
              </ui-tags-select>
            </div>
          </div>
          <div class="text-center mt-2">
            <button
              class="btn btn-primary rounded-pill shadow-sm"
              aria-label="Close"
              @click="shiftEditEmployee">
              {{ $t('SAVE_WEB') }}
            </button>
          </div>
        </div>
        <div v-if="!warning && swapShiftsShow && (+shiftEmployeerId === +currentEmployeeID)">
          <el-divider></el-divider>
          <!--<transition name="expand">-->
          <div class="my-2 text-primary text-uppercase font-weight-bold">
            {{ $t('Swap shifts with another user') }}
          </div>
          <div class="row">
            <div class="col-12">{{$t('Trade this shift')}}</div>
          </div>
          <div class="row">
            <div class="col-sm-3">{{shiftStartDate}}</div>
            <div class="col-sm-9">{{shiftStart}} - {{shiftEnd}}</div>
          </div>
          <template v-if="shiftsToSwap.length > 0 && preloadUsers">
            <p class="mx-3 mb-0 text-right" style="background-color: #efefef; margin-bottom: 0px; text-align: right;">
              <a class="mx-1" href="#" @click="selectAllShifts">{{$t('All')}}</a>
              <a class="mx-1" href="#" @click="unselectAllShifts">{{$t('None')}}</a>
            </p>
            <el-container style="height: 300px; overflow-y: auto; background-color: #efefef">
              <div class="w-100 mx-2">
                <table class="table-shifts-to-swap table-sm w-100">
                  <tr v-for="item in shiftsToSwap" v-bind:key="item.id" class="rounded p-0 m-0" style="height: 39px!important;">
                    <td v-on:click="toggleItemToSwap(item.id)" class="exchange-day-block align-middle" v-bind:style="{backgroundColor: item['color'], borderRadius: '5px',}">
                      {{ formatStringToDate(item['time_from']) }}
                    </td>
                    <td v-on:click="toggleItemToSwap(item.id)" class="align-middle">
                      <div class="m-0 pl-1 py-0">
                        {{formatStringToTime(item['time_from'])}} - {{formatStringToTime(item['time_to'])}}
                        <span v-if="item['template'] != null">{{item['template']['name']}}</span>
                        <span v-else>{{$t('Custom shift')}}</span>
                      </div>
                      <div v-if="preloadUsers[item['employee_id']]" class="m-0 py-0 pl-1 small">
                        {{ preloadUsers[item['employee_id']]['full_name']}}
                      </div>
                    </td>
                    <td class="float-right rounded-right">
                      <ui-checkbox-group v-model="checkedShiftsToSwap">
                        <ui-checkbox
                          :name="item.id + '__checkedShiftsToSwap'"
                          :custom-value="item['id']"
                        >
                          <div></div>
                        </ui-checkbox>
                      </ui-checkbox-group>
                    </td>
                  </tr>
                </table>
              </div>
            </el-container>
            <div class="text-center mt-2">
              <button class="btn btn-primary rounded-pill shadow-sm"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="initiateSwap">
                {{ $t('Initiate swap') }}
              </button>
            </div>
          </template>
          <template v-else>
            <p class="mt-2 mb-0 small text-center">{{$t('No shifts to swap')}}</p>
          </template>
        </div>
        <div v-else class="my-2 text-primary text-uppercase font-weight-bold text-center">
          <template v-if="!swapShiftsShow">
            {{ $t('Shift swapping is disabled') }}
          </template>
        </div>
        <template v-if="schedule.allow_drop_to_employee && showDrop">
          <el-divider />
          <Form ref="observer" as="div" class="row">
            <div class="col-12">
              <ui-tags-select
                :label="$t('DROP_TO_EMPLOYEE_TITLE')"
                :hint="$t('DROP_TO_EMPLOYEE_TITLE_HINT')"
                v-model="dropEmployeeIds"
                :clearable="true"
                :filterable="true"
                :placeholder="$t('Select')"
                :options="employeesToDrop"
                :key-name="'id'"
                :label-name="'full_name'"
                :value-name="'id'"
                name="drop-to-employee"
                :validation="{required: true}"
              >
                <template v-slot:default="slotProps">
                  <div  class="user-mark mr-3" v-bind:style="{ backgroundColor: slotProps.option.color }"></div>
                  {{ slotProps.option.full_name }}
                </template>
              </ui-tags-select>
            </div>
            <div class="col-12 text-center my-2">
              <button
                class="btn btn-primary rounded-pill shadow-sm"
                aria-label="Close"
                @click="dropRequest">
                {{ $t('CHANGE_SHIFT') }}
              </button>
            </div>
          </Form>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ToastMixin from '@/mixins/ToastMixin'
import momentMixin from '@/mixins/mixinMoment'
import errorMixin from '@/mixins/mixinApiErrors'
import moment from 'moment'
import vueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'ShiftActionComponent',
  mixins: [
    ToastMixin,
    momentMixin,
    errorMixin
  ],
  components: {
    vueSlider
  },
  data () {
    return {
      shiftMiddle: '',
      splitData: [],
      splitPart: 'first',
      startDateTime: null,
      endDateTime: null,
      message: '',
      shiftMarks: [],
      swapShiftsShow: true,
      start: null,
      end: null,
      shiftStart: null,
      shiftEnd: null,
      shiftStartDate: null,
      shiftsToSwap: [],
      checkedShiftsToSwap: [],
      showLocationSelect: false,
      showMarkSelect: false,
      schedule: null,
      employeesToDrop: [],
      dropEmployeeIds: [],
      shiftInfoState: {}
    }
  },
  props: ['shiftInfo', 'shift', 'schedulesById', 'locations', 'currentProjectData', 'closeModal', 'shiftRequest'],
  created () {
    this.shiftInfoState = this.shiftInfo
    this.preloadShiftsToSwap()
    this.startDateTime = this.shift.start
    this.endDateTime = this.shift.end
    this.shiftMarks = this.shift.extendedProps.marks
    const schedule = this.schedulesById[this.shift.extendedProps.schedule_id]
    this.schedule = schedule
    // console.log(456, this.shift.start)
    this.start = moment(this.shift.start)
    this.end = moment(this.shift.end)
    this.shiftStart = this.start.format(this.localeTimeFormat)
    this.shiftEnd = this.end.format(this.localeTimeFormat)
    this.shiftStartDate = this.start.format(this.localeDateFormat)
    const shiftExchange = schedule.shift_exchange || this.currentProjectData.shifts_exchange
    if (moment.utc(this.start) < moment.utc() || !shiftExchange) {
      this.swapShiftsShow = false
      // this.action = 2
    }
    this.ModulesByCompany.forEach((module) => {
      if (+module.module_id === 8) { // базовый модуль
        if (!Array.isArray(module.settings)) {
          this.showLocationSelect = !!module.settings.allow_set_location
          this.showMarkSelect = !!module.settings.allow_set_mark
        }
      }
    })
    const time = this.start.clone()
    while (time <= this.end) {
      this.splitData.push(moment(time).format(this.localeTimeFormat))
      time.add(15, 'm')
    }
    const indexMiddle = Math.floor(this.splitData.length / 2)
    this.shiftMiddle = this.splitData[indexMiddle]
    if (this.isEmployeeHasPermission('get-employees-to-drop') && this.rolesByProjects[this.currentProjectId] !== 'manager') {
      this.$store.dispatch('employeesToDrop', this.shift.id).then(response => {
        this.employeesToDrop = response.sort((a, b) => {
          if (a.full_name > b.full_name) return 1
          if (a.full_name < b.full_name) return -1
          return 0
        })
        // if (this.employeesToDrop.length > 0) {
        //   this.dropEmployeeId = this.employeesToDrop[0].id
        // }
      })
    }
  },
  computed: {
    ...mapGetters({
      currentProjectId: 'currentProjectId',
      projectMarks: 'projectMarks',
      preloadUsers: 'employeesIndexed',
      ModulesByCompany: 'ModulesByCompany'
    }),
    plannedSDT () {
      return this.shift.extendedProps.plannedStart
    },
    plannedEDT () {
      return this.shift.extendedProps.plannedEnd
    },
    currentEmployeeID () {
      return this.$store.getters.company.employee_id
    },
    isAllowMoveToFreeboard () {
      // console.log(this.shiftInfo)
      // бонусы
      if (this.shiftInfoState.bonuses && this.shiftInfoState.bonuses.length > 0) {
        return false
      }
      // задачи
      if (this.shiftInfoState.tasks && this.shiftInfoState.tasks.length > 0) {
        return false
      }
      // настройки шедуля и время шифта
      return !this.shift.isInPast && (+this.shift.extendedProps.employee_id === +this.currentEmployeeID) && this.schedulesById[this.shift.extendedProps.schedule_id].allow_put_on_freeboard
    },
    shiftEmployeerId () {
      return this.shift.extendedProps.employee_id
    },
    warning () {
      let value = true
      this.ModulesByCompany.forEach((module) => {
        if (+module.module_id === 8) { // базовый модуль
          if (!Array.isArray(module.settings)) {
            value = this.shift.isInPast && !!module.settings.should_warning_shift_in_past
          } else {
            value = this.shift.isInPast
          }
        }
      })
      return value
    },
    showDrop () {
      return this.start.isAfter(moment())
    },
    showButtons () {
      if (this.isEmployeeHasPermission('update-schedule-admin')) {
        return true
      }
      const project = this.$store.getters.projectById(this.currentProjectId)

      return !(project.block_change_in_past_for_manager === true && moment(this.startDateTime).isBefore(moment()))
    }
  },
  methods: {
    disabledDateFrom (time) {
      return time.getTime() > moment(this.plannedSDT).add(1, 'days') || time.getTime() < moment(this.plannedSDT).add(-2, 'days')
    },
    disabledDateTo (time) {
      return time.getTime() > moment(this.plannedEDT).add(1, 'days') || time.getTime() < moment(this.plannedEDT).add(-2, 'days')
    },
    preloadShiftsToSwap () {
      if (this.shiftEmployeerId !== this.currentEmployeeID) {
        return
      }
      this.$store.dispatch('shiftsToSwap', [this.shift.id]).then((response) => {
        if (response.length > 0) {
          this.shiftsToSwap = response
        } else {
          console.log('no shifts to swap')
          this.shiftsToSwap = []
        }
      }).catch(() => {
        // console.log(error)
        this.shiftsToSwap = []
      })
    },
    selectAllShifts () {
      this.checkedShiftsToSwap = this.shiftsToSwap.map(s => { return s.id })
    },
    unselectAllShifts () {
      this.checkedShiftsToSwap = []
    },
    toggleItemToSwap (value) {
      const index = this.checkedShiftsToSwap.indexOf(value)
      if (index === -1) {
        this.checkedShiftsToSwap.push(value)
      } else {
        this.checkedShiftsToSwap.splice(index, 1)
      }
    },
    initiateSwap () {
      this.$store.dispatch('initiateSwap', [this.$store.getters.companyId, this.shift.id, this.checkedShiftsToSwap]).then((response) => {
        this.toastSuccess(this.$i18n?.t('Requests was send'))
      }).catch((error) => {
        this.toastError(this.$i18n?.t(error.response.data.message))
      })
    },
    splitShiftTap (event) {
      if (this.shiftRequest) {
        event.stopImmediatePropagation()
        return
      }

      if (this.shiftInfoState.breaks && this.shiftInfoState.breaks.length) {
        const body = document.getElementsByTagName('body')
        body[0].setAttribute('style', 'padding-right: 0px;')
        this.$confirm(this.$i18n?.t('key_warning_shift_have_breaks'), this.$i18n?.t('Warning'), {
          confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
          cancelButtonText: this.$i18n?.t('No, cancel it!!'),
          type: 'warning',
          center: true
        }).then(() => {
          this.splitShift()
        }).catch(() => {})
      } else {
        this.splitShift()
      }
    },
    splitShift () {
      if (this.isEmployeeHasPermission('split-shift')) {
        this.splitShiftAction()
      } else {
        const body = document.getElementsByTagName('body')
        body[0].setAttribute('style', 'padding-right: 0px;')
        this.$confirm(this.$i18n?.t('Are you sure that you want to send this time period to the Free Board?'), this.$i18n?.t('Warning'), {
          confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
          cancelButtonText: this.$i18n?.t('No, cancel it!!'),
          type: 'warning',
          center: true
        }).then(() => {
          this.splitShiftAction()
        }).catch(() => {})
      }
    },
    splitShiftAction () {
      if (this.shiftMiddle === this.splitData[0] || this.shiftMiddle === this.splitData[this.splitData.length - 1]) {
        document.getElementById('modalCloseButton').click()
        this.closeModal()
      } else {
        const data = {}
        const index = this.splitData.indexOf(this.shiftMiddle)
        let middleDate = this.start.clone()
        middleDate = middleDate.add(15 * index, 'm').format(this.backendDateTimeFormat)
        if (this.splitPart === 'first') {
          data.time_from = this.fromZoneToZone(middleDate, this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat)
          data.time_to = this.fromZoneToZone(this.end.format(this.backendDateTimeFormat), this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat)
        } else {
          data.time_from = this.fromZoneToZone(this.start.format(this.backendDateTimeFormat), this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat)
          data.time_to = this.fromZoneToZone(middleDate, this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat)
        }
        this.$store.dispatch('splitShift', [this.shift.id, data]).then((response) => {
          if (response.length > 0) {
            this.$eventBus.emit('refetchEvents')
            // this.$eventBus.emit('splitShift', response)
            this.toastSuccess(this.$i18n?.t('Shift successfully put on the Free Board'))
          } else {
            this.toastSuccess(this.$i18n?.t('The request was created. After confirmation from the manager, the shift will be placed on the Free Board'))
          }
          document.getElementById('modalCloseButton').click()
          this.closeModal()
        }).catch((err) => {
          this.toastError(this.$i18n?.t(err.response.data.message))
        })
      }
    },
    putOnFreeBoard () {
      const body = document.getElementsByTagName('body')
      body[0].setAttribute('style', 'padding-right: 0px;')
      this.$confirm(this.$i18n?.t('Do you want to put this shift on the Free Board?'), this.$i18n?.t('Warning'), {
        confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
        cancelButtonText: this.$i18n?.t('No, cancel it!!'),
        type: 'warning',
        center: true
      }).then(() => {
        this.putOnFreeBoardAction()
      }).catch(() => {})
    },
    putOnFreeBoardAction () {
      const formData = new FormData()
      formData.append('subtype', 'move_to_freeboard')
      formData.append('shift_id', this.shift.id)

      this.$store.dispatch('setRequest', [this.$store.getters.companyId, formData]).then((response) => {
        if (response.status === 'new') {
          this.toastSuccess(this.$i18n?.t('The request was created. After confirmation from the manager, the shift will be placed on the Free Board'))
        } else if (response.status === 'confirmed') {
          if (response.shift && response.shift.employee_id === 0) {
            this.$eventBus.emit('assignShiftToFreeBoard', response.shift)
          }
          this.toastSuccess(this.$i18n?.t('Shift successfully put on the Free Board'))
        }
        document.getElementById('modalCloseButton').click()
        this.closeModal()
      }).catch((err) => {
        if (err.response.data.message === 'key_attendance_clock_in') {
          this.toastError(this.$i18n?.t('key_attendance_clock_in'))
        } else {
          this.toastError(this.$i18n?.t(err.response.data.message))
        }
        document.getElementById('modalCloseButton').click()
        this.closeModal()
      })
    },
    dropRequest () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          this.$store.dispatch('initiateDrop', [
            this.$store.getters.companyId,
            this.shift.id,
            this.dropEmployeeIds
          ]).then((response) => {
            document.getElementById('ClickEventModal').click()
            this.closeModal()
            this.message = ''
            this.toastSuccess(this.$i18n?.t('Requests was send'))
          }).catch((err) => {
            if (err.response.data.message === 'key_attendance_clock_in') {
              this.toastError(this.$i18n?.t('key_attendance_clock_in'))
            } else {
              this.toastError(this.$i18n?.t(err.response.data.message))
            }
          })
        }
      })
    },
    changeShiftRequest () {
      this.$store.dispatch('initiateShiftChange', [
        this.$store.getters.companyId,
        this.shift.id,
        this.fromZoneToZone(moment(this.startDateTime).format(this.backendDateTimeFormat), this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat),
        this.fromZoneToZone(moment(this.endDateTime).format(this.backendDateTimeFormat), this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat),
        this.message
      ]).then((response) => {
        document.getElementById('ClickEventModal').click()
        this.closeModal()
        this.message = ''
        this.toastSuccess(this.$i18n?.t('Requests was send'))
      }).catch((err) => {
        if (err.response.data.message === 'key_attendance_clock_in') {
          this.toastError(this.$i18n?.t('key_attendance_clock_in'))
        } else {
          this.toastError(this.$i18n?.t(err.response.data.message))
        }
      })
    },
    shiftEditEmployee () {
      const params = {
        marks: this.shiftMarks,
        location_id: this.shiftInfoState.location_id
      }
      this.marks = [...this.shiftMarks]
      this.$store.dispatch('patchShiftEmployee', [this.shift.id, params]).then(shift => {
        // this.$eventBus.emit('updateShift', shift)
        this.$eventBus.emit('refetchEventsLazy')
        this.toastSuccess(this.$i18n?.t('Shift have been successfully saved'))
        document.getElementById('modalCloseButton').click()
        this.closeModal()
      }).catch(() => {
        // console.log(123, err.response.data)
      })
    },
    formatStringToTime (t) {
      return this.fromZoneToZone(t, 'UTC', this.$store.getters.actualTZ).format(this.localeTimeFormat)
    },
    formatStringToDate (t) {
      return this.fromZoneToZone(t, 'UTC', this.$store.getters.actualTZ).format(this.localeDateMonthFormat)
    }
  }
}
</script>

<style scoped>
  .table-shifts-to-swap {
    border-collapse:separate;
    border-spacing: 0 1em;
  }
  .exchange-day-block {
    color: #fff;
    font-weight: 700;
    border-radius: 10px;
    text-align: center;
  }
  tr { background-color: #fff; cursor: pointer;}
</style>
