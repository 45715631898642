<template>
  <Field
    :name="name"
    v-model="innerValue"
    v-slot="{ value, field, errorMessage, handleChange }"
    :rules="validation"
    as="div"
  >
    <ui-input-label v-if="label" :label="label" :hint="hint" :required="validation && !!validation.required"/>
    <el-form-item :error="errorMessage" :required="validation && !!validation.required" class="p-0 mt-0 mb-1">
      <el-date-picker
        :model-value="value"
        v-bind="field"
        @update:model-value="handleChange"
        class="w-100"
        size="large"
        :type="pickerType"
        :format="dateFormat"
        :value-format="valueFormat ? valueFormat : null"
        :clearable="!!clearable"
        :disabled="!!disabled"
        :class="{'is-invalid': !!errorMessage}"
        :start-placeholder="$t('Start date')"
        :end-placeholder="$t('End date')"
        :placeholder="placeholder"
        :teleported="teleported"
        :disabledDate="disabledDate"
        :shortcuts="shortcuts"
      />
    </el-form-item>
  </Field>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'date-picker',
  mixins: [momentMixin],
  emits: ['update:modelValue'],

  props: {
    name: String,
    validation: Object,
    value: [String, Object, Date, Array],
    modelValue: [String, Object, Date, Array],
    clearable: Boolean,
    disabled: Boolean,
    label: String,
    hint: String,
    pickerType: {
      type: String,
      default: 'date'
    },
    pickerFormat: String,
    disabledDate: Function,
    valueFormat: String,
    placeholder: String,
    teleported: {
      type: Boolean,
      default: false
    },
    shortcuts: Array
  },
  computed: {
    innerValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    },
    dateFormat () {
      if (this.pickerFormat) {
        return this.pickerFormat
      }
      return this.localeDateFormatElementUi
    }
  },

  watch: {
    // modelValue (val) {
    //   this.innerValue = val
    // }
  }

}
</script>

<style lang="scss">
.el-picker-panel [slot=sidebar], .el-picker-panel__sidebar {
  width: 180px!important;
}
.el-picker-panel [slot=sidebar]+.el-picker-panel__body, .el-picker-panel__sidebar+.el-picker-panel__body {
  margin-left: 180px!important;
}
</style>
