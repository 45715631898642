<template>
  <li class="nav-item dropdown border-top no-md-border my-auto">
    <div class="head-link nav-link pointer-cursor d-flex align-items-center px-0" data-toggle="dropdown"
         aria-haspopup="true" aria-expanded="false" id="companySettingsDropdown">
                  <span class="p-0 d-flex align-items-center">
                      <avatar
                        ref="logo"
                        class="rounded-circle d-inline-block m-auto pl-1"
                        :width="30"
                        :borderRadius="50"
                        :placeholder="true"
                        :placeholderUrl="'../../../static/images/App/upload.png'"
                        :companyIdForAvatar="companyId"
                        :mini="true"
                        :cache="headerCache">
                      </avatar>
                        <span class="menu-color company-name"
                              :class="{ mobileItemStyle: isMobile, 'ml-1': !isMobile }">{{ company.name }}</span>
                  </span>
    </div>
    <div
      class="dropdown-menu dropdown-menu-right"
      aria-labelledby="companySettingsDropdown"
    >
      <router-link id="demo_dashboard_dropdown_item"
                   v-if="isEmployeeHasPermission('get-dashboard-project-users')"
                   class="dropdown-item"
                   :to="`/c/${companyId}/dashboard`">
        <i class="icon-grid" />
        <span class="menu-color ml-2">{{ $t('Dashboard') }}</span>
      </router-link>
      <template v-if="workMode === 'work'">
        <router-link
          id="demo_user_salary"
          v-if="isEmployeeHasPermission('get-shifts') &&
          showSalary &&
          !isEmployeeHasPermission('create-position')"
          class="dropdown-item"
          :to="`/c/${companyId}/salary`"
        >
          <i class="icon-users"></i>
          <span class="menu-color ml-2">{{ $t('USER_SALARY') }}</span>
        </router-link>
      </template>
      <router-link
        id="demo_company_settings"
        v-if="isEmployeeHasPermission('delete-company')"
        class="dropdown-item menu-color"
        :to="currentCompany"
      >
        <el-badge is-dot class="item" :hidden="!!isWizardPassed">
          <i class="icon-settings"></i>
        </el-badge>
        <span class="menu-color ml-2">{{ $t('Company settings') }}</span>
      </router-link>
      <router-link
        id="company_modules"
        v-if="isEmployeeHasPermission('get-company-admin')"
        class="dropdown-item menu-color"
        :to="`/c/${companyId}/modules`"
      >
        <el-badge is-dot class="item" :hidden="!!isWizardPassed">
          <i class="icon-layers"></i>
        </el-badge>
        <span class="menu-color ml-2">{{ $t('MODULES') }}</span>
      </router-link>
      <template v-if="workMode === 'work'">
        <router-link
          id="demo_availability"
          class="dropdown-item"
          :to="{ name: 'ProfileAvailability', params: { id: companyId }}"
        >
          <i class="icon-check-circle"></i>
          <span class="menu-color ml-2">{{ $t('When I am available') }}</span>
        </router-link>
      </template>
      <template v-if="workMode === 'work'">
        <router-link
          id="demo_my_files"
          class="dropdown-item"
          to="/files"
        >
          <i class="icon-copy" />
          <span class="menu-color ml-2">{{ $t('My files') }}</span>
        </router-link>
      </template>
      <template v-if="workMode === 'work'">
        <router-link
          id="demo_company_files"
          v-if="isEmployeeHasPermission('get-company-files-admin')"
          class="dropdown-item"
          :to="`/c/${companyId}/files`"
        >
          <i class="icon-file-text"></i>
          <span class="menu-color ml-2">{{ $t('_HEADER_ALL_COMPANY_FILES_LINK') }}</span>
        </router-link>
      </template>
      <router-link
        id="demo_reports"
        v-if="isEmployeeHasOneOrMorePermissions([
              'get-budget-report',
              'get-budget-report-admin',
              'get-minimum-hours-per-week-report',
              'get-minimum-hours-per-week-report',
              'get-minimum-hours-per-week-report-admin',
              'get-pay-report',
              'get-pay-report-admin',
              'get-project-employees-report',
              'get-timeoff-report',
              'get-timeoff-report-admin',
              'get-working-hours-report',
              'get-working-hours-report-admin',
              'get-tasks-report',
              'get-tasks-report-admin',
              'get-time-sheet-export'
            ])"
        class="dropdown-item"
        :to="`/c/${companyId}/statistic`"
      >
        <i class="icon-bar-chart"></i>
        <span class="menu-color ml-2">{{ $t('Reports') }}</span>
      </router-link>
      <template v-if="workMode === 'work'">
        <router-link id="demo_positions"
                     v-if="isEmployeeHasPermission('create-position')"
                     class="dropdown-item"
                     :to="`/c/${companyId}/positions`">
          <i class="icon-price-tags text-secondary" style="font-size: 0.9em"></i>
          <span class="menu-color ml-2">{{ $t('POSITIONS_MENU_ITEM') }}</span>
        </router-link>
      </template>
      <router-link id="demo_skills"
                   v-if="isEmployeeHasPermission('create-skill')"
                   class="dropdown-item"
                   :to="`/c/${companyId}/skills`">
        <i class="icon-library text-secondary" style="font-size: 0.9em"></i>
        <span class="menu-color ml-2">{{ $t('SKILLS_MENU_ITEM') }}</span>
      </router-link>

      <template v-if="workMode === 'work'">
        <router-link id="demo_locations"
                     v-if="isEmployeeHasPermission('create-location')"
                     class="dropdown-item"
                     :to="`/c/${companyId}/locations`">
          <i class="icon-map-pin text-secondary" style="font-size: 0.9em"></i>
          <span class="menu-color ml-2">{{ $t('LOCATIONS_MENU_ITEM') }}</span>
        </router-link>
      </template>

      <router-link id="demo_referrals"
                   v-if="isEmployeeHasPermission('get-referrals')"
                   class="dropdown-item"
                   :to="`/c/${companyId}/referrals`">
        <i class="icon-handshake-o text-secondary" style="font-size: 0.9em"></i>
        <span class="menu-color ml-2">{{ $t('Referral system') }}</span>
      </router-link>

      <router-link id="company_logs"
                   v-if="isEmployeeHasPermission('delete-company') || employeeProfile.is_log_allowed"
                   class="dropdown-item"
                   :to="`/c/${companyId}/logs`">
        <i class="icon-align-justify text-secondary" style="font-size: 0.9em"></i>
        <span class="menu-color ml-2">{{ $t('COMPANY_LOGS') }}</span>
      </router-link>

      <router-link id="marketplace"
                   v-if="isEmployeeHasPermission('get-company-apps')"
                   class="dropdown-item"
                   :to="`/c/${companyId}/app-marketplace`">
        <i class="icon-grid text-secondary" style="font-size: 0.9em"></i>
        <span class="menu-color ml-2">{{ $t('Apps Marketplace') }}</span>
      </router-link>

      <template v-if="workMode === 'work'">
        <router-link id="developer"
                     v-if="isEmployeeHasPermission('get-company-apps')"
                     class="dropdown-item"
                     :to="`/c/${companyId}/developer`">
          <i class="icon-grid text-secondary" style="font-size: 0.9em"></i>
          <span class="menu-color ml-2">{{ $t('FOR_DEVELOPERS') }}</span>
        </router-link>
      </template>

      <div v-if="isEmployeeHasPermission('delete-company')" class="dropdown-divider"></div>
      <router-link id="demo_payments"
                   v-if="isEmployeeHasPermission('delete-company')"
                   class="dropdown-item"
                   :to="`/c/${companyId}#balance`">
        <i class="icon-credit-card"></i>
        <span class="menu-color ml-2">{{ $t('Company balance') }}</span>
      </router-link>

      <router-link id="demo_change_work_mode"
                   @click="changeWorkMode"
                   class="dropdown-item"
                   :to="`/c/${companyId}/dashboard`">
        <i class="icon-repeat" />
        <span class="menu-color ml-2">{{ $t('Change work mode') }}</span>
      </router-link>
      <div v-if="hasMoreThanOneCompany" class="dropdown-divider"></div>

      <a id="demo_change_company" v-if="hasMoreThanOneCompany" class="dropdown-item" @click="openModal"
         data-toggle="modal"
         data-target="#ChangeCompany">
        <i class="icon-repeat"></i>
        <span class="menu-color ml-2">{{ $t('Change company') }}</span>
      </a>
    </div>
  </li>
</template>
<script>
import Avatar from '@/components/CommonComponents/Avatar'

export default {
  name: 'HeaderCompanyComponent',
  components: { Avatar },
  data () {
    return {
      headerCache: true
    }
  },
  props: {
    isMobile: Boolean,
    employeeProfile: Object
  },
  created () {
    this.$eventBus.on('updateLogo', () => {
      this.headerCache = false
      this.$refs.logo.getAvatar()
    })
  },
  computed: {
    avatarHash () {
      return this.$store.getters.avatarHashId
    },
    companyId () {
      return this.$store.getters.companyId
    },
    company () {
      return this.$store.getters.company
    },
    showSalary () {
      if (this.company) {
        return this.company.salary &&
          this.company.salary.show_salary_to_users &&
          !this.company.is_blocked
      }
      return false
    },
    currentCompany () {
      return '/c/' + this.$store.getters.companyId + '#general'
    },
    isWizardPassed () {
      return true
    }
  },
  watch: {
    avatarHash: function () {
      // отслеживаем загрузку нового аватара пользователем и инициализируем обновление аватарки в хедере
      this.headerCache = false
      setTimeout(() => {
        this.headerCache = true
      }, 2000)
    }
  },
  methods: {
    openModal: function () {
      this.$store.state.modal = true
      this.$store.dispatch('getCompanies')
    },
    changeWorkMode () {
      const workMode = localStorage.getItem('work_mode')
      // console.log(1, workMode)
      if (workMode) {
        if (workMode === 'work') {
          localStorage.setItem('work_mode', 'field_service')
          this.$store.dispatch('updateWorkMode', 'field_service')
          // setTimeout(() => {
          //   this.$store.dispatch('updateWorkMode', 'field_service')
          // }, 500)
          // console.log(2)
        } else {
          localStorage.setItem('work_mode', 'work')
          this.$store.dispatch('updateWorkMode', 'work')
          // setTimeout(() => {
          //   this.$store.dispatch('updateWorkMode', 'work')
          // }, 500)
          // console.log(3)
        }
      } else {
        if (this.$store.getters.workMode === 'work') {
          localStorage.setItem('work_mode', 'field_service')
          this.$store.dispatch('updateWorkMode', 'field_service')
          // setTimeout(() => {
          //   this.$store.dispatch('updateWorkMode', 'field_service')
          // }, 500)
        } else {
          localStorage.setItem('work_mode', 'work')
          this.$store.dispatch('updateWorkMode', 'work')
          // setTimeout(() => {
          //   this.$store.dispatch('updateWorkMode', 'work')
          // }, 500)
        }
      }
      this.$eventBus.emit('updateAlertCount')
      this.$eventBus.emit('updateAlerts')
      this.$router.push(`/c/${this.companyId}/dashboard`)
    }
  }
}
</script>
<style scoped>

.menu-color {
  color: #4a4a4a !important;
}

.mobileItemStyle {
  padding-left: 1.25rem;
}

.company-name {
  font-family: "Open Sans";
  display: inline-block;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
</style>
