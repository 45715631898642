import { HTTP } from '@/api/http_utils'

const UPDATE_VACATIONS_SETTINGS = 'UPDATE_VACATIONS_SETTINGS'
const UPDATE_ONE_SETTING = 'UPDATE_ONE_SETTING'
const DELETE_ONE_SETTING = 'DELETE_ONE_SETTING'
const UPDATE_VACATIONS_TRANSACTIONS = 'UPDATE_VACATIONS_TRANSACTIONS'
const UPDATE_ONE_TRANSACTION = 'UPDATE_ONE_TRANSACTION'
const DELETE_ONE_TRANSACTION = 'DELETE_ONE_TRANSACTION'
const UPDATE_VACATIONS_MONTH_STATISTIC = 'UPDATE_VACATIONS_MONTH_STATISTIC'

const state = {
  vacationsSettings: {},
  vacationsTransactions: {},
  vacationsMonthStatistic: {}
}

const promises = {}

const getters = {
  vacationsSettings: state => {
    const val = Object.values(state.vacationsSettings)
    val.sort((a, b) => {
      if (a.date_from > b.date_from) {
        return 1
      }
      if (a.date_from < b.date_from) {
        return -1
      }
      return 0
    })
    return val
  },
  vacationsTransactions: state => {
    const val = Object.values(state.vacationsTransactions)
    val.sort((a, b) => {
      if (a.date > b.date) {
        return 1
      }
      if (a.date < b.date) {
        return -1
      }
      return 0
    })
    return val
  },
  vacationsMonthStatistic: state => state.vacationsMonthStatistic
}

const mutations = {
  [UPDATE_VACATIONS_SETTINGS] (state, settings) {
    const data = {}
    settings.forEach(setting => {
      data[setting.id] = setting
    })
    state.vacationsSettings = data
  },
  [UPDATE_ONE_SETTING] (state, setting) {
    state.vacationsSettings[setting.id] = setting
  },
  [DELETE_ONE_SETTING] (state, settingId) {
    delete state.vacationsSettings[settingId]
  },
  [UPDATE_VACATIONS_TRANSACTIONS] (state, payload) {
    const data = {}
    payload.forEach(setting => {
      data[setting.id] = setting
    })
    state.vacationsTransactions = data
  },
  [UPDATE_ONE_TRANSACTION] (state, payload) {
    state.vacationsTransactions[payload.id] = payload
  },
  [DELETE_ONE_TRANSACTION] (state, transactionId) {
    delete state.vacationsTransactions[transactionId]
  },
  [UPDATE_VACATIONS_MONTH_STATISTIC] (state, payload) {
    if (payload.employee_id) {
      state.vacationsMonthStatistic[payload.employee_id] = payload
    }
  }
}

const actions = {
  getVacationsSettings ({ commit }, [companyId, employeeId]) {
    const url = `/companies/${companyId}/employees/${employeeId}/vacations-settings`

    if (promises[url]) {
      console.error('repeating requests vacations')
      return promises[url]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.get(url).then(response => {
        commit(UPDATE_VACATIONS_SETTINGS, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[url]
      })
    })

    promises[url] = promise

    return promise
  },
  createSetting ({ commit }, [companyId, employeeId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/employees/${employeeId}/vacations-settings`, payload).then(response => {
        commit(UPDATE_ONE_SETTING, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateSetting ({ commit }, [companyId, employeeId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/employees/${employeeId}/vacations-settings/${payload.id}`, payload).then(response => {
        commit(UPDATE_ONE_SETTING, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteSetting ({ commit }, [companyId, employeeId, settingId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/employees/${employeeId}/vacations-settings/${settingId}`).then(response => {
        commit(DELETE_ONE_SETTING, settingId)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getVacationsTransactions ({ commit }, [companyId, employeeId]) {
    const url = `/companies/${companyId}/employees/${employeeId}/vacations-transactions`

    if (promises[url]) {
      console.error('repeating requests vacations')
      return promises[url]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.get(url).then(response => {
        commit(UPDATE_VACATIONS_TRANSACTIONS, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[url]
      })
    })

    promises[url] = promise

    return promise
  },
  createVacationsTransactions ({ commit }, [companyId, employeeId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/employees/${employeeId}/vacations-transactions`, payload).then(response => {
        commit(UPDATE_ONE_TRANSACTION, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateVacationsTransactions ({ commit }, [companyId, employeeId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/employees/${employeeId}/vacations-transactions/${payload.id}`, payload).then(response => {
        commit(UPDATE_ONE_TRANSACTION, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteVacationsTransactions ({ commit }, [companyId, employeeId, settingId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/employees/${employeeId}/vacations-transactions/${settingId}`).then(response => {
        commit(DELETE_ONE_TRANSACTION, settingId)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getVacationsMonthReport ({ commit }, [companyId, date, employeeId]) {
    const url = `/companies/${companyId}/vacations-monthly-statistic?date=${date}&employee_id=${employeeId}`

    if (promises[url]) {
      console.error('repeating requests vacations')
      return promises[url]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.get(url).then(response => {
        commit(UPDATE_VACATIONS_MONTH_STATISTIC, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[url]
      })
    })

    promises[url] = promise

    return promise
  },
  getVacationsMonthlyStatistic ({ commit }, [companyId, date, exportType = null, selectedPositions = null]) {
    let url = `/companies/${companyId}/stats/vacations_monthly?date=${date}`
    if (exportType) {
      url += `&export_type=${exportType}`
    }
    if (selectedPositions && selectedPositions.length > 0) {
      url += `&positions[]=${selectedPositions.join('&positions[]=')}`
    }

    if (promises[url]) {
      console.error('repeating requests vacations')
      return promises[url]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.get(url, { responseType: exportType ? 'blob' : 'json' }).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[url]
      })
    })

    promises[url] = promise

    return promise
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
