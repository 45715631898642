<template>
  <Form ref="observe">
    <div v-if="$store.getters.company">
      <div v-if="isModuleActive && shift">
        <div v-if="shift.breaks && shift.breaks.length > 0" class="font-weight-bold text-primary">
          {{$t('EXISTING_BREAKS')}}
        </div>
        <div v-for="breakItem in shift.breaks" :key="breakItem.id" class="border border-info m-1 p-2 row">
          <template v-if="breakIdToEdit !== breakItem.id">
            <div class="col align-items-center">
              {{formatDate(breakItem.time_from)}} -
              {{formatDate(breakItem.time_to)}}
              <span class="font-weight-bold text-primary mx-1">{{breakItem.title}}</span>
              <el-tooltip v-if="breakItem.billable" :key="breakItem.id" class="item" effect="dark" :content="$t('Billable')" placement="top">
                <el-icon :size="18" color="#409efc" class="align-middle">
                  <Money />
                </el-icon>
                <i class="el-icon-money text-primary my-1 mx-1" />
              </el-tooltip>
            </div>
            <div v-if="!isRoleUser && showButtons" class="col-auto">
              <i class="icon-pencil btn-icon text-primary my-1 mx-1" @click="editBreak(breakItem)" />
              <i class="icon-trash btn-icon text-danger mx-1" @click="deleteBreak(breakItem.id)" />
            </div>
          </template>
          <template v-else>
            <div class="col">
              <break-form-component
                :breakItem="breakToEdit"
                :shift="shift"
              />
<!--              <span v-show="errors.has('___breaks')" class="small text-danger">-->
<!--              {{ $t(errors.first('___breaks')) }}-->
<!--            </span>-->
            </div>
            <div class="col-auto">
              <i class="icon-check btn-icon text-success font-weight-bold my-1 mx-1" @click="saveEditedBreak(breakToEdit)" />
              <i class="icon-x btn-icon text-warning font-weight-bold mx-1" @click="closeEdit()" />
            </div>
          </template>
        </div>
        <template v-if="shift">
          <div v-for="(breakItem, itemKey) in breaks" :key="itemKey">
            <el-divider />
            <break-form-component
              :breakItem="breakItem"
              :shift="shift"
              :isRoleUser="isRoleUser"
              :itemKey="itemKey"
              @onDelete="deleteItem()"
            />
          </div>
          <template v-if="showButtons">
            <button
              v-if="!isRoleUser || schedule.allow_break_request"
              type="button"
              class="btn btn-primary btn-sm mt-2"
              @click="addBreak">
              {{ $t('Add break') }}
            </button>
            <div v-if="breaks.length > 0" class="my-2 float-right">
              <button class="btn btn-sm btn-success" type="button" @click="validateAndSave">
                {{ $t('SAVE_WEB') }}
              </button>
            </div>
          </template>
        </template>
      </div>
      <div v-if="!isModuleActive">
        <dummy-payment-required
          @activate="activate"
          :dummyInfo="dummyInfo"
          :compactMode="true"
        >
        </dummy-payment-required>
      </div>
    </div>
  </Form>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import moment from 'moment'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import { mapGetters } from 'vuex'
import mixinDummy from '@/mixins/mixinDummy'
import ToastMixin from '@/mixins/ToastMixin'
import BreakFormComponent from '@/components/Schedule/CompanyScheduleComponents/Components/BreakFormComponent'
// import moment from 'moment'
export default {
  name: 'AddBreaksComponent',
  mixins: [momentMixin, mixinDummy, ToastMixin],
  components: { BreakFormComponent, DummyPaymentRequired },
  data () {
    return {
      breaks: [],
      shift: null,
      showDate: true,
      isModuleActive: true,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      disabled: false,
      breakIdToEdit: null,
      breakToEdit: {}
    }
  },
  props: {
    shiftInfo: Object
  },
  created () {
    this.init()
  },
  computed: {
    ...mapGetters({
      locale: 'locale',
      currentProjectId: 'currentProjectId'
    }),
    rolesByProjects () {
      return this.$store.getters.rolesByProjects || {}
    },
    isRoleUser () {
      return !this.isEmployeeHasPermission('get-breaks-by-company')
    },
    event () {
      return this.$store.getters.fc_events.filter(e => e.id === this.shiftInfo.id)[0]
    },
    schedule () {
      return this.$store.getters.scheduleById(this.shiftInfo.schedule_id)
    },
    showButtons () {
      if (this.isEmployeeHasPermission('update-schedule-admin')) {
        return true
      }
      const project = this.$store.getters.projectById(this.currentProjectId)

      return !(project.block_change_in_past_for_manager === true && moment(this.shift.time_from).isBefore(moment()))
    }
  },
  methods: {
    init () {
      this.$store.dispatch('isModuleActive', [this.$store.getters.companyId, 24]).then(response => {
        // console.log(45, this.event)
        this.isModuleActive = true
        this.prepareBreaks(this.shiftInfo)
      }).catch(error => {
        // console.log('err', error.response.data.module)
        this.isModuleActive = false
        this.dummyInfo = this.setupDummyData(error.response.data)
      })
    },
    prepareBreaks (shiftInfo) {
      this.shift = JSON.parse(JSON.stringify(shiftInfo))
      if (this.shift.breaks.length > 0) {
        this.shift.breaks.forEach(breakItem => {
          breakItem.time_from = this.fromZoneToZone(breakItem.time_from, 'UTC', this.$store.getters.actualTZ).format(this.backendDateTimeFormat)
          breakItem.time_to = this.fromZoneToZone(breakItem.time_to, 'UTC', this.$store.getters.actualTZ).format(this.backendDateTimeFormat)
        })
        this.shift.breaks.sort((a, b) => {
          if (moment(a.time_from).isBefore(moment(b.time_from))) {
            return -1
          }
          return 1
        })
      }
      this.shift.time_from = this.fromZoneToZone(shiftInfo.time_from, 'UTC', this.$store.getters.actualTZ).format(this.backendDateTimeFormat)
      this.shift.time_to = this.fromZoneToZone(shiftInfo.time_to, 'UTC', this.$store.getters.actualTZ).format(this.backendDateTimeFormat)
    },
    async activate () {
      this.init()
      await this.$store.dispatch('getCompany', this.$store.getters.companyId)
      this.$eventBus.emit('reloadDummy', 'breaks')
    },
    deleteItem (itemKey) {
      this.breaks.splice(+itemKey, 1)
    },
    addBreak () {
      this.breakIdToEdit = null
      this.breakToEdit = {}
      this.breaks.push({
        id: null,
        template_id: 0,
        title: this.$t('BREAK_TITLE_DEFAULT'),
        time_from: this.shift.time_from,
        time_to: this.shift.time_to,
        billable: false,
        comment: null
      })
    },
    deleteBreak (id) {
      this.$confirm('', this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
        cancelButtonText: this.$i18n?.t('No, cancel it!!'),
        type: 'warning',
        center: true
      }).then((result) => {
        this.disabled = true
        this.$store.dispatch('deleteBreak', [this.shiftInfo.project_id, id]).then(response => {
          this.$store.dispatch('getShift', this.shiftInfo.id).then((shift) => {
            this.$eventBus.emit('refetchEvents')
            this.disabled = false
            this.toastSuccess(this.$i18n?.t('BREAK_DELETE_SUCCESS'))
            this.prepareBreaks(shift)
            this.breaks = []
          }).catch(() => {
            this.disabled = false
          })
        }).catch(() => {
          this.toastError(this.$i18n?.t('SHIFT_RANGE_CONFLICT'))
        })
      }).catch(() => {})
    },
    editBreak (item) {
      this.breaks = []
      this.breakToEdit = Object.assign({}, item)
      this.breakIdToEdit = item.id
    },
    validateAndSave () {
      this.$refs.observe.validate().then(result => {
        let errors = 0
        const templateStart = this.fromZoneToZone(this.shiftInfo.time_from, 'UTC', this.$store.getters.actualTZ)
        const templateEnd = this.fromZoneToZone(this.shiftInfo.time_to, 'UTC', this.$store.getters.actualTZ)
        let isTwoDays = false
        if (templateStart.isSameOrAfter(templateEnd)) {
          templateEnd.add(1, 'day')
          isTwoDays = true // переходящая смена
        }
        // console.log(templateStart.format(this.backendDateTimeFormat), templateEnd.format(this.backendDateTimeFormat))
        this.breaks.forEach((breakItem, index) => {
          if (!breakItem.time_from || !breakItem.time_to) {
            errors++
          } else { // проверяем чтобы брейки не вылазили за пределы темлпейта
            const breakStart = moment(breakItem.time_from, this.backendDateTimeFormat)
            const breakEnd = moment(breakItem.time_to, this.backendDateTimeFormat)
            if (isTwoDays) { // если смена переходящая, и брейк приходится на вторую половину смены, добавляем день к началу брейка
              const clonedStart = templateEnd.clone().startOf('day')
              const clonedBreakStart = breakStart.clone().add(1, 'day')
              if (clonedBreakStart.isSameOrAfter(clonedStart) && clonedBreakStart.isSameOrBefore(templateEnd)) {
                breakStart.add(1, 'day')
              }
            }
            if (breakStart.isSameOrAfter(breakEnd)) { // если брейк переходящий, добавляем день к окончанию брейка
              breakEnd.add(1, 'day')
            }
            if (breakStart.isBefore(templateStart)) {
              errors++
              this.toastError(this.$t('WRONG_START_TIME'))
            }
            if (breakEnd.isAfter(templateEnd)) {
              errors++
              this.toastError(this.$t('WRONG_END_TIME'))
            }
          }
        })
        if (result.valid && errors === 0) {
          this.save()
        }
      })
    },
    save () {
      this.disabled = true
      const payload = {
        breaks: []
      }
      this.breaks.forEach(item => {
        payload.breaks.push({
          employee_id: this.shiftInfo.employee_id,
          schedule_id: this.shiftInfo.schedule_id,
          template_id: this.shiftInfo.template_id,
          shift_id: this.shiftInfo.id,
          title: item.title,
          time_from: this.fromZoneToZone(item.time_from, this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat),
          time_to: this.fromZoneToZone(item.time_to, this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat),
          billable: item.billable,
          comment: item.comment
        })
      })
      // console.log(payload)
      if (this.isRoleUser) {
        this.$store.dispatch('breaksCreateRequest', [
          this.$store.getters.company.id,
          this.shiftInfo.id,
          payload
        ]).then(() => {
          this.toastSuccess(this.$i18n?.t('BREAK_CREATE_REQUEST_CREATED'))
          this.handlePostUpdate()
        }).catch(e => {
          if (e.response.data.errors) {
            Object.keys(e.response.data.errors).forEach((key, index) => {
              if (key !== 'total' && e.response.data.errors[key] > 0) {
                setTimeout(() => {
                  this.toastError(this.$i18n?.t(key) + ': ' + e.response.data.errors[key])
                }, 1000 * index)
              }
            })
          } else {
            this.toastError(this.$i18n?.t(e.response.data.message))
          }
          this.disabled = false
        })
      } else {
        this.$store.dispatch('createBreaks', [this.shiftInfo.id, payload]).then(() => {
          this.handlePostUpdate()
        }).catch(e => {
          this.toastError(e.response.data.message)
          this.disabled = false
        })
      }
    },
    handlePostUpdate () {
      this.$store.dispatch('getShift', this.shiftInfo.id).then((shift) => {
        this.$eventBus.emit('refetchEvents')
        this.disabled = false
        this.prepareBreaks(shift)
        this.breaks = []
      }).catch(() => {
        this.disabled = false
      })
    },
    closeEdit () {
      this.breakIdToEdit = null
      this.breakToEdit = {}
    },
    saveEditedBreak (item) {
      let errors = 0
      const templateStart = moment(this.shift.time_from, this.backendDateTimeFormat)
      const templateEnd = moment(this.shift.time_to, this.backendDateTimeFormat)
      let isTwoDays = false
      if (templateStart.isSameOrAfter(templateEnd)) {
        templateEnd.add(1, 'day')
        isTwoDays = true // переходящая смена
      }
      if (this.breakToEdit.title.length < 1 || !this.breakToEdit.time_from || !this.breakToEdit.time_to) {
        errors++
      } else { // проверяем чтобы брейки не вылазили за пределы темлпейта
        const breakStart = moment(this.breakToEdit.time_from, this.backendDateTimeFormat)
        const breakEnd = moment(this.breakToEdit.time_to, this.backendDateTimeFormat)
        if (isTwoDays) { // если смена переходящая, и брейк приходится на вторую половину смены, добавляем день к началу брейка
          const clonedStart = templateEnd.clone().startOf('day')
          const clonedBreakStart = breakStart.clone().add(1, 'day')
          if (clonedBreakStart.isSameOrAfter(clonedStart) && clonedBreakStart.isSameOrBefore(templateEnd)) {
            breakStart.add(1, 'day')
          }
        }
        if (breakStart.isSameOrAfter(breakEnd)) { // если брейк переходящий, добавляем день к окончанию брейка
          breakEnd.add(1, 'day')
        }
        if (breakStart.isBefore(templateStart)) {
          errors++
          this.$validator.errors.add({
            field: '___breaks',
            msg: 'WRONG_START_TIME'
          })
        }
        if (breakEnd.isAfter(templateEnd)) {
          errors++
          this.$validator.errors.add({
            field: '___breaks',
            msg: 'WRONG_END_TIME'
          })
        }
      }
      if (errors === 0) {
        const breakItem = {
          title: this.breakToEdit.title,
          time_from: this.fromZoneToZone(this.breakToEdit.time_from, this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat),
          time_to: this.fromZoneToZone(this.breakToEdit.time_to, this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat),
          billable: this.breakToEdit.billable
        }
        this.disabled = true
        this.$store.dispatch('updateBreaks', [this.shiftInfo.project_id, this.breakToEdit.id, breakItem]).then(response => {
          this.closeEdit()
          this.$store.dispatch('getShift', this.shiftInfo.id).then((shift) => {
            this.$eventBus.emit('refetchEvents')
            this.disabled = false
            this.toastSuccess(this.$i18n?.t('BREAK_UPDATE_SUCCESS'))
            this.prepareBreaks(shift)
            this.breaks = []
          }).catch(() => {
            this.disabled = false
          })
          this.$eventBus.emit('refetchEventsLazy')
        }).catch((err) => {
          this.disabled = false
          if (err.response.data.message === 'key_attendance_clock_in') {
            this.toastError(this.$i18n?.t('key_attendance_clock_in'))
          } else {
            this.toastError(this.$i18n?.t('SHIFT_RANGE_CONFLICT'))
          }
        })
      }
    },
    formatDate (val) {
      return moment(val).format(this.localeTimeFormat)
    }
  }
}
</script>

<style scoped>

</style>
