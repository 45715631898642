// компонент для выбора адреса
// на onchange передает в родительский компонент объект содержащий текущее состояние адреса - страну, город, штат, зипкод, адрес
// при подключении компонента, в нем нужно определить метод, который будет срабатывать на событие 'addressChange' (@addressChange="[название метода]")
<template>
  <Form v-if="loaded" ref="observer" as="div" class="form-row">
    <div :class="classes" class="form-group">
      <ui-select
        v-model="addressState.countryId"
        name="country"
        :validation="{required: requiredFields.country}"
        :clearable="false"
        :filterable="true"
        :placeholder="$t('Select')"
        :label="$t('Country / Region')"
        :options="countryNames"
        :key-name="'code'"
        :label-name="'name'"
        :value-name="'code'"
      />
    </div>

    <div v-show="subdivisionNames" :class="classes" class="form-group">
      <ui-select
        v-model="addressState.stateId"
        name="stateId"
        :validation="{required: requiredFields.state}"
        :clearable="false"
        :filterable="true"
        :placeholder="$t('Select')"
        :label="$t('State / Province / Region')"
        :options="subdivisionNames"
        :key-name="'id'"
        :label-name="'name'"
        :value-name="'id'"
      />
    </div>

    <div v-show="cityNames" :class="classes" class="form-group">
      <ui-select
        v-model="addressState.cityId"
        name="cityId"
        :validation="{required: requiredFields.city}"
        :clearable="false"
        :filterable="true"
        :placeholder="$t('Select')"
        :label="$t('City')"
        :options="cityNames"
        :key-name="'id'"
        :label-name="'name'"
        :value-name="'id'"
      />
    </div>

    <div :class="classes" class="form-group">
      <ui-text-input
        name="zip_code"
        v-model="addressState.zipCode"
        :validation="{required: requiredFields.zipCode, max: 20}"
        :placeholder="$t('Zip code / Postal code')"
        :label="$t('Zip code / Postal code')"
      />
    </div>
    <div :class="classes" class="form-group" v-if="showAddressConfig">
      <ui-text-input
        name="address"
        v-model="addressState.address"
        :validation="{required: requiredFields.address, max: 128}"
        :label="$t('Address')"
      />
    </div>

    <div :class="classes" class="form-group" v-if="showAdditionalAddressConfig">
      <ui-text-input
        name="additionalAddress"
        v-model="addressState.additionalAddress"
        :validation="{required: requiredFields.additionalAddress, max: 128}"
        :label="$t('Additional address')"
      />
    </div>
  </Form>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'

export default {
  name: 'Location',
  mixins: [errorMixin],
  data () {
    return {
      loaded: false,
      addressState: { // начальные данные должны передаваться через props из родительского компонента
        countryId: '', // id страны
        cityId: null, // id города
        stateId: null, // id штата
        zipCode: '', // зипкод
        address: '', // адрес
        additionalAddress: '' // дополнительный адрес
      },
      requiredFields: { // настройка валидации (required) полей, подтягивается через props из объекта requiredFieldsSetting, ключи requiredFieldsSetting должны совпадать с ключами requiredFields
        country: true,
        city: true,
        state: true,
        zipCode: true,
        address: true,
        additionalAddress: false
      },
      showAddressConfig: false,
      showAdditionalAddressConfig: false
    }
  },
  expose: ['isAddressValid'],
  async created () {
    this.addressState = {
      countryId: this.countryId,
      cityId: +this.cityId !== 0 ? this.cityId : null,
      stateId: +this.stateId !== 0 ? this.stateId : null,
      zipCode: this.zipCode,
      address: this.address,
      additionalAddress: this.additionalAddress
    }
    if (this.requiredFieldsSetting instanceof Object) {
      this.getFilteredObject(this.requiredFieldsSetting, this.requiredFields)
    }
    if (this.showAddress) {
      this.showAddressConfig = true
    }
    if (this.showAdditionalAddress) {
      this.showAdditionalAddressConfig = true
    }
    try {
      await this.$store.dispatch('changeCountry')
      await this.$store.dispatch('getCountryNames', this.$store.getters.locale)
      if (this.addressState.countryId) {
        await this.$store.dispatch('getSubdivisionNames', [this.$store.getters.locale, this.addressState.countryId])
        if (this.addressState.stateId) {
          await this.$store.dispatch('getCityNames', [this.$store.getters.locale, this.addressState.countryId, this.addressState.stateId])
        }
      }
      await this.$nextTick()
      this.loaded = true
    } catch (e) {
      this.loaded = true
    }
  },
  props: [
    'countryId',
    'cityId',
    'stateId',
    'zipCode',
    'address',
    'additionalAddress',
    'classes',
    'showAddress',
    'showAdditionalAddress',
    'requiredFieldsSetting'
  ],
  computed: {
    ...mapGetters([
      'countryNames',
      'subdivisionNames',
      'cityNames'
    ])
  },
  watch: {
    'addressState.countryId' () {
      if (this.loaded) {
        this.changeCountry()
      }
    },
    'addressState.stateId' () {
      if (this.loaded) {
        this.changeState()
      }
    },
    'addressState.cityId' () {
      if (this.loaded) {
        this.changeCity()
      }
    },
    'addressState.zipCode' () {
      if (this.loaded) {
        this.changeZipCode()
      }
    },
    addressState: {
      handler: function () {
        if (this.loaded) {
          if (this.$refs.observer) {
            this.$refs.observer.validate()
          }
          let data = {}
          data = this.getFilteredObject(this.addressState, data)
          this.$emit('addressChange', data)
        }
      },
      deep: true
    }
  },
  methods: {
    async isAddressValid () {
      const res = await this.$refs.observer.validate()
      return res.valid
    },
    getFilteredObject (obj, filteredObj) {
      Object.keys(obj).forEach(key => {
        filteredObj[key] = obj[key]
      })
      // console.log(1243, filteredObj)
      return filteredObj
    },
    getStates () {
      if (this.addressState.countryId) {
        this.$store.dispatch('getSubdivisionNames', [this.$store.getters.locale, this.addressState.countryId]).then(() => {
          if (this.subdivisionNames.length === 0 || this.addressState.cityId) {
            this.getCities()
          }
        }).catch(() => {})
      }
    },
    getCities () {
      if (this.addressState.countryId) {
        this.$store.dispatch('getCityNames', [this.$store.getters.locale, this.addressState.countryId, this.addressState.stateId]).then(() => {
        }).catch(() => {})
      }
    },
    changeCountry () {
      this.$store.dispatch('changeCountry')
      // console.log(1)
      if (this.addressState.countryId) {
        this.addressState.stateId = ''
        this.getStates()
        // console.log(2)
        this.addressState.cityId = ''
        this.dropAddress()
        // console.log(3)
      }
    },
    changeState () {
      this.$store.dispatch('changeSubdivision')
      if (this.addressState.stateId) {
        this.getCities()
      }
      this.addressState.cityId = ''
      this.dropAddress()
    },
    changeCity () {
      // this.errors.items = []
      this.dropAddress()
    },
    changeZipCode () {
      // this.errors.clear('zip_code')
    },
    dropAddress () {
      this.addressState.zipCode = ''
      this.addressState.address = ''
      this.addressState.additionalAddress = ''
    }
  }
}
</script>

<style scoped>

</style>
