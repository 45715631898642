<template>
  <ui-input-label v-if="label" :label="label" :hint="hint"/>
  <div class="form-inline">
    <div class="form-group">
      <ui-integer-input
        :id="id + '_hrs'"
        :name="id + '_hrs'"
        v-model="hrs"
        :disabled="!!disabled"
        :min="0"
        :data-cy="dataCyH ? dataCyH : null"
      />
    </div>
    <div class="form-group">
      <span class="mx-1">:</span>
    </div>
    <div class="form-group">
      <ui-integer-input
        :id="id + '_minutes'"
        :name="id + '_minutes'"
        v-model="minutes"
        :min="0"
        :max="59"
        :disabled="!!disabled"
        :data-cy="dataCyM ? dataCyM : null"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'time-input',
  emits: ['update:valueHrs'],
  data () {
    return {
      hrs: 0,
      minutes: 0,
      totalHrs: 0
    }
  },
  props: {
    id: String,
    name: String,
    validation: Object,
    valueHrs: [Number, String],
    disabled: Boolean,
    label: String,
    hint: String,
    dataCyH: String,
    dataCyM: String
  },
  created () {
    this.convertHoursToHoursAndMinutes(+this.valueHrs)
    this.totalHrs = +this.valueHrs
  },
  watch: {
    valueHrs () {
      this.convertHoursToHoursAndMinutes(+this.valueHrs)
      this.totalHrs = +this.valueHrs
    },
    hrs () {
      this.convertHoursAndMinutesToHours()
    },
    minutes () {
      this.convertHoursAndMinutesToHours()
    }
  },
  methods: {
    convertHoursToHoursAndMinutes (hours) {
      this.hrs = Math.floor(hours)
      this.minutes = Math.round((hours - this.hrs) * 60)
    },
    convertHoursAndMinutesToHours () {
      const hrs = this.hrs ? this.hrs : 0
      const minutes = this.minutes ? this.minutes : 0
      this.totalHrs = hrs + Math.round((minutes / 60) * 100) / 100
      this.$emit('update:valueHrs', this.totalHrs)
    }
  }
}
</script>

<style lang="scss">

</style>
