<template>
  <div>
    <WorkingHoursModal
      v-if="showTimeSheetModal"
      :projects="selectedProjects"
      :title="'EXPORT_TIME_SHEET_TITLE'"
      :exportType="exportType"
      :showUsers="showUsers"
      :planned="byPlanned"
      :positions="selectedPositions"
      :marks="selectedMarks"
      @closePromoModal="closeTimeSheetModal"
    />
    <div data-toggle="modal" data-target="#ClickExportTimeSheetModal" id="exportTimeSheetModalId"></div>
    <div class="row mb-2 mx-auto">
      <span class="title-text">{{ $t('Reports') }}</span>
      <div class="ml-auto">
        <ui-text-input
          name="search"
          v-model="search"
          :placeholder="$t('Search')"
          :is-search="true"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-2 p-2">
        <div class="card card-small">
          <div class="card-body">
            <div id="dropdown-vertical" class="dropdown d-flex d-md-none">
              <button class="btn btn-primary dropdown-toggle w-100" type="button" @click="toggleMenu">
                {{ $t(buttonTitle) }}
              </button>
            </div>
            <div class="nav flex-column nav-pills d-md-block" id="tabs-vertical" role="tablist" aria-orientation="vertical" :class="{'d-none': !showTabs}">
              <a v-for="(title, key) in tabsData" :key="key"
                 :href="'#' +  key"
                 class="nav-link pl-3 pr-1 rounded"
                 :class="{active: activeTab === key}"
                 data-toggle="pill" role="tab"
                 :aria-controls="'v-pills-' + key" aria-selected="true"
                 @click="activateTab(key)">
                {{ $t(title) }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-10 p-2">
        <div class="row">
          <div class="col-lg-12 w-100">
            <!--  Common menu  -->
            <div v-if="menu === true" class="card card-small mb-2">
              <div class="card-body">
                <div class="row">
                  <template v-if="activeTab === 'vacationsMonthly'">
                    <div class="col-auto d-flex flex-column form-group">
                      <ui-date-picker
                        name="month"
                        :picker-type="'month'"
                        :picker-format="localeMonthYearFormatExtendedUi"
                        :clearable="false"
                        :label="$t('SELECT_MONTH')"
                        :validation="{required: false}"
                        data-vv-validate-on="change"
                        v-model="month"
                      />
                    </div>
                    <div class="col-auto d-flex flex-column form-group" style="min-width: 240px">
                      <ui-multi-select
                        name="positions"
                        :label="$t('filter_by_positions')"
                        v-model="selectedPositions"
                        :options="positions"
                        :key-name="'id'"
                        :label-name="'name'"
                        :value-name="'id'"
                      />
                    </div>
                    <span class="align-text-top">
                      <i class="icon-download btn-icon text-primary mx-1" type="button" @click="handleExport('default')"></i>
                    </span>
                  </template>
                  <template v-else>
                    <div v-if="activeTab === 'project'" v-show="activeTab === 'project'" class="col-auto d-flex flex-column form-group">
                      <div class="">
                        <ui-select
                          name="selectedProjectFilter"
                          :label="$t('Display users')"
                          v-model="selectProjectByUsers"
                          :clearable="false"
                          :filterable="false"
                          :options="companyProjectsOptions"
                          :key-name="'id'"
                          :label-name="'name'"
                          :value-name="'id'"
                        />
                      </div>
                    </div>
                    <div v-if="!['timeoff', 'bonus', 'tasks', 'project', 'activity'].includes(activeTab)" class="col-auto d-flex flex-column form-group">
                      <div class="mt-2">
                        <label class="control-label mb-1 p-0 font-weight-bold">{{ $t('Choose projects') }}</label>
                        <button type="button" class="btn btn-link btn-sm float-right mb-1 p-0" @click="selectAllProjects()">
                          {{ $t('Select all') }}
                        </button>
                        <ui-multi-select
                          name="admins"
                          v-model="selectedProjects"
                          :clearable="false"
                          :filterable="true"
                          :options="projects"
                          :key-name="'id'"
                          :label-name="'name'"
                          :value-name="'id'"
                          @change="changeProjectsList"
                        />
                        <button type="button" class="btn btn-link btn-sm float-right mt-1 p-0" @click="resetSelectedProjects()">{{ $t('RESET_ALL') }}</button>
                      </div>
                    </div>
                    <div class="col-auto d-flex flex-column form-group" v-if="activeTab === 'project'">
                      <div class="row">
                        <div class="col-12">
                          <div class="mt-2">
                            <label class="control-label mb-1 p-0 font-weight-bold">{{ $t('DATES_FILTER') }}</label>
                            <ui-radio
                              containerClass="m-0 p-0"
                              :name="'ignore'"
                              :set-value="'ignore'"
                              v-model="filterByStatus"
                              :label="$t('IGNORE_DATES')"
                            />
                            <ui-radio
                              containerClass="m-0 p-0"
                              :name="'hired'"
                              :set-value="'hired'"
                              v-model="filterByStatus"
                              :label="$t('FILTER_BY_HIRING_DATE')"
                            />
                            <ui-radio
                              containerClass="m-0 p-0"
                              :name="'dismissed'"
                              :set-value="'dismissed'"
                              v-model="filterByStatus"
                              :label="$t('FILTER_BY_DISMISSING_DATE')"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto d-flex flex-column form-group" v-if="activeTab !== 'minimumHours'">
                      <div class="">
                        <ui-date-picker
                          :label="$t('Select a date range')"
                          :picker-type="'daterange'"
                          name="range"
                          v-model="period"
                          :start-placeholder="$t('Start date')"
                          :end-placeholder="$t('End date')"
                          :validation="{required: true}"
                          :clearable="false"
                        />
                      </div>
                    </div>
                    <div class="mt-0 pt-0" v-if="activeTab === 'minimumHours'">
                      <ui-date-picker
                        :label="$t('Select a week')"
                        :picker-type="'week'"
                        name="week__range"
                        v-model="weekStartDate"
                        :validation="{required: true}"
                        :clearable="false"
                        :placeholder="$t('Pick a week')"
                        :pickerFormat="startDays() + ' - ' + endDays()"
                      />
                    </div>
                    <div v-if="activeTab === 'employee'" class="col-auto d-flex flex-column form-group">
                      <div class="row">
                        <div class="col-12">
                          <div class="mt-1">
                            <label class="control-label mb-1 p-0 font-weight-bold">{{ $t('Show users') }}</label>
                            <ui-radio
                              containerClass="m-0 p-0"
                              :name="'all'"
                              :set-value="'all'"
                              v-model="showUsers"
                              :label="$t('all')"
                            />
                            <ui-radio
                              containerClass="m-0 p-0"
                              :name="'new'"
                              :set-value="'new'"
                              v-model="showUsers"
                              :label="$t('Only new')"
                            />
                            <ui-radio
                              containerClass="m-0 p-0"
                              :name="'deleted'"
                              :set-value="'deleted'"
                              v-model="showUsers"
                              :label="$t('Only deleted')"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="activeTab === 'project'" class="col-auto d-flex flex-column form-group">
                      <div class="row">
                        <div class="col-12">
                          <div class="mt-1">
                            <label class="control-label mb-1 p-0 font-weight-bold">{{ $t('Show users') }}</label>
                            <ui-radio
                              containerClass="m-0 p-0"
                              :name="'p_all'"
                              :set-value="'all'"
                              v-model="showProjectUsers"
                              :label="$t('All')"
                            />
                            <ui-radio
                              containerClass="m-0 p-0"
                              :name="'operating'"
                              :set-value="'operating'"
                              v-model="showProjectUsers"
                              :label="$t('Operating')"
                            />
                            <ui-radio
                              containerClass="m-0 p-0"
                              :name="'dismissed'"
                              :set-value="'dismissed'"
                              v-model="showProjectUsers"
                              :label="$t('Dismissed')"
                            />
                            <ui-radio
                              containerClass="m-0 p-0"
                              :name="'not_invited'"
                              :set-value="'not_invited'"
                              v-model="showProjectUsers"
                              :label="$t('Not invited')"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto d-flex flex-column form-group" v-if="activeTab === 'employee'">
                      <label class="control-label font-weight-bold">{{ $t('Planned') }}</label>
                      <ui-switch
                        name="byPlanned"
                        v-model="byPlanned"
                        @change="val => byPlanned = val"
                      />
                    </div>
                    <div v-if="!['budget', 'tasks', 'project', 'shift_confirmation_report', 'late_employees_report', 'activity'].includes(activeTab)" class="col-auto d-flex flex-column form-group">
                      <div class="">
                        <ui-group-select
                          :label="$t('filter_by_tags')"
                          name="templates"
                          v-model="selectedMarks"
                          :filterable="true"
                          :options="options"
                          :multiple="true"
                          :clearable="true"
                        />
                      </div>
                    </div>
                    <div v-if="!['budget', 'tasks', 'shift_confirmation_report', 'late_employees_report', 'activity'].includes(activeTab)" class="col-auto d-flex flex-column form-group" style="min-width: 240px">
                      <div class="">
                        <ui-multi-select
                          name="selectedPositions"
                          :label="$t('filter_by_positions')"
                          :placeholder="$t('Select')"
                          :filterable="true"
                          v-model="selectedPositions"
                          :options="positions"
                          :value-name="'id'"
                          :label-name="'name'"
                          :clearable="true"
                        />
                      </div>
                    </div>
                    <div v-if="activeTab === 'timeoff'" class="col-auto d-flex flex-column form-group">
                      <div class="">
                        <ui-multi-select
                          name="typeTimeOff"
                          :label="$t('filter_by_type')"
                          :placeholder="$t('Select')"
                          v-model="typeTimeOff"
                          :options="typeTimeOffOptions"
                          :value-name="'code'"
                          :label-name="'name'"
                          :clearable="true"
                        />
                      </div>
                    </div>
                    <div v-if="'shift_confirmation_report' === activeTab" class="col-auto d-flex flex-column form-group">
                      <div class="">
                        <ui-select
                          name="confirmStatus"
                          :label="$t('filter_by_confirm_status')"
                          :placeholder="$t('Select')"
                          v-model="confirmStatus"
                          :options="confirmStatusOptions"
                          :value-name="'id'"
                          :label-name="'name'"
                          :clearable="true"
                        />
                      </div>
                    </div>
                    <div class="col-auto" v-if="['timeoff', 'payrate', 'bonus', 'employee', 'project', 'shift_confirmation_report', 'late_employees_report', 'activity', 'tasks', 'budget', 'minimumHours'].includes(activeTab)">
                      <template v-if="['bonus', 'project', 'shift_confirmation_report', 'late_employees_report', 'activity'].includes(activeTab)">
                        <span class="align-text-top">
                          <i class="icon-download btn-icon text-primary mx-1" type="button" @click="handleExport('default')"></i>
                        </span>
                      </template>
                      <template v-else-if="activeTab === 'timeoff'">
                        <span class="align-text-top">
                          <i class="icon-download btn-icon text-primary mx-1" type="button" @click="handleExport('default')"></i>
                        </span>
                      </template>
                      <template v-else-if="activeTab === 'minimumHours'">
                        <span class="align-text-top">
                          <i class="icon-download btn-icon text-primary mx-1" type="button" @click="handleExport('default')"></i>
                        </span>
                      </template>
                      <template v-else-if="activeTab === 'budget'">
                        <span class="align-text-top">
                          <i class="icon-download btn-icon text-primary mx-1" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" type="button" @click="handleExport('by_locations')">{{ $t('budget_by_locations') }}</a>
                          </div>
                        </span>
                      </template>
                      <template v-else-if="activeTab === 'payrate'">
                        <span class="align-text-top">
                          <i class="icon-download btn-icon text-primary mx-1" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" type="button" @click="handleExport('default')">{{ $t('payrate_default') }}</a>
                            <a class="dropdown-item" type="button" @click="handleExport('extended')">{{ $t('payrate_extended') }}</a>
                          </div>
                        </span>
                      </template>
                      <template v-else-if="'tasks' === activeTab">
                        <span class="align-text-top">
                          <i class="icon-download btn-icon text-primary mx-1" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" type="button" @click="handleExport('time_spent_by_status')">{{ $t('time_spent_in_each_status') }}</a>
                            <a class="dropdown-item" type="button" @click="handleExport('work_time_spent_by_status')">{{ $t('real_working_time_spent_on_tasks') }}</a>
                          </div>
                        </span>
                      </template>
                      <template v-else>
                        <span class="align-text-top">
                          <i class="icon-download btn-icon text-primary mx-1" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" type="button" @click="handleExport('default')">{{ $t('Default') }}</a>
                            <a class="dropdown-item" type="button" @click="handleExport('extended')">{{ $t('Extended') }}</a>
                            <a class="dropdown-item" type="button" @click="handleExport('extended_split')">{{ $t('Extended split') }}</a>
                            <a class="dropdown-item" type="button" @click="handleExport('time_sheet_schedule')">{{ $t('Schedule') }}</a>
                            <a class="dropdown-item" type="button" data-toggle="modal" data-target="#ClickExportTimeSheetModal" @click="openTimeSheetModal('time_sheet')">{{ $t('Time tracking') }}</a>
                            <a class="dropdown-item" type="button" data-toggle="modal" data-target="#ClickExportTimeSheetModal" @click="openTimeSheetModal('time_sheet_by_days')">{{ $t('Time tracking by days') }}</a>
                            <a class="dropdown-item" type="button" data-toggle="modal" data-target="#ClickExportTimeSheetModal" @click="openTimeSheetModal('time_sheet_by_locations')">{{ $t('Time tracking by locations') }}</a>
                            <a class="dropdown-item" type="button" data-toggle="modal" data-target="#ClickExportTimeSheetModal" @click="openTimeSheetModal('time_sheet_full')">{{ $t('time_sheet_full') }}</a>
                            <a class="dropdown-item" type="button" data-toggle="modal" @click="handleExport('time_sheet_by_days_with_shifts')">{{ $t('time_sheet_by_days_with_shifts') }}</a>
                            <a class="dropdown-item" type="button" data-toggle="modal" @click="handleExport('time_sheet_by_location_extend')">{{ $t('time_sheet_by_location_extend') }}</a>
                            <a v-if="[5886, 14184].indexOf(companyId) > -1" class="dropdown-item" type="button" data-toggle="modal" data-target="#ClickExportTimeSheetModal" @click="openTimeSheetModal('employee_working_absence')">{{ $t('employee_working_and_absence_hours_report') }}</a>
                          </div>
                        </span>
                      </template>
                    </div>
                  </template>
                </div>
                <button type="button" class="btn btn-outline-primary rounded-pill shadow-sm" @click="resetAllFilters()">{{ $t('RESET_TO_DEFAULT') }}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-small">
          <div class="card-body">
            <div class="row pb-3 h-100">
              <div class="col-lg-12 flex">
                <company-workers
                  :project="selectProjectByUsers"
                  :period="period"
                  :filterByStatus="filterByStatus"
                  :search="search"
                  :selectedMarks="selectedMarks"
                  :selectedPositions="selectedPositions"
                  :marksByUsers="marksByUsers"
                  :showUsers="showProjectUsers"
                  :positionsByUsers="positionsByUsers"
                  v-if="loaded && activeTab === 'project'">
                </company-workers>
                <employee-working-hours
                  :projects="selectedProjects"
                  :period="period"
                  :search="search"
                  :selectedMarks="selectedMarks"
                  :selectedPositions="selectedPositions"
                  :marksByUsers="marksByUsers"
                  :positionsByUsers="positionsByUsers"
                  :showUsers="showUsers"
                  :byPlanned="byPlanned"
                  v-if="activeTab === 'employee'">
                </employee-working-hours>
                <pay-rate
                  :projects="selectedProjects"
                  :period="period"
                  :search="search"
                  :selectedMarks="selectedMarks"
                  :selectedPositions="selectedPositions"
                  :marksByUsers="marksByUsers"
                  :positionsByUsers="positionsByUsers"
                  v-if="loaded && activeTab === 'payrate'">
                </pay-rate>
                <time-off
                  :projects="selectedProjects"
                  :period="period"
                  :search="search"
                  :selectedMarks="selectedMarks"
                  :selectedPositions="selectedPositions"
                  :typeTimeOff="typeTimeOff"
                  :marksByUsers="marksByUsers"
                  :positionsByUsers="positionsByUsers"
                  v-if="activeTab === 'timeoff'">
                </time-off>
                <budget
                  :projects="selectedProjects"
                  :period="period"
                  :search="search"
                  :selectedMarks="selectedMarks"
                  :selectedPositions="selectedPositions"
                  :marksByUsers="marksByUsers"
                  :positionsByUsers="positionsByUsers"
                  v-if="activeTab === 'budget'">
                </budget>
                <minimumHoursComponent
                  :projects="selectedProjects"
                  :period="weekPeriod"
                  :search="search"
                  :selectedMarks="selectedMarks"
                  :selectedPositions="selectedPositions"
                  :marksByUsers="marksByUsers"
                  :positionsByUsers="positionsByUsers"
                  v-if="activeTab === 'minimumHours'">
                </minimumHoursComponent>
                <BonusPenalty
                  :period="period"
                  :search="search"
                  :selectedMarks="selectedMarks"
                  :selectedPositions="selectedPositions"
                  :marksByUsers="marksByUsers"
                  :positionsByUsers="positionsByUsers"
                  v-if="activeTab === 'bonus'">
                </BonusPenalty>
                <TasksReport
                  v-if="activeTab === 'tasks'"
                  :period="period"
                  :search="search"
                />
                <ActivityReport
                    v-if="activeTab === 'activity'"
                    :period="period"
                    :search="search"
                />
                <VacationsMonthly
                  v-if="activeTab === 'vacationsMonthly'"
                  :month="month"
                  :selectedPositions="selectedPositions"
                  :search="search"
                />
                <ShiftConfirmationReport
                  v-if="activeTab === 'shift_confirmation_report'"
                  :projects="selectedProjects"
                  :period="period"
                  :search="search"
                  :confirmStatus="confirmStatus"
                />
                <LateEmployeesReport
                  v-if="activeTab === 'late_employees_report'"
                  :projects="selectedProjects"
                  :period="period"
                  :search="search"
                  :confirmStatus="confirmStatus"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyWorkers from './StatisticComponents/CompanyWorkers'
import EmployeeWorkingHours from './StatisticComponents/EmployeeWorkingHours'
import PayRate from './StatisticComponents/PayRate'
import TimeOff from './StatisticComponents/TimeOff'
import Budget from './StatisticComponents/Budget'
import BonusPenalty from './StatisticComponents/BonusPenalty'
import minimumHoursComponent from './StatisticComponents/MinimumHours'
import WorkingHoursModal from './WorkingHoursModal.vue'
import errorMixin from '../../mixins/mixinApiErrors'
import momentMixin from '../../mixins/mixinMoment'
import moment from 'moment'
import TasksReport from '@/components/Statistic/StatisticComponents/TasksReport'
import { mapGetters } from 'vuex'
import VacationsMonthly from '@/components/Statistic/StatisticComponents/VacationsMonthly'
import ShiftConfirmationReport from '@/components/Statistic/StatisticComponents/ShiftConfirmationReport'
import LateEmployeesReport from '@/components/Statistic/StatisticComponents/LateEmployeesReport'
import ActivityReport from '@/components/Statistic/StatisticComponents/ActivityReport.vue'

export default {
  name: 'Statistic',
  mixins: [errorMixin, momentMixin],
  components: {
    ActivityReport,
    VacationsMonthly,
    TasksReport,
    CompanyWorkers,
    EmployeeWorkingHours,
    PayRate,
    TimeOff,
    Budget,
    minimumHoursComponent,
    BonusPenalty,
    WorkingHoursModal,
    ShiftConfirmationReport,
    LateEmployeesReport
  },
  data: function () {
    return {
      projects: [],
      options: [],
      selectedMarks: [],
      selectedPositions: [],
      typeTimeOffOptions: [
        { code: 'all', name: this.$i18n?.t('All') },
        { code: 'sick', name: this.$i18n?.t('Sick day') },
        { code: 'vacation', name: this.$i18n?.t('Vacation') },
        { code: 'holiday', name: this.$i18n?.t('Holiday') }
      ],
      typeTimeOff: ['all'],
      schedules: [],
      selectedProjects: [],
      marksByUsers: {},
      search: '',
      byPlanned: false,
      activeTab: null,
      period: [null, null],
      weekPeriod: [null, null],
      loaded: false,
      filterByStatus: 'ignore',
      showUsers: 'all',
      show: false,
      menu: false,
      tabsData: {},
      showTabs: true,
      buttonTitle: 'Please Select',
      baseDate: null,
      positions: [],
      positionsByUsers: [],
      showTimeSheetModal: false,
      weekStartDate: null,
      exportType: 'default',
      selectProjectByUsers: 'all',
      showProjectUsers: 'operating',
      companyProjectsOptions: [],
      month: moment().format(this.backendDateFormat),
      confirmStatus: 'all',
      confirmStatusOptions: [
        { id: 'all', name: this.$i18n?.t('All') },
        { id: 'confirmed', name: this.$i18n?.t('Confirmed') },
        { id: 'unconfirmed', name: this.$i18n?.t('Unconfirmed') }
      ]
    }
  },
  created () {
    this.period = [moment().add(-7, 'Days').format(this.backendDateFormat), moment().format(this.backendDateFormat)]
    this.weekPeriod = [this.weekStart(), this.weekEnd()]
    this.weekStartDate = this.weekStart().format(this.backendDateFormat)
    this.baseDate = moment().format(this.backendDateFormat)
    if (this.workMode === 'work') {
      this.activeTab = 'project'
      this.tabsData = {
        project: 'Project workers',
        employee: 'Employee working hours',
        timeoff: 'Time off reports',
        minimumHours: 'Minimum hours reports',
        bonus: 'BONUS_PENALTY_REPORT',
        activity: 'ACTIVITY_REPORT',
        vacationsMonthly: 'VACATIONS_MONTHLY_REPORT',
        shift_confirmation_report: 'SHIFT_CONFIRMATION_REPORT',
        late_employees_report: 'LATE_EMPLOYEES_REPORT'
      }
      if (this.salaryForManager) {
        this.tabsData.budget = 'Budget reports'
        this.tabsData.payrate = 'Pay rate reports'
      }
    } else {
      this.activeTab = 'tasks'
      this.tabsData = {
        tasks: 'TASKS_REPORT'
      }
    }
    this.$eventBus.on('dummy', this.menuVisible)
    this.positions = this.positionsList.map(item => {
      return {
        id: item.id,
        name: item.title
      }
    })
    if (this.companyId) {
      this.init()
      this.companyProjectsOptions = this.createCompanyProjectsOptions()
    }
  },
  beforeUnmount () {
    this.$eventBus.off('dummy')
  },
  watch: {
    positionsByUsers (val) {
      //
    },
    companyId () {
      this.init()
      this.companyProjectsOptions = this.createCompanyProjectsOptions()
    },
    weekStartDate () {
      this.weekPeriod = [
        moment(this.weekStartDate).startOf('isoWeek'),
        moment(this.weekStartDate).startOf('isoWeek').add(7, 'days')
      ]
    },
    positionsList () {
      this.positions = this.positionsList.map(item => {
        return {
          id: item.id,
          name: item.title
        }
      })
    },
    projectsList () {
      const projectsList = this.projectsList.filter(project => this.isEmployeeHasPermission('create project') ||
        (project.managers && project.managers.includes(this.$store.getters.company.employee_id)))
      projectsList.forEach(project => {
        if (this.selectedProjects && this.selectedProjects.indexOf(project.id) === -1) {
          this.selectedProjects.push(project.id)
        }
      })
      this.changeProjectsList()
    },
    salaryForManager () {
      if (this.salaryForManager && this.workMode === 'work') {
        this.tabsData.budget = 'Budget reports'
        this.tabsData.payrate = 'Pay rate reports'
      }
    }
  },
  computed: {
    ...mapGetters({
      positionsList: 'positions',
      projectsList: 'projects'
    }),
    salarySettings () {
      return this.$store.getters.company.salary
    },
    salaryForManager () {
      if (this.isEmployeeHasPermission('create project')) {
        return true
      }
      if (this.$store.getters.company.salary) {
        return this.$store.getters.company.salary.show_salary_to_managers === undefined ? true : this.$store.getters.company.salary.show_salary_to_managers
      }
      return false
    },
    filteredProjects: function () {
      return this.selectedProjects
    },
    companyProjects () {
      return this.$store.getters.projects
    }
  },
  methods: {
    openTimeSheetModal (exportType) {
      this.exportType = exportType
      this.showTimeSheetModal = true
    },
    closeTimeSheetModal () {
      this.showTimeSheetModal = false
    },
    weekStart () {
      return moment().startOf('isoWeek')
    },
    weekEnd () {
      return moment().startOf('isoWeek').add(7, 'days')
    },
    startDays () {
      return this.weekPeriod[0].clone().format(this.localeDateFormat)
    },
    endDays () {
      return this.weekPeriod[0].clone().add(6, 'days').format(this.localeDateFormat)
    },
    init () {
      this.$store.dispatch('getPositions', this.$store.getters.companyId).then((response) => {
      })
      this.$store.dispatch('getProjectsWithTrashedScheduleUsers', this.$store.getters.companyId).then((response) => {
        this.loaded = true
      })
    },
    createCompanyProjectsOptions () {
      let options = []
      if (this.isEmployeeHasPermission('create project')) {
        options = [
          {
            id: 'all',
            name: this.$t('ALL_USERS')
          },
          {
            id: 'no_project',
            name: this.$t('USERS_WITHOUT_PROJECTS')
          }
        ]
        this.companyProjects.forEach(project => {
          options.push({
            id: project.id,
            name: project.name
          })
        })
      } else {
        options = [
          {
            id: 'all',
            name: this.$t('ALL_USERS')
          }
        ]
        const projects = this.projectsList.filter(project => this.isEmployeeHasPermission('create project') ||
          (project.managers && project.managers.includes(this.$store.getters.company.employee_id)))
        projects.sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        })
        projects.forEach(project => {
          options.push({
            id: project.id,
            name: project.name
          })
        })
      }
      return options
    },
    changeProjectsList () {
      this.options = []
      const options = []
      const marksByUsers = {}
      const positionsByUsers = {}
      this.projects = this.projectsList.filter(project => this.isEmployeeHasPermission('get-tasks-admin') ||
        (project.managers && project.managers.includes(this.$store.getters.company.employee_id)))
      this.projects.forEach(project => {
        if (this.selectedProjects.indexOf(project.id) !== -1) {
          if (project.schedules) {
            project.schedules.forEach(schedule => {
              let scheduleMarks = []
              const optionData = {
                label: `${project.name}: ${schedule.name}`,
                schedule_id: schedule.id,
                options: []
              }
              schedule.employees.forEach(employee => {
                if (!positionsByUsers[employee.id]) {
                  positionsByUsers[employee.id] = null
                }
                if (employee.positions.length > 0) {
                  positionsByUsers[employee.id] = {
                    positionId: employee.positions[0].id,
                    positionName: employee.positions[0].title
                  }
                }
              })
              schedule.users.forEach(user => {
                if (!marksByUsers[user.employee_id]) {
                  marksByUsers[user.employee_id] = []
                }

                if (!marksByUsers[user.employee_id][schedule.id]) {
                  marksByUsers[user.employee_id][schedule.id] = []
                }
                user.marks.forEach(userMark => {
                  if (marksByUsers[user.employee_id][schedule.id].indexOf(userMark) === -1) {
                    marksByUsers[user.employee_id][schedule.id].push(userMark)
                  }
                })
                scheduleMarks = scheduleMarks.concat(user.marks)
              })

              const uniqMarks = [...new Set(scheduleMarks)]

              uniqMarks.forEach(markId => {
                const markPosition = project.marks.filter(item => item.id === markId)
                if (markPosition.length) {
                  optionData.options.push({
                    value: markId,
                    id: schedule.id + ':' + markId,
                    label: markPosition[0].name,
                    name: markPosition[0].name
                  })
                }
              })
              options.push(optionData)
            })
          }
        }
      })
      this.options = options
      this.marksByUsers = marksByUsers
      this.positionsByUsers = positionsByUsers
      this.selectedMarks = []
    },
    toggleMenu () {
      this.showTabs = !this.showTabs
    },
    menuVisible (value) {
      this.menu = !value
    },
    activateTab (name) {
      this.activeTab = name
    },
    selectAllProjects () {
      this.selectedProjects = []
      this.projects.forEach((item) => {
        this.selectedProjects.push(item.id)
      })
    },
    resetSelectedProjects () {
      this.selectedProjects = []
    },
    handleExport (exportType) {
      if (this.activeTab === 'employee') {
        if (['time_sheet_by_location_extend', 'time_sheet_by_days_with_shifts', 'time_sheet_schedule'].includes(exportType)) {
          this.$eventBus.emit('exportTimeSheetReport', exportType)
        } else {
          this.$eventBus.emit('exportEmployeeWorkingHoursReport', exportType)
        }
      } else if (this.activeTab === 'payrate') {
        this.$eventBus.emit('exportPayRateReport', exportType)
      } else if (this.activeTab === 'timeoff') {
        this.$eventBus.emit('exportTimeOffReport', exportType)
      } else if (this.activeTab === 'minimumHours') {
        this.$eventBus.emit('exportMinimumHoursReport', exportType)
      } else if (this.activeTab === 'vacationsMonthly') {
        this.$eventBus.emit('exportVacationsMonthlyReport', exportType)
      } else if (this.activeTab === 'project') {
        this.$eventBus.emit('exportCompanyWorkersReport')
      } else if (this.activeTab === 'shift_confirmation_report') {
        this.$eventBus.emit('exportShiftConfirmationReport')
      } else if (this.activeTab === 'late_employees_report') {
        this.$eventBus.emit('exportShiftConfirmationReport')
      } else if (this.activeTab === 'activity') {
        this.$eventBus.emit('exportShiftActivityReport')
      } else if (this.activeTab === 'tasks') {
        this.$eventBus.emit('exportTasksReport', exportType)
      } else if (this.activeTab === 'budget') {
        this.$eventBus.emit('exportBudgetReport', exportType)
      } else {
        this.$eventBus.emit('exportBonusPenaltyReport', exportType)
      }
    },
    resetAllFilters () {
      this.selectedMarks = []
      this.selectedPositions = []
      this.selectAllProjects()
      this.search = ''
      this.byPlanned = false
      this.period = [moment().add(-7, 'Days').format(this.backendDateFormat), moment().format(this.backendDateFormat)]
      this.filterByStatus = 'ignore'
      this.showUsers = 'all'
      this.selectProjectByUsers = 'all'
      this.showProjectUsers = 'operating'
      this.month = moment().format(this.backendDateFormat)
    }
  }
}
</script>

<style scoped>
</style>
