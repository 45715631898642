<template>
  <Form ref="observe" v-slot="{ meta }">
  <modal-component :id="'RequestCreateShiftModal'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title" id="">{{ $t('Request for creating shift')}}</h5>
    </template>
    <template #body>
        <div class="row mb-2">
          <div class="col-md-6">
            <ui-date-picker
              :label="$t('Shift date from')"
              name="date_from"
              :value-format="dateFormat"
              v-model="shiftDateFrom"
              :validation="{required: true}"
              :clearable="false"
              :disabledDate="disabledDate"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <ui-group-select
              name="scheduleTemplateStr"
              :label="$t('Select a shift')"
              v-model="scheduleTemplateStr"
              :clearable="false"
              :filterable="true"
              :options="selectOptions"
              :key-name="'id'"
              :label-name="'name'"
              :value-name="'id'"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-lg-12 text-left">
            <label class="control-label">{{ $t("Shift period") }} ({{duration}} {{$t('hrs')}})</label>
          </div>
        </div>
        <div class="row align-items-baseline">
          <div class="col-md-4">
            <ui-time-picker
              :label="$t('From')"
              :name="'start_time'"
              :placeholder="$t('From')"
              :disabled="template && !!template.time_from"
              :validation="{required: true}"
              :clearable="false"
              :value-format="backendTimeFormat"
              v-model="timeFrom"
              :picker-options="{start:'00:00',step:'00:05',end:'24:00'}"
            />
          </div>
          <div class="col-md-4">
            <ui-time-picker
              :label="$t('To')"
              :name="'time_to'"
              :placeholder="$t('To')"
              :disabled="template && !!template.time_from"
              :validation="{required: true}"
              :clearable="false"
              :value-format="backendTimeFormat"
              v-model="timeTo"
              :picker-options="{start:'00:00',step:'00:05',end:'24:00'}"
            />
          </div>
          <div class="col-md-4">
            <ui-text-input
              name="break_time"
              v-model="breakTime"
              :label="$t('BREAK_MIN')"
              :disabled="template && !!template.break_time"
              :validation="{integer: true, max_value: durationMinutes}"
            />
          </div>
        </div>
    </template>
    <template #footer>
      <div>
        <button
          class="btn btn-success rounded-pill shadow-sm"
          aria-label="Close"
          @click="addShift"
          :class="{'disabled': !meta.valid}" :disabled="!meta.valid"
        >
          {{ $t('Send request') }}
        </button>
      </div>
    </template>
  </modal-component>
  </Form>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
import errorMixin from '@/mixins/mixinApiErrors'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import mixinColorsMarks from '@/mixins/mixinColorsMarks'

export default {
  name: 'RequestCreateShiftModal',
  mixins: [momentMixin, errorMixin, mixinColorsMarks],
  components: {
    ModalComponent: ModalComponent
  },

  data () {
    return {
      activeCollapse: 'period',
      shiftDateFrom: moment(),
      shiftDateTo: moment(),
      scheduleTemplateStr: '',
      timeFrom: '08:00',
      timeTo: '17:00',
      breakTime: '0',
      scheduleId: '',
      shiftNumber: 1,
      schedule: null,
      template: null,
      schedulesByIds: [],
      templatesByIds: [],
      schedWithAllowRequests: [],
      // inProgress: false,
      shifts: [],
      marks: [],
      addShiftTemplateChoices: {},
      selectOptions: [],
      breakTimeChoices: [
        { id: 0, name: this.$t('None') },
        { id: 10, name: this.$t('10 min') },
        { id: 15, name: this.$t('15 min') },
        { id: 20, name: this.$t('20 min') },
        { id: 30, name: this.$t('30 min') },
        { id: 45, name: this.$t('45 min') },
        { id: 60, name: this.$t('60 min') },
        { id: 90, name: this.$t('90 min') }
      ],
      weekOvertime: {
        is_overtime: false,
        warning: null,
        week_overtime: 0,
        week_overtime_control_type: 'allow_overtime',
        week_quota: 40,
        week_working_hours: 0
      },
      locations: [],
      locationId: null,
      days: [0, 1, 2, 3, 4, 5, 6],
      dates: []
    }
  },

  props: {
    shiftAddDate: Object,
    userId: Number,
    userMarks: Object,
    users: Object,
    schedulesList: Array
  },

  computed: {
    dateTimeFormat () {
      return this.localeDateTimeFormat
    },
    dateFormat () {
      return this.localeDateFormatElementUi
    },
    timeFormat () {
      return this.localeTimeFormatElementUi
    },
    templatesByProject () {
      return this.$store.getters.templatesByProject
    },
    // schedule () {
    //   const scheduleId = +this.scheduleTemplateStr.split('__sp__')[0]
    //   return this.schedulesByIds[scheduleId]
    // },
    // template () {
    //   const templateId = +this.scheduleTemplateStr.split('__sp__')[1]
    //   return this.templatesByIds[templateId]
    // },
    durationMinutes () {
      const timeTo = moment(moment().format('YYYY-MM-DD') + ' ' + this.timeTo)
      const timeFrom = moment(moment().format('YYYY-MM-DD') + ' ' + this.timeFrom)
      if (timeTo.isSameOrBefore(timeFrom)) {
        timeTo.add(1, 'day')
      }
      return timeTo.diff(timeFrom, 'minutes', true)
    },
    duration () {
      let value = moment(moment().format('YYYY-MM-DD') + ' ' + this.timeTo).diff(moment(moment().format('YYYY-MM-DD') + ' ' + this.timeFrom), 'hours', true)
      value = Math.round(value * 100) / 100

      if (value === 0) {
        return 24
      }

      if (value <= 0) {
        return Math.round((24 + value) * 100) / 100
      }
      return Math.round(value * 100) / 100
    },
    blockedDateFrom () {
      return this.fromZoneToZone(this.schedule.date_from, 'UTC', this.$store.getters.actualTZ)
    },
    blockedDateTo () {
      return this.fromZoneToZone(this.schedule.date_to, 'UTC', this.$store.getters.actualTZ)
    },
    companyId () {
      return this.$store.getters.companyId
    },
    shiftsInPast () {
      if (!this.$store.getters.warningInPast) {
        return false
      }
      if (this.timeFrom && this.timeTo) {
        const dateTime = moment([this.shiftDateFrom, this.timeFrom].join(' '), this.localeDateTimeFormat)
        const shiftStart = this.fromZoneToZone(dateTime, this.$store.getters.actualTZ, 'UTC')
        return shiftStart < moment.utc()
      }
      return false
    },
    ...mapGetters({
      preloadUsers: 'employeesIndexed',
      currentProjectId: 'currentProjectId',
      projectMarks: 'projectMarks'
    })
  },

  watch: {
    scheduleTemplateStr () {
      this.templateChange()
    },
    activeCollapse (val, prev) {
      if (!val) {
        this.$nextTick(() => {
          this.activeCollapse = prev === 'period' ? 'dates' : 'period'
        })
      }
    },
    shiftAddDate () {
      this.shiftDateFrom = this.shiftAddDate.format(this.localeDateFormat)
      this.shiftDateTo = this.shiftAddDate.format(this.localeDateFormat)
    },
    marks (items) {
      if (Array.isArray(items)) {
        items.forEach((item, index) => {
          if (typeof item === 'string') {
            const tag = {
              name: item,
              enabled: true
            }
            const usedColors = []
            this.projectMarks.forEach(mark => {
              usedColors.push(mark.color)
            })
            const colorDiff = this.colorsChoicesMarks.filter(x => !usedColors.includes(x))
            if (colorDiff.length > 0) {
              tag.color = colorDiff[0]
            } else {
              tag.color = this.colorsChoicesMarks[Math.floor(Math.random() * this.colorsChoicesMarks.length)]
            }
            const newIndex = index
            this.$store.dispatch('createMark', [tag, this.currentProjectId]).then((mark) => {
              this.marks[newIndex] = mark.id
            })
          }
        })
      }
    }
  },
  created () {
    this.timeFrom = this.timeFromZoneToZone('08:00', 'UTC', 'UTC', this.localeTimeFormatElementUi)
    this.timeto = this.timeFromZoneToZone('17:00', 'UTC', 'UTC', this.localeTimeFormatElementUi)
    this.$store.dispatch('getLocations', this.companyId).then(response => {
      this.locations = response.filter(item => !item.hide).map(location => {
        return { id: location.id, name: location.title }
      })
    })
    this.shiftDateFrom = this.shiftAddDate.format(this.localeDateFormat)
    this.shiftDateTo = this.shiftAddDate.format(this.localeDateFormat)
    // let schedWithAllowRequests = []
    // console.log(this.schedulesList)
    const options = []
    const schedulesByIds = []
    const templatesByIds = []
    this.schedulesList.filter((item) => item.allow_shift_request && item.project_id === this.currentProjectId).forEach(schedule => {
      // schedWithAllowRequests[schedule.id] = schedule
      schedulesByIds[schedule.id] = schedule
      const userInSched = schedule.users.filter((item) => item.employee_id === this.userId).length > 0
      if (userInSched) {
        const schedTemplates = []
        // console.log(45, schedule.templates)
        schedule.templates.forEach(template => {
          if (template.is_active && !template.is_hide) {
            if (this.scheduleTemplateStr === '') {
              this.template = template
              this.scheduleTemplateStr = `${schedule.id}__sp__${template.id}`
              this.timeFrom = template.time_from
              this.timeTo = template.time_to
              this.scheduleId = schedule.id
              this.schedule = schedule
            }
            schedTemplates.push({
              id: `${schedule.id}__sp__${template.id}`,
              name: `${this.$i18n?.t('Shift template')}: ${template.name}`
            })
          }
          templatesByIds[template.id] = template
        })
        // custom template
        schedTemplates.push({
          id: `${schedule.id}__sp__0`,
          name: `${this.$i18n?.t('Shift template')}: ${this.$i18n?.t('Custom template')}`
        })
        if (this.scheduleTemplateStr === '') {
          this.scheduleTemplateStr = `${schedule.id}__sp__0`
          this.timeFrom = this.timeFromZoneToZone('08:00', 'UTC', 'UTC', this.localeTimeFormatElementUi)
          this.timeto = this.timeFromZoneToZone('17:00', 'UTC', 'UTC', this.localeTimeFormatElementUi)
          this.scheduleId = schedule.id
          this.schedule = schedule
        }
        options.push({
          label: `${this.$i18n?.t('Schedule')}: ${schedule.name}`,
          options: schedTemplates
        })
      }
      // console.log('options', options)
      // console.warn('userInSched', userInSched)
    })
    this.schedulesByIds = schedulesByIds
    this.templatesByIds = templatesByIds
    this.selectOptions = options
    // console.log(options)
  },
  mounted () {
    this.$eventBus.emit('requestCreateShiftModalMounted')
  },
  methods: {
    disabledDate (date) {
      return this.blockedDateFrom.isAfter(moment(date)) || moment(date).isSameOrAfter(this.blockedDateTo)
    },
    onChangeDates (dates) {
      this.dates = dates
    },
    onChangeDays (days) {
      this.days = days
    },
    updateOvertimeCheck () {
      this.$store.dispatch('isWeekOvertime', this.weekOvertimeCheckParams()).then(result => {
        this.weekOvertime = result
      })
    },
    weekOvertimeCheckParams () {
      const from = this.fromZoneToZone(this.shiftDateFrom, this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat)
      const to = this.fromZoneToZone(this.shiftDateTo, this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat)
      const query = `employee_id=${this.userId}&time_from=${from}&time_to=${to}&break_time=${+this.breakTime}`
      return [+this.shift.extendedProps.schedule_id, query]
    },
    changeDate () {
      if (moment(this.shiftDateFrom, this.localeDateFormat).isAfter(moment(this.shiftDateTo, this.localeDateFormat))) {
        this.shiftDateTo = this.shiftDateFrom
      }
    },
    closeModal () {
      // this.marks = []
      document.getElementById('RequestCreateShiftModal').click()
      this.$emit('closeRequestCreateShiftModal')
    },
    templateChange () {
      const tmplData = this.scheduleTemplateStr.split('__sp__')
      const templateId = tmplData[1]
      const scheduleId = tmplData[0]

      if (templateId !== '0') {
        const template = this.templatesByIds[templateId]
        this.template = template
        this.timeFrom = template.time_from
        this.timeTo = template.time_to
        this.breakTime = template.break_time.toString()
      } else {
        this.template = null
        this.breakTime = '0'
      }

      this.scheduleId = scheduleId
      this.schedule = this.schedulesByIds[scheduleId]
      this.shiftNumber = 1
    },

    addShiftAction () {
      const payload = {
        subtype: 'create_shift',
        schedule_id: parseInt(this.scheduleId),
        template_id: this.template ? this.template.id : 0,
        date: moment(this.shiftDateFrom, this.localeDateFormat).format(this.backendDateFormat),
        time_from: moment(this.timeFrom, this.localeTimeFormatElementUi).format(this.backendTimeFormat),
        time_to: moment(this.timeTo, this.localeTimeFormatElementUi).format(this.backendTimeFormat)
      }

      this.$store.dispatch('setRequest', [this.$store.getters.companyId, payload]).then((response) => {
        if (response.status === 'confirmed') {
          this.toastSuccess(this.$t('The shift has been successfully created'))

          // add shift to calendar
          this.$eventBus.emit('addShiftsToUsers', [response.shift])
          // this.$eventBus.emit('updateShift', response.shift)
          // this.$eventBus.emit('refetchEvents')
          this.$eventBus.emit('checkNotify')
        } else {
          let message = this.$t('Your request to create a shift has been accepted and will be processed by the manager shortly.')
          if (!response.shift_exchange_confirmation && response.shiftWeekOvertime) {
            message = this.$t('REQUEST_BECAUSE_OVERTIME')
          }
          this.toastSuccess(message)
        }
        this.closeModal()
      }).catch((error) => {
        if (error.response.data.message) {
          this.toastError(this.$i18n?.t(error.response.data.message))
        }
      })
    },
    addShift (e) {
      e.preventDefault()
      this.$refs.observe.validate().then(({ valid }) => {
        if (valid) {
          if (this.shiftsInPast) {
            const body = document.getElementsByTagName('body')
            body[0].setAttribute('style', 'padding-right: 0px;')
            this.$confirm(this.$i18n?.t('You want to add a shift to a passed time period.'), this.$i18n?.t('Are you sure?'), {
              confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
              cancelButtonText: this.$i18n?.t('No, cancel it!!'),
              type: 'warning',
              center: true
            }).then(() => {
              this.addShiftAction()
            }).catch(() => {})
          } else {
            this.addShiftAction()
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .project-color {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 25px;
    vertical-align: middle;
  }

</style>
