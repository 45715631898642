<template>
  <modal-component :id="'RemoveUserFromScheduleModal'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title">{{ $t('Remove employee from schedule')}}</h5>
    </template>
    <template #body>
      <Form ref="observe">
        <div class="mt-3">
          <ui-date-picker
            :label="$t('Remove employee starting from the date')"
            name="start_date"
            v-model="fromDate"
            :validation="{required: true}"
            :clearable="false"
            :disabledDate="disabledDate"
          />
          <span class="mt-2">{{ $t('REMOVE_USER_DATE_DESC')}}</span>
        </div>
        <div class="mt-3">
          <ui-select
            name="removalType"
            :label="$t('Removal options')"
            v-model="removalType"
            :clearable="false"
            :filterable="false"
            class="w-100"
            :options="removeTypeChoices"
            :key-name="'value'"
            :label-name="'label'"
            :value-name="'value'"
          />
          <span class="mt-2 text-center">{{ $t('REMOVE_USER_TYPE_DESC') }}</span>
        </div>
      </Form>
    </template>
    <template #footer>
      <div>
        <button type="button" class="btn btn-primary rounded-pill shadow-sm" @click="save">
          <span aria-hidden="true">{{ $t('Delete')}}</span>
        </button>
      </div>
    </template>
  </modal-component>
</template>
<script>

import ModalComponent from '@/components/CommonComponents/ModalComponent'
import momentMixin from '@/mixins/mixinMoment'
import errorMixin from '@/mixins/mixinApiErrors'
import moment from 'moment'

export default {
  name: 'RemoveUserFromScheduleModal',
  mixins: [errorMixin, momentMixin],
  components: {
    ModalComponent: ModalComponent
  },

  data () {
    return {
      removalType: 'remove',
      fromDate: null,
      removeTypeChoices: [
        {
          value: 'move_free_board',
          label: this.$i18n?.t('Place on Free Board')
        },
        {
          value: 'remove',
          label: this.$i18n?.t('Delete from schedule')
        }
      ],
      selectedSchedule: null
    }
  },

  props: {
    scheduleId: Number,
    userId: Number
  },

  created () {
    this.$store.dispatch('getScheduleById', this.scheduleId).then(schedule => {
      // this.fromDate = schedule.date_from
      this.selectedSchedule = schedule
    })
  },

  computed: {
    dateFormat () {
      return this.localeDateFormatElementUi
    }
  },
  methods: {
    disabledDate (date) {
      if (!this.selectedSchedule) {
        return null
      }

      const project = this.$store.getters.projectById(this.selectedSchedule.project_id)
      const from = this.toSelectedTimeZone(this.selectedSchedule.date_from, project.time_zone).format(this.backendDateFormat)
      const to = this.toSelectedTimeZone(this.selectedSchedule.date_to, project.time_zone).format(this.backendDateFormat)

      return moment.utc(date).isBefore(from) || moment.utc(date).isAfter(to)
    },
    closeModal () {
      this.$emit('closeRemoveUserFromScheduleModal')
    },
    save () {
      this.$refs.observe.validate().then(({ valid }) => {
        if (valid) {
          const formData = {
            users: [this.userId],
            shifts_action: this.removalType,
            date_from: moment.utc(this.fromDate).format(this.backendDateTimeFormat)
          }
          this.$store.dispatch('deleteUsers', [this.scheduleId, formData]).then(() => {
            this.toastSuccess(this.$t('Schedule was successfully changed'))
            document.getElementById('modalCloseButton').click()
            this.closeModal()
            this.$eventBus.emit('updateSchedulesList')
          }).catch((e) => {
            this.toastError(this.$i18n?.t(e.response.data.message))
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
