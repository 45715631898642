<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-else-if="loaded" class="container-fluid p-0">
      <el-table
        :data="filtered"
        stripe
        style="width: auto"
      >
        <el-table-column
          prop="employee.full_name"
          :label="$t('EMPLOYEE_NAME')"
        >
        </el-table-column>
        <el-table-column
          prop="add"
          :label="$t('VACATIONS_ADD')"
          width="180">
        </el-table-column>
        <el-table-column
          prop="reduce"
          :label="$t('VACATIONS_REDUCE')"
          width="180">
        </el-table-column>
        <el-table-column
          prop="balance"
          :label="$t('VACATIONS_BALANCE')"
          width="180">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import paginationMixin from '../../CommonComponents/Pagination/mixinPagination'
import momentMixin from '@/mixins/mixinMoment'
import DummyPaymentRequired from '../../CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'
// import moment from 'moment'

export default {
  mixins: [errorMixin, paginationMixin, momentMixin, mixinDummy],
  components: { DummyPaymentRequired: DummyPaymentRequired },
  name: 'VacationsMonthly',
  data () {
    return {
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      loaded: false,
      data: [],
      filtered: null
    }
  },
  props: {
    month: [String, Date],
    search: String,
    selectedPositions: Array
  },
  created () {
    this.getTableData()
  },
  mounted () {
    this.$eventBus.on('exportVacationsMonthlyReport', this.getTableData)
  },
  beforeUnmount () {
    this.$eventBus.off('exportVacationsMonthlyReport')
  },
  computed: {
    ...mapGetters({
      locale: 'locale'
    }),
    tz () {
      return this.$store.getters.profileTimeZone
    }
  },
  methods: {
    getTableDataActivated () {
      this.dummy = false
      this.getTableData()
    },
    filteredData () {
      let filtered = this.data
      filtered = filtered.sort((a, b) => {
        if (a.employee.full_name > b.employee.full_name) return 1
        if (a.employee.full_name < b.employee.full_name) return -1
        return 0
      })
      if (this.search !== '') {
        filtered = filtered.filter((row, index) => {
          return (
            row.employee.full_name.toLowerCase().indexOf(this.search.toString().toLowerCase()) > -1 ||
            (row.employee.email && row.employee.email.toLowerCase().indexOf(this.search.toString().toLowerCase()) > -1) ||
            (row.employee.phone && ('+' + row.employee.phone).toLowerCase().indexOf(this.search.toString().toLowerCase()) > -1)
          )
        })
      }
      if (this.selectedPositions.length > 0) {
        filtered = filtered.filter((row, index) => {
          let find = 0
          if (row.employee.positions.length > 0 && this.selectedPositions.includes(row.employee.positions[0].id)) {
            find++
          }
          return find > 0
        })
      }

      this.filtered = filtered
    },
    getTableData (exportType = null) {
      this.loaded = false
      const month = this.fromZoneToZone(this.month, this.$store.getters.profileTimeZone, 'UTC').add(5, 'days').format(this.backendMonthYearFormat)

      this.$store.dispatch('getVacationsMonthlyStatistic', [this.$store.getters.companyId, month, exportType, this.selectedPositions]).then((response) => {
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
        if (exportType) {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${this.$i18n?.t('export_vacation_monthly')}_${month}.xlsx`)
          document.body.appendChild(link)
          link.click()
        } else {
          this.data = response
          this.filteredData()
        }
      }).catch((error) => {
        if (error.response.status === 500) {
          this.toastError(this.$t('500_ERROR_MESSAGE'))
          return
        }
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        } else {
          // this.$router.push('dashboard')
          this.toastError(this.$t(error.response.data.message))
        }
      })
    }
  },
  watch: {
    search () {
      this.filteredData()
    },
    selectedPositions (v) {
      this.filteredData()
    },
    month () {
      this.getTableData()
    }
  }
}
</script>

<style>
  th {
    text-align: center;
  }
</style>
