<template>
  <Form ref="observer" v-slot="{ meta }" as="">
  <modal-component :id="'AddHoliday'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title" id="">{{ holidayId ? $t('Edit a holiday') : $t('Create a holiday') }}</h5>
    </template>
    <template #body>
      <div class="control-label font-weight-bold">
        {{ $t('Choose period')}}
      </div>
      <div v-if="showDate" class="row">
        <div class="col-6 mb-2">
          <ui-datetime-picker
            name="from"
            :placeholder="$t('Start date')"
            v-model="dateRange[0]"
            @change="dateChange('from')"
            :validation="{required: true}"
          />
        </div>
        <div class="col-6 mb-2">
          <ui-datetime-picker
            name="to"
            :placeholder="$t('End date')"
            v-model="dateRange[1]"
            @change="dateChange('to')"
            :validation="{required: true}"
          />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-lg-8">
          <ui-text-input
            name="name"
            :label="$t('Holiday title')"
            :placeholder="$t('Title')"
            v-model="holiday.name"
            :validation="{required: true, max: 128}"
          />
        </div>
        <div class="col-lg-4">
          <ui-color-picker
            name="holiday_color"
            v-model="holiday.color"
            :label="$t('Holiday color')"
          />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <ui-textarea-input
            name="description"
            :label="$t('Description')"
            :rows="2"
            v-model="holiday.description"
          />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <ui-radio
            name="block_shifts_false"
            id="block_shifts_false"
            :label="$t('Allow shift addition on this period')"
            v-model="block_shifts"
            :set-value="false"
          />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <div class="d-inline-flex align-items-center">
            <ui-number-input
              name="pay_rate"
              :placeholder="$t('Pay rate')"
              v-model="holiday.pay_rate"
              :disabled="block_shifts"
              :precision="2"
              :step="0.1"
              :min="0"
              :validation="{required: true, decimal: 2}"
            />
            <div class="ml-2">
              {{$t('% from the basic pay rate for work on a holiday')}}
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <ui-radio
            name="block_shifts_false"
            id="block_shifts_true"
            :label="$t('Block shift addition on this period')"
            v-model="block_shifts"
            :set-value="true"
          />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <ui-checkbox
            name="block_timeoff"
            v-model="block_timeoff">
            <div class="text-wrap">
              {{ $t('Block time off requests') }}
            </div>
          </ui-checkbox>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <ui-checkbox
            name="is_work_day"
            v-model="is_work_day"
            :label="$t('IS_WORK_DAY')"
            :hint="$t('IS_WORK_DAY_HINT')"
          />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <ui-checkbox
            name="employees_notify"
            v-model="holiday.employees_notify">
            <div class="text-wrap">
              {{ $t('Notify all employees') }}
            </div>
          </ui-checkbox>
        </div>
      </div>
    </template>
    <template #footer>
      <div>
        <button
          v-if="holidayId"
          data-dismiss="modal"
          class="btn btn-danger rounded-pill shadow-sm"
          aria-label="Close" @click="drop"
        >
          {{ $t('Delete') }}
        </button>
        <button
          class="btn btn-success rounded-pill shadow-sm ml-2"
          :class="{'disabled': !meta.valid}" :disabled="!meta.valid"
          @click="save"
        >
          {{ $t('SAVE_WEB') }}
        </button>
      </div>
    </template>
  </modal-component>
  </Form>
</template>

<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
import errorMixin from '@/mixins/mixinApiErrors'
import ModalComponent from '@/components/CommonComponents/ModalComponent'

export default {
  name: 'AddHolidayModal',
  mixins: [momentMixin, errorMixin],
  components: {
    ModalComponent: ModalComponent
  },
  data () {
    return {
      holidayId: null,
      block_timeoff: false,
      block_shifts: false,
      is_work_day: false,
      holiday: {
        name: null,
        color: '#4e93e9',
        allow_shifts: true,
        pay_rate: 100,
        start: null,
        end: null,
        description: null,
        allow_timeoff: true,
        is_work_day: false,
        employees_notify: false
      },
      dateRange: [moment().startOf('day').toISOString(), moment().add(1, 'days').startOf('day').toISOString()],
      showDate: true
    }
  },
  props: {
    holidayUpdated: Object,
    viewStart: Object,
    toggleModalHoliday: Boolean
  },
  created () {
    this.init()
  },
  watch: {
    toggleModalHoliday () {
      this.init()
    },
    holidayUpdated () {
      this.init()
    }
  },
  methods: {
    init () {
      // console.log(this.holidayUpdated)
      const startDate = this.viewStart.format(this.backendDateTimeFormat)
      this.dateRange = [
        moment(startDate).startOf('day').toISOString(),
        moment(startDate).add(1, 'days').startOf('day').toISOString()
      ]
      if (this.holidayUpdated && this.holidayUpdated.id) {
        const start = this.fromZoneToZone(this.holidayUpdated.start, 'UTC', this.$store.getters.actualTZ).format(this.backendDateTimeFormat)
        const end = this.fromZoneToZone(this.holidayUpdated.end, 'UTC', this.$store.getters.actualTZ).format(this.backendDateTimeFormat)
        this.holidayId = this.holidayUpdated.id
        this.block_timeoff = !this.holidayUpdated.allow_timeoff
        this.block_shifts = !this.holidayUpdated.allow_shifts
        this.is_work_day = this.holidayUpdated.is_work_day
        this.dateRange = [start, end]
        this.holiday = {
          name: this.holidayUpdated.name,
          color: this.holidayUpdated.color,
          allow_shifts: this.holidayUpdated.allow_shifts === 1,
          pay_rate: this.holidayUpdated.pay_rate,
          start: this.holidayUpdated.start,
          end: this.holidayUpdated.end,
          description: this.holidayUpdated.description,
          allow_timeoff: this.holidayUpdated.allow_timeoff === 1,
          is_work_day: this.holidayUpdated.is_work_day,
          employees_notify: false
        }
      }
      // console.log(45, this.holidayUpdated)
      if (this.holidayUpdated && !this.holidayUpdated.id) {
        this.holidayId = null
        this.dateRange = [
          moment(this.holidayUpdated.start).startOf('day').toISOString(),
          moment(this.holidayUpdated.end).add(1, 'day').startOf('day').toISOString()
        ]
        this.block_timeoff = false
        this.block_shifts = false
        this.is_work_day = false
        this.holiday = {
          name: '',
          color: '#4e93e9',
          allow_shifts: true,
          pay_rate: 100,
          start: null,
          end: null,
          description: null,
          allow_timeoff: true,
          is_work_day: false,
          employees_notify: false
        }
        // this.holiday.start = this.holidayUpdated.start
        // this.holiday.end = this.holidayUpdated.end
      }
      if (!this.holidayUpdated) {
        this.holidayId = null
        this.dateRange = [
          moment().startOf('day').toISOString(),
          moment().add(1, 'days').startOf('day').toISOString()
        ]
        this.block_timeoff = false
        this.block_shifts = false
        this.is_work_day = false
        this.holiday = {
          name: '',
          color: '#4e93e9',
          allow_shifts: true,
          pay_rate: 100,
          start: null,
          end: null,
          description: null,
          allow_timeoff: true,
          is_work_day: false,
          employees_notify: false
        }
      }
    },
    dateChange (val) {
      this.showDate = false // костыль, иначе даты в датапикере сразу не обновляются
      if (val === 'from') {
        if (moment(this.dateRange[0]).isAfter(moment(this.dateRange[1]))) {
          this.dateRange[1] = moment(this.dateRange[0]).add(1, 'day').format(this.backendDateTimeFormat)
        }
      }
      if (val === 'to') {
        if (moment(this.dateRange[1]).isBefore(moment(this.dateRange[0]))) {
          this.dateRange[0] = moment(this.dateRange[1]).add(-1, 'day').format(this.backendDateTimeFormat)
        }
      }
      this.showDate = true
    },
    closeModal () {
      document.getElementById('modalCloseButton').click()
      this.$emit('closeAddHolidayModal')
    },
    drop () {
      const holidayId = this.holidayId
      this.$store.dispatch('deleteHoliday', [this.$store.getters.companyId, holidayId]).then(() => {
        this.$eventBus.emit('updateHolidays', {
          id: holidayId,
          data: null
        })
        this.closeModal()
      }).catch(() => {})
    },
    save () {
      this.$refs.observer.validate().then(({ valid }) => {
        if (valid) {
          if (this.holidayId) {
            this.updateHoliday()
          } else {
            this.createHoliday()
          }
        }
      })
    },
    createHoliday () {
      this.holiday.start = this.fromZoneToZone(this.dateRange[0], this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat)
      this.holiday.end = this.fromZoneToZone(this.dateRange[1], this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat)
      this.holiday.allow_timeoff = !this.block_timeoff
      this.holiday.allow_shifts = !this.block_shifts
      this.holiday.is_work_day = this.is_work_day
      this.$store.dispatch('createHoliday', [this.$store.getters.companyId, this.holiday]).then((response) => {
        this.$eventBus.emit('updateHolidays', {
          id: response.id,
          data: response
        })
        this.closeModal()
      }).catch((err) => {
        this.toastError(this.$i18n?.t(err.response.data.message))
      })
    },
    updateHoliday () {
      this.holiday.start = this.fromZoneToZone(this.dateRange[0], this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat)
      this.holiday.end = this.fromZoneToZone(this.dateRange[1], this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat)
      this.holiday.allow_timeoff = !this.block_timeoff
      this.holiday.allow_shifts = !this.block_shifts
      this.holiday.is_work_day = this.is_work_day
      this.$store.dispatch('editHoliday', [this.$store.getters.companyId, this.holidayId, this.holiday]).then((response) => {
        this.$eventBus.emit('updateHolidays', {
          id: response.id,
          data: response
        })
        this.closeModal()
      }).catch((err) => {
        this.toastError(this.$i18n?.t(err.response.data.message))
      })
    }
  }
}
</script>

<style scoped>

</style>
