<template>
  <Form ref="observe">
    <div v-if="values && values.length > 0">
      <div v-for="(field, key) in values" :key="'existing-' + field['title'] + '-' + key" class="pb-2">
        <template v-if="field['field_type'] === 'text'">
          <ui-text-input
            :id="field['field_name']"
            :name="'input-' + field['field_name']"
            v-model="values[key]['value']"
            :validation="{required: field['required'], min: field['required'] ? 1 : null}"
            :label="field['field_title']"
          />
        </template>
        <template v-if="field['field_type'] === 'secret_key'">
          <ui-text-input
            :id="field['field_name']"
            :name="'input-' + field['field_name']"
            v-model="values[key]['value']"
            :disabled="true"
            :validation="{required: field['required'], min: field['required'] ? 1 : null}"
            :label="field['field_title']"
          />
        </template>
        <template v-if="field['field_type'] === 'date_range_picker'">
          <ui-date-picker
            :label="field['field_title']"
            :picker-type="'daterange'"
            :name="'range-' + field['field_name']"
            v-model="values[key]['values']"
            :start-placeholder="$t('Start date')"
            :end-placeholder="$t('End date')"
            :validation="{required: field['required']}"
            :clearable="true"
          />
        </template>
        <template v-if="field['field_type'] === 'date_picker'">
          <ui-date-picker
            :label="field['field_title']"
            :picker-type="'date'"
            :name="'picker-' + field['field_name']"
            v-model="values[key]['value']"
            :validation="{required: field['required']}"
            :clearable="true"
            :placeholder="$t('Pick a day')"
          />
        </template>
        <template v-else-if="field['field_type'] === 'number'">
          <ui-text-input
            :id="field['field_name']"
            :name="'input-number-' + field['field_name']"
            v-model="values[key]['value']"
            :validation="{required: field['required'], decimal: true}"
            :label="field['field_title']"
          />
        </template>
        <template v-else-if="field['field_type'] === 'checkbox'">
          <div>
            <ui-checkbox
              :id="field['field_name']"
              v-model="values[key]['value']"
              :label="field['field_title']"
              :name="'input-checkbox-' + field['field_name']"
            />
          </div>
        </template>
        <template v-else-if="field['field_type'] === 'select'">
          <ui-select
            :id="field['field_name']"
            :name="'select-' + field['field_name']"
            :label="field['field_title']"
            v-model="values[key]['value']"
            class="w-100"
            :options="field['options'].map(op => {
              return {id: op, value: op}
            })"
            :value-name="'id'"
            :label-name="'value'"
            :validation="{required: field['required']}"
          />
        </template>
        <template v-else-if="field['field_type'] === 'multi_select'">
          <ui-multi-select
            :id="field['field_name']"
            :name="'multiselect-' + field['field_name']"
            :label="field['field_title']"
            v-model="values[key]['values']"
            class="w-100"
            :options="field['options'].map(op => {
              return {id: op, value: op}
            })"
            :validation="{required: field['required']}"
          />
        </template>
      </div>
    </div>
  </Form>
</template>

<script>
import mixinMoment from '@/mixins/mixinMoment'

export default {
  name: 'DeveloperAppFields',
  mixins: [mixinMoment],
  props: {
    items: Array
  },
  data () {
    return {
      values: null
    }
  },
  created () {
    this.$eventBus.on('validateDeveloperAppFields', this.validate)
    this.values = this.items
  },
  beforeUnmount () {
    this.$eventBus.off('validateDeveloperAppFields')
  },
  watch: {
    items (val) {
      this.values = val
    },
    values (val) {
      this.$emit('fieldsUpdated', val)
    }
  },
  methods: {
    validate () {
      this.$refs.observe.validate().then(val => {
        if (val) {
          this.$emit('handleSaveFields')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
