<template>
  <div v-if="loaded">
    <div class="card card-small">
      <div class="card-body">
        <div class="row pb-2">
          <div class="col-lg-12">
            <div class="errors" v-for="(error, index) in apiNonFieldErrors" :key="index">{{error}}</div>
            <div v-if="newbie" class="text-uppercase font-weight-bold">{{ $t('Welcome! At this stage you can fill out required fields only and skip other settings steps.') }}</div>
            <div class="text-primary text-uppercase font-weight-bold">{{ $t('General Information') }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <div id="avatar_image_container" class="mt-5">
              <avatar
                v-if="showAvatar"
                :width="180"
                :borderRadius="50"
                :companyIdForAvatar="companyId"
                :placeholder="true"
                :placeholderUrl="'../../../static/images/App/upload.png'"
                :cache="cacheAvatar">
              </avatar>
              <div class="text-center mt-3">
                <ui-file-upload
                  accept="image/*"
                  name='file'
                  :upload="{ url: url, headers: headers }"
                  :auto-upload="true"
                  :multiple="false"
                  :show-file-list="false"
                  :limit="1"
                  @onSuccess="getAvatar"
                  :buttonText="$t('Upload company logo')"
                />
              </div>
              <div v-if="isEmployeeHasPermission('change-company-owner') && !showOwnerChangeForm" class="text-center my-2">
                <button
                  @click="() => { this.showOwnerChangeForm = true }"
                  class="btn btn-danger rounded-pill shadow-sm btn-sm"
                >
                  {{$t('CHANGE_COMPANY_OWNER')}}
                </button>
              </div>
              <Form v-if="showOwnerChangeForm" ref="observerOwner" as="div">
                <ui-select
                  name="newOwnerId"
                  v-model="newOwnerId"
                  :validation="{required: true}"
                  :clearable="false"
                  :filterable="true"
                  :placeholder="$t('Select')"
                  :label="$t('CHANGE_OWNER_FORM_TITLE')"
                  :hint="$t('CHANGE_OWNER_FORM_HINT')"
                  :options="preloadUsers"
                  :key-name="'id'"
                  :label-name="'full_name'"
                  :value-name="'id'"
                />
                <div class="row my-2">
                  <div class="col-12 col-md-6 text-center p-1">
                    <el-button @click="cancelChange" type="success" round>
                      {{$t('Cancel')}}
                    </el-button>
                  </div>
                  <div class="col-12 col-md-6 text-center p-1">
                    <el-button @click="changeOwner" type="danger" round>
                      {{$t('Confirm')}}
                    </el-button>
                  </div>
                </div>
              </Form>
              <div v-if="showOwnerChangeForm">
              </div>
              <el-dialog
                :title="$t('CONFIRM_OWNER_CHANGE_TITLE')"
                v-model="dialogVisible"
                @closed="cancelDialog"
                width="30%">
                <div class="text-break my-3 text-danger">{{$t('CONFIRM_OWNER_CHANGE_MESSAGE')}}</div>
                <template #footer>
                  <div class="dialog-footer">
                    <el-button @click="cancelDialog">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" @click="handleChangeOwner">{{$t('Confirm')}}</el-button>
                  </div>
                </template>
              </el-dialog>
            </div>
          </div>
          <Form ref="observer" as="div" class="col-lg-9">
            <div class="form-row">
              <div class="col-md-6 form-group">
                <ui-text-input
                  name="name"
                  v-model="companyState.name"
                  :validation="{required: true, min: 3, max: 128, regex: /[?$<>{}*^=\p{L}\d]/u}"
                  :label="$t('Company name')"
                />
              </div>
              <div class="col-md-6 form-group">
                <ui-select
                  name="work_mode"
                  :clearable="false"
                  :filterable="false"
                  v-model="companyState.work_mode"
                  :options="workModeOptions"
                  :validation="{required: true}"
                  :label="$t('Select application interface')"
                />
              </div>
            </div>
            <location
              :classes="'col-md-6'"
              :countryId="companyState.address"
              :cityId="companyState.city_id"
              :stateId="companyState.subdivision_id"
              :zipCode="companyState.zip_code"
              :requiredFieldsSetting="{zipCode: true}"
              @addressChange="addressUpdate">
            </location>
            <div class="form-row">
              <div class="form-group col-md">
                <ui-select
                  id="companyWorkers"
                  v-model="companyState.workers"
                  name="workers"
                  :validation="{required: true}"
                  :clearable="false"
                  :filterable="true"
                  :placeholder="$t('Select')"
                  :label="$t('Company workers')"
                  :options="companyWorkersChoice"
                />
              </div>
              <div class="form-group col-md">
                <ui-select
                  id="companyIndustry"
                  v-model="companyState.industry"
                  name="industry"
                  :validation="{required: true}"
                  :clearable="false"
                  :filterable="true"
                  :placeholder="$t('Select')"
                  :label="$t('Industry')"
                  :options="companyIndustryChoice"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 form-group">
                <ui-textarea-input
                  name="scope_company"
                  v-model="companyState.scope_company"
                  :validation="{required: false}"
                  :label="$t('Company details')"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 text-center py-2">
                <button
                  type="submit"
                  name="submit"
                  :disabled="disabled"
                  class="btn rounded-pill shadow-sm"
                  v-bind:class="[newbie ? 'btn-primary' : 'btn-success']"
                  @click="submitForm">{{ newbie ? $t('Next') : $t('SAVE_WEB') }}</button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
import Location from '@/components/CommonComponents/Location'
import Avatar from '@/components/CommonComponents/Avatar'

export default {
  name: 'MainSettings',
  props: ['company', 'newbie'],
  mixins: [errorMixin, momentMixin],
  components: { Location: Location, avatar: Avatar },
  data () {
    return {
      disabled: false,
      loaded: false,
      companyWorkersChoice: [
        '1-20', '21-50', '51-100', '100-300', '300-1000', '1000+'
      ],
      companyIndustryChoice: [],
      cacheAvatar: true,
      url: process.env.VUE_APP_ROOT_API + 'companies/' + this.$store.getters.companyId + '/avatar',
      headers: {
        Authorization: `${localStorage.getItem('token_type')} ${localStorage.getItem('api_key')}`
      },
      showAvatar: true,
      showOwnerChangeForm: false,
      preloadUsers: [],
      newOwnerId: null,
      dialogVisible: false,
      companyState: {},
      workModeOptions: [
        {
          id: 'work',
          name: this.$t('Work mode')
        },
        {
          id: 'field_service',
          name: this.$t('Field service mode')
        }
      ]
    }
  },
  created () {
    this.companyState = this.company
    this.$store.dispatch('getProfile').then(() => {
      if (!this.$route.params.id) {
        this.getInfoCreate()
      } else {
        this.loaded = true
      }
    })
    this.$store.dispatch('translationsByType', ['en', 'industry']).then((response) => {
      this.companyIndustryChoice = Object.keys(response)
    }).catch(() => {})
    this.$store.dispatch('getEmployeesByCompany', this.$store.getters.companyId).then((preloadUsers) => {
      this.preloadUsers = Object.values(preloadUsers)
        .filter(employee => employee.user_id > 0 && employee.id !== this.currentEmployeeId)
        .sort((a, b) => {
          if (a.full_name.toLowerCase() > b.full_name.toLowerCase()) {
            return 1
          }
          if (a.full_name.toLowerCase() < b.full_name.toLowerCase()) {
            return -1
          }
          return 0
        })
    })
  },
  computed: {
    ...mapGetters([
      'Profile'
    ]),
    currentEmployeeId () {
      return this.$store.getters.company.employee_id
    }
  },
  watch: {
    Profile () {
      if (!this.$route.params.id) {
        this.getInfoCreate()
      } else {
        this.loaded = true
      }
    }
  },
  methods: {
    cancelChange () {
      this.showOwnerChangeForm = false
      this.newOwnerId = null
    },
    cancelDialog () {
      this.dialogVisible = false
      this.newOwnerId = null
    },
    changeOwner () {
      this.$refs.observerOwner.validate().then(result => {
        if (result.valid && this.isEmployeeHasPermission('change-company-owner')) {
          this.showOwnerChangeForm = false
          this.dialogVisible = true
        }
      })
    },
    handleChangeOwner () {
      this.dialogVisible = false
      this.$store.dispatch('changeOwner', [this.companyState.id, {
        employee_id: this.newOwnerId
      }]).then(() => {
        this.toastSuccess(this.$t('OWNER_CHANGED'))
        this.$store.dispatch('logout', this.$store.getters.locale).then(() => {
        }).catch(() => {
        })
      })
    },
    addressUpdate (newAddress) {
      this.companyState.address = newAddress.countryId
      this.companyState.city_id = newAddress.cityId
      this.companyState.subdivision_id = newAddress.stateId
      this.companyState.zip_code = newAddress.zipCode
    },
    submitForm () {
      this.cancelChange()
      // this.errors.clear()
      this.$nextTick(() => {
        this.$refs.observer.validate().then(result => {
          if (result.valid) {
            let companyData = this.companyState
            if (this.$route.params.id) {
              this.disabled = true
              this.$store.dispatch('editCompany', [this.$route.params.id, companyData]).then((response) => {
                this.$emit('dispatchCompany')
                // console.log(response)
                this.disabled = false
                if (this.newbie) {
                  this.$eventBus.emit('setStep', 2)
                }
                this.toastSuccess(this.$t('Company successfully edited'))
              }).catch((err) => {
                this.toastError(this.$i18n?.t(err.response.data.message))
                this.disabled = false
              })
            } else {
              companyData.salary = this.companyState.salary
              companyData.user_privacy = this.companyState.user_privacy
              companyData.user_fields = this.companyState.user_fields
              companyData = this.beforeSave(companyData)
              this.disabled = true
              this.$store.dispatch('createCompany', companyData).then((company) => {
                this.disabled = false
                this.$store.dispatch('dropProfile')
                this.$store.dispatch('getProfile')
                this.$eventBus.emit('checkout')
                if (this.newbie) this.$eventBus.emit('setStep', 2)
                localStorage.setItem('currentCompany', company.id)
                localStorage.setItem('companyRole', 'gg_admin')
                this.toastSuccess(this.$t('Company successfully edited'))
                Promise.resolve(this.dispatchCompany()).then(() => {})
              }).catch((error) => {
                this.toastError(this.$t(error.response.data.message))
                this.disabled = false
              })
            }
          }
        })
      })
    },
    beforeSave (companyData) {
      if (companyData.subdivision_id === '') {
        delete companyData.subdivision_id
      }
      if (companyData.zip_code === '') {
        delete companyData.zip_code
      }
      Object.keys(companyData.salary).forEach(key => {
        if (!['currency', 'week_start', 'allow_overtime', 'pay_breaks'].includes(key)) {
          if (!companyData.salary[key]) {
            companyData.salary[key] = 0
          }
          companyData.salary[key] = parseFloat(companyData.salary[key])
        }
        companyData[key] = companyData.salary[key]
      })
      return companyData
    },
    dispatchCompany () {
      // console.log(this.$store.getters.companyId)
      this.$store.dispatch('getCompany', this.$store.getters.companyId).then(() => {
        this.$router.push({
          name: 'Company',
          params: { id: this.$store.getters.companyId }
        })
      })
    },
    getInfoCreate () {
      if ('subdivision_id' in this.Profile) {
        this.companyState.subdivision_id = this.Profile.subdivision_id
      }
      if ('zip_code' in this.Profile) {
        this.companyState.zip_code = this.Profile.zip_code
      }
      // console.log(this.Profile)
      this.companyState.address = this.Profile.country_code
      this.companyState.city_id = this.Profile.city_id
      this.companyState.first_name = this.Profile.first_name
      this.companyState.last_name = this.Profile.last_name
      if (this.Profile.date_birth) {
        this.companyState.date_birth = this.Profile.date_birth
      }
      if (this.Profile.phone) {
        this.companyState.phone = Object.keys(this.Profile.phone)[0]
      }
      this.companyState.email = this.Profile.email
      this.loaded = true
    },
    getAvatar () {
      this.cacheAvatar = false
      this.showAvatar = false
      // возвращаем флаг кэширования в тру, чтобы можно было менять аватар без перезагрузки страницы несколько раз подряд
      const self = this
      setTimeout(function () {
        self.cacheAvatar = true
        self.showAvatar = true
      }, 2000)
      this.$eventBus.emit('updateLogo')
      // this.cacheAvatar = true
    }
  }
}
</script>
<style>

</style>
