<template>
  <div class="col-12 col-lg-8 text-right">
    <div class="row float-right">
<!--      <div class="col-auto my-auto">-->
<!--        <el-button-->
<!--            v-if="!hideDateButton"-->
<!--            class="mx-1"-->
<!--            type="primary"-->
<!--            @click="changeDate(-1)"-->
<!--            icon="Arrow-left">-->
<!--        </el-button>-->
<!--        <el-button-->
<!--            v-if="!hideDateButton"-->
<!--            class="mx-1"-->
<!--            type="primary"-->
<!--            @click="changeDate(0)"-->
<!--        >-->
<!--          {{$t('TODAY')}}-->
<!--        </el-button>-->
<!--        <el-button-->
<!--            v-if="!hideDateButton"-->
<!--            class="mx-1"-->
<!--            type="primary"-->
<!--            @click="changeDate(1)"-->
<!--            icon="Arrow-right">-->
<!--        </el-button>-->
<!--      </div>-->
      <div class="col-auto my-auto">
        <ui-date-picker
            :pickerType="'daterange'"
            name="from_to_date"
            v-model="period"
            :validation="{required: true}"
            :clearable="false"
            :teleported="true"
            :shortcuts="preset"
        />
      </div>
      <div class="col-auto my-auto">
        <button
            v-if="!hideAddTaskButton"
            class="btn btn-primary btn-sm rounded-pill"
            data-toggle="modal"
            data-target="#ClickTaskModal"
            type="button"
            @click="addTask">
          {{ $t('Create Task') }}
        </button>
        <el-button
          v-if="!hideMapButton"
          class="mx-1"
          type="primary"
          @click="openMap"
          icon="MapLocation">
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import moment from 'moment/moment'

export default {
  name: 'DateNavigationBar',
  mixins: [momentMixin],
  data () {
    return {
      period: [moment().format(this.backendDateFormat), moment().format(this.backendDateFormat)],
      preset: [
        {
          text: this.$t('Last month'),
          value: () => {
            const start = moment().add(-1, 'month').startOf('month').format(this.backendDateFormat)
            const end = moment().add(-1, 'month').endOf('month').format(this.backendDateFormat)
            return [start, end]
          }
        },
        {
          text: this.$t('Last week'),
          value: () => {
            const start = moment().add(-1, 'week').startOf('isoWeek').format(this.backendDateFormat)
            const end = moment().add(-1, 'week').endOf('isoWeek').format(this.backendDateFormat)
            return [start, end]
          }
        },
        {
          text: this.$t('Last day'),
          value: () => {
            const start = moment().add(-1, 'day').startOf('day').format(this.backendDateFormat)
            const end = moment().add(-1, 'day').endOf('day').format(this.backendDateFormat)
            return [start, end]
          }
        },
        {
          text: this.$t('Today'),
          value: () => {
            const start = moment().format(this.backendDateFormat)
            const end = moment().format(this.backendDateFormat)
            return [start, end]
          }
        },
        {
          text: this.$t('Current week'),
          value: () => {
            const start = moment().startOf('isoWeek').format(this.backendDateFormat)
            const end = moment().endOf('isoWeek').format(this.backendDateFormat)
            return [start, end]
          }
        },
        {
          text: this.$t('Current month'),
          value: () => {
            const start = moment().startOf('month').format(this.backendDateFormat)
            const end = moment().endOf('month').format(this.backendDateFormat)
            return [start, end]
          }
        },
        {
          text: this.$t('Next day'),
          value: () => {
            const start = moment().add(1, 'day').startOf('day').format(this.backendDateFormat)
            const end = moment().add(1, 'day').endOf('day').format(this.backendDateFormat)
            return [start, end]
          }
        },
        {
          text: this.$t('Next week'),
          value: () => {
            const start = moment().add(1, 'week').startOf('isoWeek').format(this.backendDateFormat)
            const end = moment().add(1, 'week').endOf('isoWeek').format(this.backendDateFormat)
            return [start, end]
          }
        },
        {
          text: this.$t('Next month'),
          value: () => {
            const start = moment().add(1, 'month').startOf('month').format(this.backendDateFormat)
            const end = moment().add(1, 'month').endOf('month').format(this.backendDateFormat)
            return [start, end]
          }
        }
      ]
    }
  },
  created () {
    const period = this.$store.getters.tasksListViewPeriod
    if (period) {
      this.period = period
    }
  },
  props: {
    hideDateButton: { type: Boolean, default: false },
    hideAddTaskButton: { type: Boolean, default: false },
    hideMapButton: { type: Boolean, default: true }
  },
  computed: {
    firstDayOfWeek () {
      return +this.$store.getters.company.salary.week_start + 1
    }
  },
  watch: {
    period () {
      this.pickerChange()
    }
  },
  methods: {
    changeDate (val) {
      if (val === 0) {
        this.period = [moment().format(this.backendDateFormat), moment().format(this.backendDateFormat)]
      } else {
        const date = moment(this.period[0], this.backendDateFormat).add(val, 'days').format(this.backendDateFormat)
        this.period = [date, date]
      }
      // this.$emit('changeDate', this.period)
    },
    pickerChange () {
      this.$emit('changeDate', this.period)
    },
    addTask () {
      this.$emit('addTask')
    },
    openMap () {
      this.$emit('openMap')
    }
  }
}
</script>

<style>
</style>
