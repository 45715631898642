<template>
  <div class="d-flex justify-content-center">
    <div class="card col-md-4">
    <Form @submit="submitForm">
      <div class="card-header">
        <h5 class="text-center">{{ $t('Log in to your account') }}</h5>
      </div>
<!--      <el-alert v-if="errors.has('apiErrors')"-->
<!--                class="mt-1"-->
<!--                data-cy="form-alert"-->
<!--                :title="$t('Incorrect username and/or password')"-->
<!--                type="warning">-->
<!--      </el-alert>-->
<!--      <el-alert v-if="errors.has('social')"-->
<!--                class="mt-1"-->
<!--                :title="$t(errors.first('social'))"-->
<!--                type="warning">-->
<!--      </el-alert>-->
      <div class="card-body">
        <div
          id="form_login"
          class="justify-content-center"
        >
          <ui-text-input
            class="w-auto"
            id="username"
            name="username"
            v-model="username"
            :validation="{required: true}"
            :data-cy="'login-input'"
            :placeholder="$t('E-mail or phone number')"
            prepend-icon="icon-user"
          />
          <ui-text-input
            class="mt-3"
            id="password"
            name="password"
            type="password"
            v-model="password"
            :validation="{required: true, min: 8}"
            :data-cy="'password-input'"
            :placeholder="$t('Password')"
            prepend-icon="icon-lock"
          />
          <small>
            <router-link :to="`/password/recovery?lang=${lang}`">{{ $t('Forgot password?') }}</router-link>
          </small>

          <div class="d-flex justify-content-center">
            <button
              type="submit"
              name="submit"
              class="btn btn-primary rounded-pill shadow-sm"
              data-cy="submit-login">
              {{ $t('Sign in') }}
            </button>
          </div>

          <div class="pt-4">
            <hr class='hr'>
          </div>

          <div class="pt-4 d-flex justify-content-center">
            <div class="row d-flex justify-content-center">
              <div class="col-12 d-flex justify-content-center pb-2">
                {{ $t('Sign in with') }}
              </div>
              <div class="col-12 d-flex justify-content-center">
                <router-link @click="authenticate('google')" to="">
                  <img src="@/assets/images/g_sign_in.png" width="180" alt="shifton">
                </router-link>
              </div>
              <div class="col-12 d-flex justify-content-center pt-1">
                <router-link @click="authenticate('live')" to="">
                  <img src="@/assets/images/ms-live_signin_light.png" width="175" alt="shifton">
                </router-link>
              </div>
              <div class="col-12 d-flex justify-content-center">
                <div id="appleid-signin" data-type="sign in" class="signin-button"></div>
              </div>
            </div>
          </div>
          <div class="row pt-2 d-flex justify-content-center">
            <div class="col-sm-auto p-1 my-auto text-center">
              {{ $t('Don\'t have an account?') }}
            </div>
            <div class="col-sm-auto p-1 text-center">
              <router-link :to="`/registration?lang=${pageLang}`" class="">
                <button type="button" class="btn btn-outline-info rounded-pill ml-2">{{ $t('Register') }}</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </Form>
    </div>
  </div>
</template>

<script>
import errorMixin from '../mixins/mixinApiErrors'
import { loadLanguageAsync } from '@/i18n'
import { mapGetters } from 'vuex'
import mixinWindowWidthWatch from '@/mixins/mixinWindowWidthWatch'
import IMG from '@/assets/images/logo.svg'

function isUndefined (value) {
  return typeof value === 'undefined'
}

function getRedirectUri (uri) {
  try {
    return (!isUndefined(uri)) ? ('' + (window.location.origin) + uri) : window.location.origin
  } catch (e) {
    // console.log('getRedirectUri', e)
  }

  return uri || null
}

export default {
  name: 'Login',
  mixins: [errorMixin, mixinWindowWidthWatch],
  data () {
    return {
      username: '',
      password: '',
      recaptcha_key: '',
      disabled: false,
      languages: ['ru', 'en', 'he', 'uk', 'de', 'pl', 'es', 'el'],
      pageLang: 'en',
      firstCompanyId: null,
      popup: null,
      sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
      recaptchaShow: (process.env.VUE_APP_RECAPTCHA_SHOW === 'true'),
      apple: {
        redirectUri: getRedirectUri('/login'),
        url: '/',
        authorizationEndpoint: 'https://appleid.apple.com/auth/authorize?client_id=app-qa2&redirect_uri=https://app.qa1.raspisanka.dviga.com/login&response_type=code%20id_token&response_mode=fragment&state=32234234234',
        name: 'Apple signIn',
        responseDataKey: 'data',
        tokenName: 'access_token',
        tokenPath: '',
        state: null,
        responseType: 'token',
        popupOptions: {
          withCredentials: 'yes',
          width: 1200,
          height: 650,
          location: 0,
          menubar: 'yes'
        }
      },
      response: null
    }
  },
  mounted () {
    if (this.isMobile) {
      this.$alert('<div><img src="' + IMG + '" width="200"></div>' + '<div class="mt-2">' + this.$t('USE_DESKTOP_WARNING') + '</div>', this.$t('USE_DESKTOP_WARNING_TITLE'), {
        confirmButtonText: this.$t('OK'),
        dangerouslyUseHTMLString: true,
        customClass: 'w-100',
        center: true,
        callback: action => {}
      })
    }
    window.AppleID.auth.init({
      clientId: 'app-qa2',
      scope: 'name email',
      redirectURI: getRedirectUri('/login'),
      usePopup: true
    })
    document.addEventListener('AppleIDSignInOnSuccess', (data) => {
      this.auth({
        access_token: data.detail.authorization.id_token,
        provider: 'sign-in-with-apple',
        grant_type: 'social'
      })
    })
    document.addEventListener('AppleIDSignInOnFailure', (err) => {
      console.log(err)
    })
  },
  created () {
    if (this.$route.query.f) {
      localStorage.setItem('agentId', this.$route.query.f)
    }
    if (localStorage.getItem('need_login')) {
      this.toastError(this.$i18n?.t('You must be logged'))
      localStorage.removeItem('need_login')
    }
    if (this.languages.includes(this.$route.query.lang)) {
      this.pageLang = this.$route.query.lang
    }
    this.setLanguage()
  },
  watch: {
    async '$recaptchaInstance.recaptcha' () {
      // this.$recaptchaInstance.showBadge()
    },
    apiNonFieldErrors: function () {
      if (this.apiNonFieldErrors.length > 0) this.disabled = false
    },
    username () {
      this.username = this.username.trim()
    },
    $route (to, from) {
      if (this.languages.includes(this.$route.query.lang)) {
        this.pageLang = this.$route.query.lang
      }
    }
  },
  computed: {
    ...mapGetters(['Profile'])
  },
  methods: {
    submitForm (values) {
      this.userAuth(values)
    },
    checkInvite () {
      if (localStorage.getItem('add_to_company')) {
        this.$store.dispatch('confirmInviteAuth', localStorage.getItem('add_to_company')).then(response => {
          if (response) {
            localStorage.removeItem('add_to_company')
            this.toastSuccess(this.$i18n?.t('You were successfully added to a company'))

            this.$store.dispatch('dropProfile').then(() => {
              this.$store.dispatch('getProfile').then(() => {
                this.$store.dispatch('clearCompanyStore').then(() => {
                  this.$store.dispatch('getCompany', this.$store.state.user.Profile.companies[0]).then((company) => {
                    this.$router.push(`/c/${company.id}/dashboard`)
                  })
                })
              })
            })
          } else {
            this.toastError(this.$t(this.$store.state.apiErrors.error))

            if (this.$store.state.apiErrors.error.toLowerCase().indexOf('not found.') > -1) {
              localStorage.removeItem('add_to_company')
            }
            this.$store.dispatch('getProfile').then(() => {
              this.$router.push('/dashboard').catch(() => {})
            })
          }
        })
      } else {
        if (this.firstCompanyId) {
          this.$router.push(`/c/${this.firstCompanyId}/dashboard`)
        } else {
          this.$router.push('/dashboard').catch(() => {})
        }
      }
    },
    authenticate (provider) {
      // console.log(1, this.$auth.isAuthenticated())
      if (this.$auth.isAuthenticated()) {
        this.$auth.logout()
      }
      this.response = null

      this.$auth.authenticate(provider).then(() => {
        // console.log(2, this.$auth.getToken())
        this.auth({
          access_token: this.$auth.getToken(),
          provider: provider === 'live' ? 'microsoft' : provider,
          grant_type: 'social'
        })
      }).catch((error) => {
        // console.log(error)
        this.response = error
        this.toastError(this.$t('Authorization was interrupted'))
      })
    },
    async auth (authData) {
      authData.recaptcha_key = this.recaptcha_key
      this.$store.dispatch('getAuth', authData).then(async () => {
        await this.$store.dispatch('getProfile')
        await this.$store.dispatch('getUserInfo')

        localStorage.removeItem('agentId')
        this.$router.push({}).catch((e) => {})
        this.$store.dispatch('getAvatar', this.$store.getters.profileId)
        const companies = this.$store.getters.Profile.companies
        if (companies && companies.length > 0) {
          this.firstCompanyId = companies[0]
          this.$store.dispatch('getCompany', companies[0]).then(() => {
            this.setLanguage(this.lang)
            this.checkInvite()
          })
        } else {
          this.setLanguage(this.lang)
          this.checkInvite()
        }
      }).catch((err) => {
        if (err.response.data.error === 'invalid_credentials') {
          this.toastError(this.$t('Sorry, we didn\'t find your Shifton account. Please register first!'))
        } else {
          this.toastError(this.$t(err.response.data.message))
        }
        // console.log(err.response)
      })
    },
    async userAuth () {
      let username = this.username
      const useImpersonalization = username.indexOf('#') > -1

      if (username.indexOf('#') === -1 && !this.validateEmail(username)) {
        username = username.replace(/\D/g, '')
      }
      if (this.validateEmail(username)) {
        username = username.toLowerCase()
      }

      await this.$recaptchaLoaded()
      if (!this.recaptchaShow) {
        this.recaptcha_key = this.sitekey
      } else {
        const token = await this.$recaptcha('Login')
        this.recaptcha_key = token
      }

      await this.auth({
        username: username,
        password: this.password,
        grant_type: 'password',
        useImpersonalization: useImpersonalization
      })
    },
    validateEmail (email) {
      const re = /[^\s@]+@[^\s@]+\.[^\s@]+/
      return re.test(email)
    },
    setLanguage (lang = this.pageLang) {
      loadLanguageAsync(lang)
    }
  }
}
</script>
<style scoped lang="scss">
  .hr {
    width: 80%;
    margin-top: 1px;
    margin-bottom: 1px
  }

  .login-icon {
    font-size: 1.750rem !important;
  }
  .signin-button {
      font-size: 1.75rem !important;
      width: 176px;
      height: 35px;
  }
  .checkbox-component > input:checked + label > .input-box {
    background: url("../../public/static/images/App/checkbox-green.png") no-repeat center !important;
    background-size: 100% 100% !important;
  }
</style>
