<template>
  <div>
    <div class="container-fluid p-0" v-if="loaded">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th class="align-middle text-center table-header" v-for="(item, key) in columns" :key="item.name+ '_' + key">{{item.title}}</th>
          </tr>
          </thead>
          <tbody v-if="this.rows.length > 0">
          <template v-for="value in paginationConfig.paginated">
            <template v-for="(row, name) in value" :key="name + '_total'">
              <tr class="secondary pointer border border-primary" @click="showHide(name)">
                <td class="text-nowrap">
                  <strong>{{name}}</strong>
                  <div class="small">
                    {{rows.length > 0 && positionsByUsers[row[0]['employee_id']] ? positionsByUsers[row[0]['employee_id']].positionName : ''}}
                  </div>
                </td>
                <td></td>
                <td class="text-center"><strong>{{ roundValue(row[0]['duration']) }} <br> ({{hrsForHuman(+row[0]['duration'] + Number.EPSILON)}})</strong></td>
                <td class="text-center"><strong>{{ roundValue(row[0]['working_time']) }} <br> ({{hrsForHuman(+row[0]['working_time'] + Number.EPSILON)}})</strong></td>
              </tr>
              <template v-for="(project, i, index) in row[0]['projects']">
                <template v-if="display.includes(name)">
                  <tr :key="name + '_' + i">
                    <td :rowspan="1" class="text-nowrap"></td>
                    <td class="text-nowrap"><strong>{{ project.project_name }}</strong></td>
                    <td class="text-center">{{ roundValue(project.duration / 60.0) }} <br> ({{hrsForHuman(project.duration / 60.0 + Number.EPSILON)}})</td>
                    <td v-if="index === 0" :rowspan="Object.keys(row[0]['projects']).length" class="align-middle text-center">{{ roundValue(row[0]['working_time']) }} <br> ({{hrsForHuman(+row[0]['working_time'] + Number.EPSILON)}})</td>
                  </tr>
                </template>
              </template>
            </template>
          </template>
          </tbody>
        </table>
      </div>
      <pagination :rows="paginationConfig.rows" v-on:updatePaginated="updatePaginated"></pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '../../../mixins/mixinApiErrors'
import momentMixin from '../../../mixins/mixinMoment'
import paginationMixin from '../../CommonComponents/Pagination/mixinPagination'
import Pagination from '../../CommonComponents/Pagination/Pagination'

export default {
  name: 'MinimumHours',
  mixins: [errorMixin, momentMixin, paginationMixin],
  data () {
    return {
      columns: [
        { name: 'name', title: this.$i18n?.t('Name') },
        { name: 'project', title: this.$i18n?.t('Project') },
        { name: 'duration', title: this.$i18n?.t('SHIFT_DURATION_HRS') },
        { name: 'working_time', title: this.$i18n?.t('Required working time') }
      ],
      rows: [],
      headRows: {},
      loaded: false,
      display: []
    }
  },
  props: ['projects', 'period', 'search', 'selectedMarks', 'marksByUsers', 'showUsers', 'selectedPositions', 'positionsByUsers'],
  components: { pagination: Pagination },
  beforeMount () {
    this.getTableData()
  },
  mounted () {
    this.$eventBus.on('exportMinimumHoursReport', this.getTableData)
  },
  beforeUnmount () {
    this.$eventBus.off('exportMinimumHoursReport')
  },
  computed: {
    ...mapGetters({
      locale: 'locale'
    }),
    filteredEmployees: function () {
      return this.filteredByName(this.filteredByPositions(this.filteredByMarks(this.rows)))
    }
  },
  methods: {
    filteredByName (employees) {
      return employees.filter((row) => {
        if (this.search === '') {
          return row
        }
        const self = this
        let find = 0
        Object.keys(row).forEach(function (key) {
          if ((key.toLowerCase()).indexOf(self.search.toString().toLowerCase()) > -1) find++
        })
        return find > 0
      })
    },
    filteredByPositions (employees) {
      return employees.filter((row) => {
        if (this.selectedPositions.length === 0) {
          return row
        }
        let find = 0
        Object.keys(row).forEach((key) => {
          row[key].forEach(userData => {
            const userId = userData.employee_id
            if (this.positionsByUsers[userId] && this.selectedPositions.includes(this.positionsByUsers[userId].positionId)) {
              find++
            }
          })
        })

        return find > 0
      })
    },
    filteredByMarks (employees) {
      const selectedMarks = this.selectedMarks
      const marksByUsers = this.marksByUsers
      return employees.filter((row) => {
        if (selectedMarks.length === 0 && marksByUsers) {
          return row
        }
        let find = 0
        Object.keys(row).forEach(function (key) {
          row[key].forEach(userData => {
            const userId = userData.employee_id
            selectedMarks.forEach(selectMark => {
              const splitData = selectMark.split(':')
              const scheduleId = parseInt(splitData[0])
              const markId = parseInt(splitData[1])
              if (marksByUsers[userId] && scheduleId in marksByUsers[userId] && marksByUsers[userId][scheduleId].indexOf(markId) !== -1) {
                find += 1
              }
            })
          })
        })
        return find > 0
      })
    },
    setFilteredData () {
      this.paginationConfig.rows = this.filteredEmployees
    },
    roundValue (value) {
      return Math.round(value * 100) / 100
    },
    showHide (name) {
      if (this.display.includes(name)) {
        for (let i = 0; i < this.display.length; i++) {
          if (this.display[i] === name) {
            this.display.splice(i, 1)
          }
        }
      } else {
        this.display.push(name)
      }
    },
    getTableData (exportType = null) {
      this.loaded = false
      const [start, end] = [...this.period]
      const dateFrom = this.toUTC(start.format(this.localeDateFormat)).format(this.backendDateTimeFormat)
      const dateTo = this.toUTC(end.format(this.localeDateFormat)).format(this.backendDateTimeFormat)
      let data = '?projects[]=' + this.projects.join('&projects[]=') + '&date_from=' + dateFrom + '&date_to=' + dateTo
      if (exportType) {
        data += '&export_type=' + exportType
      }
      // console.error(this.selectedMarks)
      if (this.selectedMarks.length > 0) {
        data += '&marks[]=' + this.selectedMarks.join('&marks[]=')
      }
      if (this.selectedPositions.length > 0) {
        data += '&positions[]=' + this.selectedPositions.join('&positions[]=')
      }
      // console.error(data)
      this.$store.dispatch('getMinimumHours', [this.$store.getters.companyId, data, exportType]).then((response) => {
        this.loaded = true
        this.$eventBus.emit('dummy', false)
        if (exportType) {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${this.$i18n?.t('export_minimum_hours')}_${dateFrom}_${dateTo}.xlsx`)
          document.body.appendChild(link)
          link.click()
        } else {
          this.setTable(response)
        }
      }).catch((error) => {
        if (error.response?.status === 500) {
          this.toastError(this.$t('500_ERROR_MESSAGE'))
          return
        }
        if (error.response.data?.module) {
          this.$eventBus.emit('dummy', true)
        } else {
          // this.$router.push('dashboard')
          this.toastError(this.$t(error.response.data.message))
        }
      })
    },
    setTable (response) {
      this.rows = []
      if (response) {
        const rows = {}
        const self = this
        Object.entries(response).forEach(([key, value]) => {
          const name = value.user_data.full_name
          if (!(name in rows)) {
            rows[name] = []
          }

          rows[name].push({
            name: '',
            projects: value.projects_data,
            employee_id: value.user_data.employee_id,
            working_time: value.user_data.working_time / 60.0,
            duration: value.total.duration / 60.0
          })
        })

        Object.keys(rows).forEach((key) => {
          const oneRow = {}
          oneRow[key] = rows[key]
          self.rows.push(oneRow)
        })
        self.rows.sort((a, b) => {
          const keyA = Object.keys(a)[0]
          const keyB = Object.keys(b)[0]
          return keyA.toLowerCase() > keyB.toLowerCase() ? 1 : -1
        })
        this.setFilteredData()
        this.loaded = true
      }
    }
  },
  watch: {
    projects: function () {
      this.getTableData()
    },
    period: function () {
      this.getTableData()
    },
    search: function () {
      this.setFilteredData()
    },
    showUsers: function () {
      this.getTableData()
    },
    selectedMarks: function () {
      this.setFilteredData()
    },
    selectedPositions () {
      this.setFilteredData()
    }
  }
}
</script>

<style>
  .pointer:hover {
    cursor: pointer;
  }
</style>
