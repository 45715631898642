<template>
  <modal-component :id="'AddShiftByTemplateModal'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title">{{ $t('Add Shift') }}</h5>
    </template>
    <template #body>
      <div>
        <Form ref="observer" as="div">
          <div class="row mb-2">
            <div class="col">
              <div class="font-weight-bold">
                {{ scheduleName }}
              </div>
              <div class="small">
                {{ blockedDateFrom.format(localeDateFormat) }} -
                {{ blockedDateTo.clone().add(-1, 'days').format(localeDateFormat) }}
              </div>
            </div>
          </div>
          <dates-picker-component
            :dateToLimit="null"
            :dateFromLimit="null"
            :shiftAddDate="from"
            :shiftAddEndDate="to"
            @onChangeDays="onChangeDays"
            @onChangeDates="onChangeDates"
            @onChangeActiveCollapse="onChangeActiveCollapse"
            @onChangeShiftDateFrom="onChangeShiftDateFrom"
            @onChangeShiftDateTo="onChangeShiftDateTo"
          />
          <template v-if="usersChoices.length > 0">
            <div class="my-2 font-weight-bold">{{ $t('Choose users to whom you want to add shifts to') }}</div>
            <div class="row">
              <div class="col-md-6 text-left">
                <a href="#" class="control-label font-weight-bold" style="cursor: pointer" @click="selectAll">
                  {{ $t('SELECT_ALL_USERS') }}
                </a>
              </div>
              <div class="col-md-6 text-right">
                <a href="#" class="control-label font-weight-bold" style="cursor: pointer" @click="clearAll">
                  {{ $t('CLEAR_USERS') }}
                </a>
              </div>
            </div>
            <ui-multi-select
              name="selectedUsers"
              v-model="selectedUsers"
              :filterable="true"
              :options="usersChoices"
              :validation="{required: true}"
              :key-name="'key'"
              :label-name="'name'"
              :value-name="'id'"
            />
            <div class="row">
              <div class="col">
                <ui-tags-select
                  :label="$t('Select positions for the shift')"
                  :hint="$t('POSITION_HINT')"
                  name="positions"
                  v-model="marks"
                  :clearable="true"
                  :filterable="true"
                  :placeholder="$t('Select')"
                  :options="projectMarks"
                  :key-name="'id'"
                  :label-name="'name'"
                  :value-name="'id'"
                  :allow-create="true"
                >
                  <template v-slot:default="slotProps">
                    <div  class="project-color mr-2" v-bind:style="{ backgroundColor: slotProps.option.color }"></div>
                    {{ slotProps.option.name }}
                  </template>
                </ui-tags-select>
              </div>
            </div>
            <ui-form-row :label="$t('SHIFT_LOCATION_SELECT')" :hint="$t('SHIFT_LOCATION_SELECT_HINT')">
              <ui-select
                name="locationId"
                v-model="locationId"
                :clearable="true"
                class="w-100"
                :options="locations"
              />
            </ui-form-row>
            <div class="row">
              <div class="col-12">
                <ui-textarea-input
                  name="note"
                  :label="$t('SHIFT_NOTE_LABEL')"
                  :hint="$t('SHIFT_NOTE_LABEL_HINT')"
                  v-model="note"
                  :validation="{required: false, max: 256}"
                  :rows="2"
                  :placeholder="$t('SHIFT_NOTE')"
                />
              </div>
              <div class="col-auto d-flex">
                <ui-checkbox
                  class="mt-auto"
                  name="rate"
                  :disabled="!note"
                  v-model="hide_note"
                >
                  {{ $t('SHIFT_NOTE_HIDE_LABEL') }}
                  <ui-hint :content="$t('SHIFT_NOTE_HIDE_LABEL_HINT')" />
                </ui-checkbox>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="text-primary pt-3">{{ $t('There are no users for whom shifts can be created on this date') }}</div>
          </template>
        </Form>
      </div>
    </template>
    <template #footer>
      <div>
        <div class="mx-auto text-center">
          <button
            class="btn btn-success rounded-pill shadow-sm"
            @click="addShift"
            :disabled="selectedUsers.length === 0">
            {{ $t('Add shift') }}
          </button>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
import ToastMixin from '@/mixins/ToastMixin'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import mixinColorsMarks from '@/mixins/mixinColorsMarks'
import DatesPickerComponent from '@/components/Schedule/CompanyScheduleComponents/Components/DatesPickerComponent'

export default {
  name: 'AddShiftByTemplateModal',
  mixins: [momentMixin, mixinColorsMarks, ToastMixin],
  components: {
    DatesPickerComponent,
    ModalComponent: ModalComponent
  },

  data () {
    return {
      activeCollapse: 'period',
      shiftDateFrom: '',
      shiftDateTo: '',
      templateId: '',
      scheduleId: '',
      usersChoices: [],
      selectedUsers: [],
      locations: [],
      marks: [],
      locationId: null,
      days: [0, 1, 2, 3, 4, 5, 6],
      dates: [],
      from: null,
      to: null,
      note: '',
      hide_note: true
    }
  },

  props: {
    scheduleTemplateStr: String,
    projectTz: String,
    schedules: Array,
    users: Array,
    color: String,
    shiftAddDate: Object,
    shiftAddEndDate: Object,
    schedulesList: Array
  },

  computed: {
    ...mapGetters({
      templatesIndexed: 'templatesIndexed',
      currentProjectId: 'currentProjectId',
      preloadUsers: 'employeesIndexed',
      projectMarks: 'projectMarks'
    }),
    schedule () {
      const scheduleId = +this.scheduleTemplateStr.split('__sp__')[0]
      return this.$store.getters.scheduleById(scheduleId)
    },
    template () {
      const templateId = +this.scheduleTemplateStr.split('__sp__')[1]
      return this.$store.getters.templateById(templateId)
    },
    templateTime () {
      const template = this.templatesIndexed[this.templateId]
      return {
        timeStart: template.time_from,
        timeEnd: template.time_to,
        breakTime: template.break_time
      }
    },
    blockedDateFrom () {
      return this.fromZoneToZone(this.schedule.date_from, 'UTC', this.$store.getters.actualTZ)
    },
    blockedDateTo () {
      return this.fromZoneToZone(this.schedule.date_to, 'UTC', this.$store.getters.actualTZ)
    },
    shiftsInPast () {
      if (!this.$store.getters.warningInPast) {
        return false
      }
      if (this.templateTime.timeStart && this.templateTime.timeEnd) {
        const dateTime = moment([this.shiftDateFrom, this.templateTime.timeStart].join(' '), this.localeDateTimeFormat)
        const shiftStart = this.fromZoneToZone(dateTime, this.$store.getters.actualTZ, 'UTC')
        return shiftStart < moment.utc()
      }
      return false
    },
    scheduleName () {
      if (this.templateId) {
        const schedule = this.schedulesList.filter(schedule => schedule.id === this.scheduleId)[0]
        return schedule.name
      }
      return ''
    }
  },

  created () {
    if (this.template) {
      this.locationId = this.template.location_id === 0 ? null : this.template.location_id
      this.marks = this.template.marks
    }
    this.$store.dispatch('getLocations', this.companyId).then(response => {
      this.locations = response.filter(item => !item.hide).map(location => {
        return { id: location.id, name: location.title }
      })
    })
    console.log(this.shiftAddDate)
    console.log(this.shiftAddEndDate)
    this.shiftDateFrom = this.shiftAddDate.format(this.backendDateTimeFormat)
    this.shiftDateTo = this.shiftAddEndDate.format(this.backendDateTimeFormat)
    this.from = this.shiftAddDate.clone()
    this.to = this.shiftAddEndDate.clone().add(-1, 'day')
    this.users.forEach(user => {
      this.usersChoices.push({ id: user, name: this.preloadUsers[user].full_name })
      this.usersChoices.sort((a, b) => {
        if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase()) return -1
        if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) return 1
        return 0
      })
    })
  },

  mounted () {
    const templateScheduleIdArray = this.scheduleTemplateStr.split('__sp__')
    this.templateId = +templateScheduleIdArray[1]
    this.scheduleId = +templateScheduleIdArray[0]
  },
  watch: {
    activeCollapse (val, prev) {
      if (!val) {
        this.$nextTick(() => {
          this.activeCollapse = prev === 'period' ? 'dates' : 'period'
        })
      }
    },
    marks (items) {
      if (Array.isArray(items)) {
        items.forEach((item, index) => {
          if (typeof item === 'string') {
            const tag = {
              name: item,
              enabled: true
            }
            const usedColors = []
            this.projectMarks.forEach(mark => {
              usedColors.push(mark.color)
            })
            const colorDiff = this.colorsChoicesMarks.filter(x => !usedColors.includes(x))
            if (colorDiff.length > 0) {
              tag.color = colorDiff[0]
            } else {
              tag.color = this.colorsChoicesMarks[Math.floor(Math.random() * this.colorsChoicesMarks.length)]
            }
            const newIndex = index
            this.$store.dispatch('createMark', [tag, this.currentProjectId]).then((mark) => {
              this.marks[newIndex] = mark.id
            }).catch(() => {
              this.marks.splice(newIndex, 1)
              this.toastError(this.$i18n?.t('The name has already been taken.'))
            })
          }
        })
      }
    }
  },
  methods: {
    onChangeDates (dates) {
      this.dates = dates
    },
    onChangeDays (days) {
      this.days = days
    },
    onChangeActiveCollapse (val) {
      this.activeCollapse = val
    },
    onChangeShiftDateFrom (val) {
      this.shiftDateFrom = val
    },
    onChangeShiftDateTo (val) {
      this.shiftDateTo = val
    },
    selectAll () {
      this.selectedUsers = []
      this.usersChoices.forEach(user => {
        this.selectedUsers.push(user.id)
      })
    },

    clearAll () {
      this.selectedUsers = []
    },

    closeModal () {
      this.$emit('closeAddShiftByTemplateModal')
    },

    addShiftAction () {
      const formData = {
        schedule_id: this.scheduleId,
        template_id: this.templateId,
        user_ids: this.selectedUsers,
        location_id: this.locationId,
        marks: this.marks,
        date_from: moment(this.shiftDateFrom, this.backendDateFormat).format(this.backendDateFormat),
        date_to: moment(this.shiftDateTo, this.backendDateFormat).format(this.backendDateFormat),
        days: this.days,
        dates: this.dates.map(date => date.id),
        type: this.activeCollapse,
        note: this.note,
        hide_note: this.hide_note,
        time_zone: this.$store.getters.actualTZ
      }

      let title = this.$i18n?.t('Shift(s) successfully added')
      let text = ''
      this.$eventBus.emit('enableLoader')
      this.$store.dispatch('createShift', formData).then((response) => {
        title = response.shifts.length + ' ' + title
        if (response.errors > 0) {
          response.errors_details.forEach(error => {
            text += `<div class="text-danger">${this.$i18n?.t(error.error_description)}: ${error.shift_data}</div>`
          })
        } else {
          text = ''
        }
        let type = 'success'
        if (response.errors > 0 && response.shifts.length < 1) {
          type = 'error'
        }
        if (response.errors > 0 && response.shifts.length > 0) {
          type = 'warning'
        }
        this.toastCustom({
          title: title,
          message: text,
          type: type,
          dangerouslyUseHTMLString: true
        })
        this.$eventBus.emit('addShiftsToUsers', response.shifts)
      }).catch((error) => {
        this.toastError(this.$t(error.response.data.message))
      }).finally(() => {
        document.getElementById('modalCloseButton').click()
        this.closeModal()
      })
      this.$eventBus.emit('checkNotify')
    },

    addShift () {
      this.$refs.observer.validate().then(({ valid }) => {
        if (valid) {
          if (this.shiftsInPast) {
            const body = document.getElementsByTagName('body')
            body[0].setAttribute('style', 'padding-right: 0px;')
            this.$confirm(
              this.$i18n?.t('You want to add a shift to a passed time period.'),
              this.$i18n?.t('Are you sure?'),
              {
                confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
                cancelButtonText: this.$i18n?.t('No, cancel it!!'),
                type: 'warning',
                center: true
              }
            ).then(() => {
              this.addShiftAction()
            }).catch(() => {
            })
          } else {
            this.addShiftAction()
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .checkbox-wrapper :deep(.el-checkbox) {
    margin: 0;
  }
  .el-checkbox :deep(.el-checkbox__inner) {
    width: 30px;
    height: 30px;
  }
  .el-checkbox :deep(.el-checkbox__inner::after) {
    border: 3px solid #FFF;
    border-left: 0;
    border-top: 0;
    height: 14px;
    left: 11px;
    top: 4px;
  }
</style>
